import React from 'react'

const info = {
    normas: [
        "Lea cuidadosamente el Baremo, para evitar confusiones y errores tanto en la consignación de documentos como en la interpretación de los procedimientos utilizados. ",
        "Deberá consignar sólo los documentos estrictamente solicitados, cerciorándose que no falte alguno de los obligatorios y de no incluir documentos o credenciales no exigidos, repetidos y sin validez para el concurso; deben entregar originales o copias certificadas por el CEDIA – LUZ o autoridad universitaria competente, en los casos en los cuales se indique (ver documentos obligatorios,  sección VI.1). <u>NOTE QUE NO TODOS LOS DOCUMENTOS NECESITAN SER CERTIFICADOS. </u>",
        "Los documentos deben ser entregados PERSONALMENTE en carpeta de fibra marrón tamaño oficio con gancho. Debe ser etiquetada en la portada con el nombre completo del aspirante, su cedula de identidad y debajo de esta en mayúscula la especialidad a la cual aspira. Deben ser ordenados, numerados y foliados en el margen superior derecho, en el orden en que se listan los documentos obligatorios y las credenciales con valoración. No se aceptarán carpetas entregadas por otras personas en ningún caso. En el caso de <u>aspirantes extranjeros</u> todos los procesos del concurso son estrictamente personales.",
        "EL LISTADO DE DOCUMENTOS QUE SE CONSIGNE DEBE SER EN EL ORDEN QUE EXIGE EL BAREMO. En especial lo relativo a los documentos y requisitos obligatorios, para tener derecho a participar en el concurso. La carpeta debe tener una etiqueta en la portada que indique nombre del aspirante, cédula de identidad o pasaporte y programa al que concursa. Debe separar con hojas de colores las secciones señaladas en el Baremo. Se rechazarán las carpetas que no cumplan tales condiciones. ",
        `Los documentos serán recibidos por personal entrenado de la División de Estudios para Graduados, quienes harán el registro de los documentos consignados en el formato de "Recepción de documentos para concursos de Posgrado". <u>SU RECEPCIÓN NO IMPLICA LA ACEPTACIÓN DE LA 
        INSCRIPCIÓN EN EL CONCURSO, NI QUE LOS MISMOS ESTÉN EN REGLA, LO CUAL ES 
        RESPONSABILIDAD DEL PARTICIPANTE</u>. La inscripción en el concurso solo será aceptada, una vez que la comisión evaluadora designada para tal fin, realice la revisión de las credenciales consignadas y determine el cumplimiento de los requisitos exigidos y la veracidad de los documentos. `,
        "Los documentos consignados se considerarán válidos cuando estén expedidos en papel con membrete, contemplen fecha de inicio y culminación de la(s) actividad(es), la fecha de expedición del documento, la firma de la autoridad competente y el sello de la oficina u organismo respectivo. Credenciales docentes debidamente certificadas por la Secretaría de LUZ y las Credenciales de Experiencia Profesional deben estar firmadas por el Director del Sistema Regional de Salud. ",
        "No serán válidos documentos con tachaduras, enmiendas, borrones, manchas o con información agregada que no forme parte del diseño original del documento. ",
        "Todo documento o credencial expedido en el exterior debe estar legalizado por el Consulado de la República Bolivariana de Venezuela, en el país de origen, y si está expresado en otro idioma, deberá ser traducido al idioma castellano por un intérprete público. Exceptuándose las publicaciones realizadas en otro idioma. ",
        "Los aspirantes pertenecientes al Personal Docente y de Investigación de las Universidades de la República Bolivariana de Venezuela, deberán presentar, para la inscripción en el concurso la Constancia de tramitación ante el Consejo de la Facultad respectiva de su permiso para cursar estudios de  posgrado y tenerlo aprobado antes del inicio del mismo. ",
        `El formato de "Recepción de documentos para concursos de posgrado", deberá ser debidamente firmado por el Personal de la División que lo recibe y por el aspirante, donde se registrará el número total de folios consignados y al aspirante se le entregará una copia de la misma. `,
        "La inscripción en el concurso y la inscripción en el programa de posgrado si es seleccionado, debe ser realizada estrictamente por el interesado. ",
        "Una vez concluido el lapso de la inscripción en el concurso no se admitirán nuevos documentos, <u>sin excepción</u>.",
        "Los aspirantes sólo podrán inscribirse en el concurso para solo un (1) curso de Posgrado por período académico y en una sola unidad docente. ",
        "Los aspirantes no seleccionados podrán retirar los documentos o credenciales consignados 15 días después de concluido y publicado el listado definitivo. Deben ser solicitados por escrito, personalmente o por persona debidamente autorizada. Una vez hecho esto pierde su posición en el concurso. Los documentos de los aspirantes no seleccionados serán desechados 30 días después del concurso si no son retirados. ",
        "La falsedad o la adulteración de algunos de los documentos consignados debidamente comprobada por la División de Estudios para Graduados, significará la exclusión  del aspirante en cualquier etapa del proceso de selección o del curso, o la invalidación del Título obtenido, si fuere el caso y no podrá participar posteriormente en concursos ofertados por la División de Estudios para Graduados de la Facultad de Medicina de LUZ ni en otras Universidades del País en los próximos cinco (5) años. El mencionado hecho será notificado a las distintas Universidades del país. ",
        "Una vez concluido el proceso de selección, se publicará en la cartelera de la División de Estudios para Graduados y en nuestra página web las listas provisionales, quedando un lapso de cinco (5) días hábiles a partir del momento de la publicación, para optar al recurso de reconsideración, el cual deberá hacerse por escrito ante la División de Estudios para Graduados. Se acordará con el interesado una fecha de reconsideración. Una vez procesada ésta, el aspirante podrá o no firmar la conformidad del acto. ",
        "Los listados definitivos de los seleccionados se publicarán una vez revisadas las reconsideraciones por el Consejo Técnico de Posgrado, si las hubiere, cualquier aspirante inconforme con el resultado final, podrá solicitar el recurso de reconsideración ante el Consejo de la Facultad de Medicina. ",
        "Los concursantes seleccionados deben formalizar su inscripción en la División de Estudios para Graduados de la Facultad de Medicina, pasando a ser cursantes de Posgrado con la obligación de cumplir con lo establecido en la Ley de Universidades, Reglamento de Estudios para Graduados de LUZ, Normas vigentes de la Facultad y División y las Normas y Reglamentos de las instituciones en las cuales cumplirán sus actividades de Posgrado a Dedicación Exclusiva. ",
        "Los seleccionados deberán acogerse a las normas vigentes de permanencia y evaluación establecidas en el Reglamento de Estudios para Graduados de la Universidad del Zulia. ",
        "Los montos a pagar por concepto de matrícula y/o por unidades créditos por Especialidad ó Maestría, serán fijados por la Administración de Posgrado y se aprobarán por todas las instancias pertinentes. ",
        "Los estudiantes inscritos que renuncien al curso de posgrado sin causa debidamente justificada SERÁN PENALIZADOS CON LA PROHIBICIÓN DE PARTICIPACIÓN EN CONCURSOS DE POSGRADOS A NIVEL NACIONAL POR UN PERIODO DE DOS (2) AÑOS. "
    ]
}

const ConcursosNormativa = () => {
    return (
        <div className='page-section normativa'>
            <h3 className="subpage-title">Normativa que rige el proceso</h3>

            <ol className='normativa-lista'>
            {
                info.normas.map((norma, index) =>
                    <li className='list-item' key={index}>
                        <p dangerouslySetInnerHTML={{__html: norma}} className='item-p'></p>
                    </li>
                )
            }
            </ol>
        </div>
    );
}

export { ConcursosNormativa }