import React from 'react'

const info = {
    header_lista: 'A continuación se detallan los cursos que se ofertan frecuentemente en el Programa de Educación Continua de la División de Estudios para Graduados de LUZ: ',
    programas: [
        "Curso Básico de Artroscopia de Rodilla. ",
        "Curso Básico de Artroscopia de Rodilla Modulo Imágenes, Técnica Quirúrgica, instrumento Artroscopia, instrumento Artrosplastia, Reconstrucción de Cruzado, Musculo Esquelético. ", 
        "Prueba de Suficiencia de Inglés. ", 
        "Prueba de Salud Mental. ",
        "Curso de Radiología Torácica. ",
        "Curso de Enfermería en cuidados intensivos generales. ",
        "Curso de Enfermería en Ciudades Intermedio y Telemetría. ", 
        "Curso Avanzado en Medicina Estética. ", 
        "Curso de Formación y Supervisores de Enfermería. ",
        "Curso de Patología y Colposcopia del Cuello Uterino. ", 
        "Introducción a la Transmisión de Conferencias. ", 
        "Curso Avanzado en Rinología. ", 
        "Gestión Aplicada a la Salud Ocupacional. ",
        "Segundo Modulo de Patología y Colposcopia de Cuello Uterino. ", 
        "Curso Básico en Cirugía de Hombro y Codo. ",
        "Curso Avanzado en Rinosología Base de Cráneo. ",
        "Seguridad del Paciente en el Ámbito Hospitalario. ",
        "Curso Avanzado de Facilitadora de Nacimiento. ",
        "Curso de Radiología Imágenes Diagnosticas. ", 
        "Curso Medicina de la Obesidad Bariátrica. ",  
        "Curso para Enfermería en Cuidados Intensivos Generales. ",
        "Curso Avanzado en Rinología, Rinosinusologia y Cirugía de Base de Cráneo",
        "IV Procesos Gerenciales en Enfermería. ",  
        "Curso Avanzado de Cirugía Artroscopica y de Reemplazos Articulares de Rodilla. ",
        "Curso de Psicofarmacología para Atención Primaria de Salud. ",
        "Curso Intervención Primaria en Urgencias de Salud Mental. ", 
        "Curso de Radiología torácica. ", 
        "Curso de Psicología Infantil. ", 
        "Curso Clínico Centrado en el Paciente Aplicación, Pertinencia e Impacto en la Efectividad de la Práctica Médica. ",
        "Curso Electrocardiografía Básica. ", 
        "Curso Neurobiología Celular Normal y Patológica. ", 
        "Curso Medio de Salud Pública. "         
    ]
}

const PecProgramasFrecuentes = () => {
  const programas = info.programas.sort((a, b) => a.localeCompare(b));

  return (
    <div className='page-section programas-frec'>
        <h3 className='subpage-title'>Programas frecuentes</h3>

        <p className='header-lista'>{info.header_lista}</p>

        <ul className='lista-cursos arrow-list'>
        {
            programas.map((programa, index) => 
            <li key={index} className='list-item'>{programa}</li>
        )}    
        </ul>
    </div>
  )
}

export { PecProgramasFrecuentes }