import React from 'react'

const info = {
  tramites: [
    "RENUNCIAS",
    "ABANDONOS",
    "DESINCORPORACIÓN",
    "REINCORPORACIÓN",
    "CAMBIO DE TUTOR ACADÉMICO",
    "CAMBIO DE TÍTULO", 
    "ALUMNOS CONDICIONADOS",
    "PROYECTO DE INVESTIGACIÓN",
    "TRABAJO DE GRADO",
    "TRABAJO ESPECIAL DE GRADO",
    "TESIS DOCTORAL",
    "VERIDICTOS (MAESTRÍAS, ESPECIALIDADES Y DOCTORADOS)",
    "APROBACIÓN DE BAREMO (POSGRADOS Y DOCTORADOS)", 
    "APROBACIÓN DE CONCURSOS (POSGRADOS Y DOCTORADOS)"
  ]
}

const TramitesConsejoTec = () => {
  const tramitesNoFormateados = [
    'Aprobación de Cursos del PEC'
  ]
  return (
    <>
      <h2 className='subpage-title'>Trámites</h2>

      <ul className='standard-list'>
      {
        info.tramites.map((item, index) =>
          <li className='list-item' key={index}>
            <span className='text-capitalize'>{item.toLocaleLowerCase()}</span>
          </li> 
        )
      }
      {
        tramitesNoFormateados && tramitesNoFormateados.map((item, index) =>
          <li className='list-item' key={index}>
            <span className=''>{item}</span>
          </li>
        )
      }
      </ul>
    </>
  )
}

export { TramitesConsejoTec }