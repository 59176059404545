import React from 'react'
import { Header } from '../components/Header'
import { NavigationBar } from '../components/NavigationBar'
import { Footer } from '../components/Footer'
import img_director_perfil from '../assets/images/Perfiles/Director DEPG - Dr. Freddy Pachano Arenas.jpg'
import { Link , useLocation, Outlet } from 'react-router-dom'

const info = {
  contenido: "La Dirección de la División de Estudios para Graduados de la Facultad de Medicina de la Universidad del Zulia, es el área encargada de supervisar todos los procesos académicos y administrativos de la institución. \n\nEstá dirigida por el Dr. Freddy Enrique Pachano Arenas distinguido profesor Titular de LUZ, Médico Cirujano egresado de la Facultad de Medicina de LUZ en el año 1996, quien es además Morfólogo, Especialista en Cirugía Pediátrica y Doctor en Ciencias Médicas, es el Director y Presidente del Consejo Técnico de esta División desde el año 2008 hasta la actualidad, bajo designación hecha por el Dr. Sergio Benito Aquiles Osorio Morales, Decano de la Facultad de Medicina de LUZ. \n\nA su vez es la persona encargada de liderizar todos los procesos administrativos y académicos que se realizan en el Posgrado de Medicina, así como también es desde el año 2010 Director del Consejo Nacional de Directores y Coordinadores de Postgrados de Medicina y Ciencias de la Salud de Venezuela. \n  \nAdemás es Coordinador y miembro de varios Comités Académicos de algunos programas de la División de Estudios para Graduados.\n\nBajo su gestión se ha realizado un exponencial crecimiento de esta organización, creando programas de posgrados en los hospitales del Instituto Venezolano de los Seguros Sociales (IVSS), Costa Oriental del Lago de Maracaibo, Hospital de Especialidades Pediátricas, Centro Oftalmológico IDEO así como la creación muchas especialidades en los Hospitales del Ministerio del Poder Popular para la Salud y creador junto a destacados profesionales de la salud de los Programas de Doctorados en Ciencias de la Salud, Ciencias Médicas y Metabolismo Humano.\n\nDesde la Dirección de la División de Estudios Para Graduados de la Facultad de Medicina, estamos comprometidos con la atención de nuestros estudiantes y con el fomento de actividades académicas, de extensión y de investigación de alto nivel y competencia.",
  secretaria: "Patricia León",
  email_contacto: [
    "direcciondepgfmluz@ditic.luz.edu.ve",
    "fpachano@ditic.luz.edu.ve"
  ],
  submenu: [
    {
      opcion: "Trámites",
      link: "/tramites"
    }
  ]
}

const submenu = [
  {
    opcion: "Contenido principal",
    link: "/direccion"
  },
  {
    opcion: "Trámites",
    link: "/tramites"
  }
]

function Direccion() {

  const location = useLocation();

  return (
    <>
      <Header />
      <NavigationBar activeLink={"Directorio"}/>
      <main className='main main-directorio'>
      {
        (location.pathname === "/direccion") ?

        <section className='main-container dept-main'>
          <div className='side-container'>
            <div className='header-box side'>
              <h1 className='page-title text-center'>Dirección</h1>
            </div>

            <div className='perfil-card side-card'>
              <img className='img-director' alt='Director Dr. Freddy Pachano Arenas' src={img_director_perfil} />
              <h4 className='perfil-nombre'>Dr. Freddy Enrique Pachano Arenas</h4>
              <span className='perfil-cargo fw-semibold'>Director</span>
            </div>

            <div className='secretarias-card side-card'>
              <h4 className='card-title'>Secretaria</h4>
              <span className='card-span-std'>{info.secretaria}</span>
            </div>

            <div className='contacto-card side-card'>
              <h4 className='card-title'>Contacto</h4>

              <ul className='contacto-lista'>
              {
                info.email_contacto.map((item, index) => 
                  <li className='list-item' key={index}>
                    {item}
                  </li>
                )
              }
              </ul>
            </div>

            <div className='side-card submenu-card'>

              <ul className='submenu-list std-list'>
              {
                info.submenu.map((item, index) => 
                  <li className='list-item' key={index}>
                    <Link to={location.pathname + item.link} className='list-link'>{item.opcion} </Link>
                  </li>
                )
              }
              </ul>
            </div>
            
          </div>

          <div className='central-container'>
            <div className='header-box'></div>
            <p className='p-contenido ws-pw'>{info.contenido}</p>
          </div>
        </section>
        :
        <section className='main-container dept-main'>
          <div className='side-container'>
            <div className='header-box side'>
              <h1 className='page-title text-center'>Dirección</h1>
            </div>

            <div className='side-card submenu-card'>

              <ul className='submenu-list std-list'>
              {
                submenu.map(item => 
                  (location.pathname !== "/direccion" && item.link === "/direccion") ?
                  <li className='list-item'>
                    <Link to={item.link} className='list-link'>{item.opcion} </Link>
                  </li>
                  :
                  <li className='list-item'>
                    <Link to={"/direccion" + item.link} className='list-link'>{item.opcion} </Link>
                  </li>
                )
              }
              </ul>
            </div>
            <div className='contacto-card side-card mt-0'>
              <h4 className='card-title'>Contacto</h4>

              <ul className='contacto-lista'>
              {
                info.email_contacto.map((item, index) => 
                  <li className='list-item' key={index}>
                    {item}
                  </li>
                )
              }
              </ul>
            </div>
          </div>

          <div className='central-container'>
              <div className='header-box'>
              </div>
              <Outlet />
          </div>
        </section>
      }        
      </main>
      <Footer />
    </>
  )
}

export { Direccion }