import React from 'react'
import anatomia_patologica_sahum from "../../../assets/images/planes_estudio/anatomia-patologica-01-crop.png"
import anatomia_patologica_hgs from "../../../assets/images/planes_estudio/Antomia_Patologica_HGS.png"
import anestesiologia_01 from "../../../assets/images/planes_estudio/Anestesiologia_AHGS.png"
import anestesiologia_02 from "../../../assets/images/planes_estudio/Anestesiologia_AP_MNT_PGC.png"
import anestesiologia_03 from "../../../assets/images/planes_estudio/Anestesiologia_SAHUM_URQ.png"
import cardiologia from "../../../assets/images/planes_estudio/Cardiologia_HGS-05.png"
import cirugia_cardiovascular from "../../../assets/images/planes_estudio/Cirugia_Cardiovascular.png"
import cirugia_de_mano from "../../../assets/images/planes_estudio/Cirugia_de_Mano.png"
import cirugia_de_torax from "../../../assets/images/planes_estudio/Cirugia_de_Torax_SAHUM_HGS.png"
import cirugia_general_hgs from "../../../assets/images/planes_estudio/Cirugia_General_HGS_HCM_HCH.png"
import cirugia_general_pgc from "../../../assets/images/planes_estudio/Cirugia_General_PGC_MNT_HGC_HAP.png"
import cirugia_general_sahum from "../../../assets/images/planes_estudio/Cirugia_General_SAHUM.png"
import cirugia_pediatrica from "../../../assets/images/planes_estudio/Cirugia_Pediatrica.png"
import cirugia_plastica_reconstructiva from "../../../assets/images/planes_estudio/Cirugia Plastica y Reconstructiva.png"
import dermatologia_med_quir_est from "../../../assets/images/planes_estudio/Dermatologia Medica Quirurgica y Estetica.png"
import dermatologia_pediatrica from "../../../assets/images/planes_estudio/Dermatologia Pediatrica.png"
import enfermeria_area_cuidados_criticos from "../../../assets/images/planes_estudio/Enfermeria_en_Areas_de_Cuidados_Criticos.png"
import gastroenterologia_urq from "../../../assets/images/planes_estudio/Gastroenterologia_URQ.png"
import gastroenterologia_sahum from "../../../assets/images/planes_estudio/Gastroenterologia_SAHUM.png"
import hematologia_urq from "../../../assets/images/planes_estudio/Hematologia_URQ.png"
import hematologia_sahum from "../../../assets/images/planes_estudio/Hematologia_SAHUM.png"
import hepatologia_clinica from "../../../assets/images/planes_estudio/Hepatologia_Clinica.png"
import medicina_critica from "../../../assets/images/planes_estudio/Medicina_Critica.png"
import medicina_critica_pediatrica from "../../../assets/images/planes_estudio/Medicina_Critica_Pediatrica.png"
import medicina_emergencia_desastre from "../../../assets/images/planes_estudio/Medicina_Emergencia_y_Desastre.png"
import medicina_familiar from "../../../assets/images/planes_estudio/Medicina Familiar.png"
import medicina_interna_ap from "../../../assets/images/planes_estudio/Medicina_Interna_AP, MNT, PGS.png"
import medicina_interna_urq from "../../../assets/images/planes_estudio/Medicina_Interna_URQ.png"
import medicina_interna_hgs from "../../../assets/images/planes_estudio/Medicina_Interna_HGS.png"
import medicina_interna_sahum from "../../../assets/images/planes_estudio/Medicina_Interna_SAHUM.png"
import nefrologia_hgs from "../../../assets/images/planes_estudio/Nefrologia_HGS.png"
import nefrologia_sahum from "../../../assets/images/planes_estudio/Nefrologia_SAHUM.png"
import nefrologia_pediatrica from "../../../assets/images/planes_estudio/Nefrologia Pediatrica.png"
import neonatologia from "../../../assets/images/planes_estudio/Neonatología.png"
import neumonologia_tisiologia from "../../../assets/images/planes_estudio/Neumonología y Tisiología.png"
import nutricion_clinica from "../../../assets/images/planes_estudio/Nutrición Clinica.png"
import neurocirugia_hgs_sahum from "../../../assets/images/planes_estudio/Neurocirugía SAHUM-HGS.png"
import neurologia from "../../../assets/images/planes_estudio/Neurología.png"
import neurologia_pediatrica from "../../../assets/images/planes_estudio/Neurología Pediátrica.png"
import obstetricia_ginecologia_ap from "../../../assets/images/planes_estudio/Obstetricia y Ginecologia AP-HG-PGC-MNT.png"
import obstetricia_ginecologia_hch from "../../../assets/images/planes_estudio/Obstetricia y Ginecologia HCH.png"
import obstetricia_ginecologia_urq from "../../../assets/images/planes_estudio/Obstetricia y Ginecologia URQ.png"
import obstetricia_ginecologia_sahum from "../../../assets/images/planes_estudio/Obstetricia y Ginecologia SAHUM.png"
import oftalmologia from "../../../assets/images/planes_estudio/Oftalmología.png"
import oncologia_pediatrica_hep from "../../../assets/images/planes_estudio/Oncología Pediátrica HEP.png"
import oncologia_pediatrica_sahum from "../../../assets/images/planes_estudio/Oncología Pediatrica SAHUM.png"
import ortopedia_traumatologia_mnt_hgs_ap from "../../../assets/images/planes_estudio/Ortopedia y Traumatología MNT-HGS-AP.png"
import ortopedia_traumatologia_urq from "../../../assets/images/planes_estudio/Ortopedia y Traumatología (URQ).png"
import ortopedia_traumatologia_sahum from "../../../assets/images/planes_estudio/Ortopedia y Traumatología SAHUM.png"
import otorrinolaringologia from "../../../assets/images/planes_estudio/Otorrinolaringología.png"
import psiquiatria from "../../../assets/images/planes_estudio/Psiquiatría.png"
import puericultura_pediatria_sahum from "../../../assets/images/planes_estudio/Puericultura y Pediatria (SAHUM-PGC-MNT-AP).png"
import puericultura_pediatria_hch from "../../../assets/images/planes_estudio/Puericultura y Pediatria (HCH).png"
import radiologia from "../../../assets/images/planes_estudio/Radiología.png"
import reumatologia from "../../../assets/images/planes_estudio/Reumatología.png"
import urgencia_emergencia_pediatrica from "../../../assets/images/planes_estudio/Urgencia y Emergencia Pediátrica.png"
import urologia from "../../../assets/images/planes_estudio/Urología.png"

function TabPlanEstudios ({ programData }) {
  const planes = [
    {
      programa: "anatomia-patologica-sahum",
      imagen: anatomia_patologica_sahum
    },
    {
      programa: "anatomia-patologica-hgs",
      imagen: anatomia_patologica_hgs
    },
    {
      programa: "anestesiologia-ap-mnt-pgc",
      imagen: anestesiologia_02
    },
    {
      programa: "anestesiologia-sahum-urq",
      imagen: anestesiologia_03
    },
    {
      programa: "anestesiologia-hgs",
      imagen: anestesiologia_01
    },
    {
      programa: "cardiologia-hgs",
      imagen: cardiologia
    },
    {
      programa: "cirugia-cardiovascular",
      imagen: cirugia_cardiovascular
    },
    {
      programa: "cirugia-de-mano",
      imagen: cirugia_de_mano
    },
    {
      programa: "cirugia-de-torax",
      imagen: cirugia_de_torax
    },
    {
      programa: "cirugia-general-pgc",
      imagen: cirugia_general_pgc
    },
    {
      programa: "cirugia-general-hgs",
      imagen: cirugia_general_hgs
    },
    {
      programa: "cirugia-general-sahum",
      imagen: cirugia_general_sahum
    },
    {
      programa: "cirugia-pediatrica",
      imagen: cirugia_pediatrica
    },
    {
      programa: "cirugia-plastica-reconstructiva",
      imagen: cirugia_plastica_reconstructiva
    },
    {
      programa: "dermatologia-med-quir-est",
      imagen: dermatologia_med_quir_est
    },
    {
      programa: "dermatologia-pediatrica",
      imagen: dermatologia_pediatrica
    },
    {
      programa: "enfermeria-area-cuidados-criticos",
      imagen: enfermeria_area_cuidados_criticos
    },
    {
      programa: "gastroenterologia-urq",
      imagen: gastroenterologia_urq
    },
    {
      programa: "gastroenterologia-sahum",
      imagen: gastroenterologia_sahum
    },
    {
      programa: "hematologia-urq",
      imagen: hematologia_urq,
    },
    {
      programa: "hematologia-sahum",
      imagen: hematologia_sahum,
    },
    {
      programa: "hepatologia-clinica",
      imagen: hepatologia_clinica
    },
    {
      programa: "medicina-critica",
      imagen: medicina_critica
    },
    {
      programa: "medicina-critica-pediatrica",
      imagen: medicina_critica_pediatrica
    },
    {
      programa: "medicina-emergencia-desastre",
      imagen: medicina_emergencia_desastre
    },
    {
      programa: "medicina-familiar",
      imagen: medicina_familiar
    },
    {
      programa: "medicina-interna-ap",
      imagen: medicina_interna_ap
    },
    {
      programa: "medicina-interna-urq",
      imagen: medicina_interna_urq
    },
    {
      programa: "medicina-interna-hgs",
      imagen: medicina_interna_hgs
    },
    {
      programa: "medicina-interna-sahum",
      imagen: medicina_interna_sahum
    },
    {
      programa: "nefrologia-hgs",
      imagen: nefrologia_hgs
    },
    {
      programa: "nefrologia-sahum",
      imagen: nefrologia_sahum
    },
    {
      programa: "nefrologia-pediatrica",
      imagen: nefrologia_pediatrica
    },
    {
      programa: "neonatologia",
      imagen: neonatologia
    },
    {
      programa: "neumonologia-tisiologia",
      imagen: neumonologia_tisiologia
    },
    {
      programa: "nutricion-clinica",
      imagen: nutricion_clinica
    },
    {
      programa: "neurocirugia-hgs-sahum",
      imagen: neurocirugia_hgs_sahum
    },
    {
      programa: "neurologia",
      imagen: neurologia
    },
    {
      programa: "neurologia-pediatrica",
      imagen: neurologia_pediatrica
    },
    {
      programa: "obstetricia-ginecologia-ap-hgc-pgc-mnt",
      imagen: obstetricia_ginecologia_ap
    },
    {
      programa: "obstetricia-ginecologia-hch",
      imagen: obstetricia_ginecologia_hch
    },
    {
      programa: "obstetricia-ginecologia-urq",
      imagen: obstetricia_ginecologia_urq
    },
    {
      programa: "obstetricia-ginecologia-sahum",
      imagen: obstetricia_ginecologia_sahum
    },
    {
      programa: "oftalmologia",
      imagen: oftalmologia
    },
    {
      programa: "oncologia-pediatrica-hep",
      imagen: oncologia_pediatrica_hep
    },
    {
      programa: "oncologia-pediatrica-sahum",
      imagen: oncologia_pediatrica_sahum
    },
    {
      programa: 'ortopedia-traumatologia-mnt-hgs-ap',
      imagen: ortopedia_traumatologia_mnt_hgs_ap
    },
    {
      programa: 'ortopedia-traumatologia-urq',
      imagen: ortopedia_traumatologia_urq
    },
    {
      programa: 'ortopedia-traumatologia-sahum',
      imagen: ortopedia_traumatologia_sahum
    },
    {
      programa: 'otorrinolaringologia',
      imagen: otorrinolaringologia
    },
    {
      programa: 'psiquiatria',
      imagen: psiquiatria
    },
    {
      programa: 'puericultura-pediatria-sahum',
      imagen: puericultura_pediatria_sahum
    },
    {
      programa: 'puericultura-pediatria-hch',
      imagen: puericultura_pediatria_hch
    },
    {
      programa: 'radiologia',
      imagen: radiologia
    },
    {
      programa: 'reumatologia',
      imagen: reumatologia
    },
    {
      programa: 'urgencia-emergencia-pediatrica',
      imagen: urgencia_emergencia_pediatrica
    },
    {
      programa: 'urologia',
      imagen: urologia
    }
  ]

  const programaActual = planes.find(plan =>
    plan.programa === programData.plan_estudios.imagen_pde
  );

  return (
    <>
    {
      programData.release ?
      <div className='tab-plan-estudios'>
        <h3 className='sub-titulo'>Plan de Estudios</h3>

        <div className='d-flex flex-column align-items-center'>
          {
            (programData.plan_estudios.imagen_pde !== "--pendiente--") ?
            <figure className='plan-container'>
                <img 
                  className={`plan-estudios-img ${ programData.plan_estudios.img_layout ?? 'standard' }` } 
                  alt='Plan de Estudios' 
                  src={programaActual.imagen}
                />
            </figure>
            :
            <div class="spinner-border text-primary mb-3" style={{'height': "30px", "width": "30px"}} role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          }
          <div className={`plan-footer text-center fw-semibold d-flex flex-wrap justify-content-center ${programData.plan_estudios?.footer===false && 'd-none'}`}>
            <span className='footer-text'>HT: Horas teóricas. </span>
            <span className='footer-text'>HP: Horas prácticas. </span>
            <span className='footer-text'>HPP: Horas de Práctica Profesional. </span>
            <br/>
            <span className='footer-text'>HTol: Horas totales semanales. </span>
            {
              programData.plan_estudios.uc && 
              <span className='footer-text'>UC: Unidades créditos</span>
            }
          </div>
        </div>

      {
        (programData.plan_estudios.layout === 1 && !(programData.plan_estudios.no_electivas)) && 
        <>
          {
            programData.plan_estudios.notas_header && programData.plan_estudios.notas_header.map((element, index) => 
              <p key={index} className='list-parag'>{element}</p>
            )
          }
          <span className='text-footer fw-bold'>{programData.plan_estudios.footer_title}</span>
          {
            programData.plan_estudios.footer &&
            <span className='text-footer'>{programData.plan_estudios.footer}</span>
          }
          <ul className='tab-list'>
          {
            programData.plan_estudios.electivas.map((element, index) => 
              <li key={index} className='element-list'>{element}</li>
            )
          }
          </ul>
          {
            programData.plan_estudios.nota && <>
              <span className='fw-bold'>Nota: </span><span>{programData.plan_estudios.nota} </span>
            </>
          }
        </>
      }
      {
        (programData.plan_estudios.layout === 2 && !(programData.plan_estudios.no_electivas)) && 
        <>
          {
            programData.plan_estudios.footer_title &&
            <div className='mb-4'>
              <span className='text-footer fw-semibold'>{programData.plan_estudios.footer_title}</span>
            {
              programData.plan_estudios.footer && 
              <span className='text-footer'>{programData.plan_estudios.footer}</span>
            }
            </div>
          }
          {
            programData.plan_estudios.electivas.map((electiva, index) => 
              <div key={index} className='tab-footer-group'>
                <span className='fw-bold'>{electiva.titulo}</span>
                <span>{electiva.descripcion}</span>

                <ul className='tab-list mb-0'>
                {
                  electiva.materias.map((materia, index) =>
                    <li key={index} className='list-item'>{materia}</li>
                  )
                }
                </ul>

                <span className='fw-semibold'>{electiva.footer}</span>
              </div>
            )
          }
        </>
      }
      </div>
      :
      <span>En construcción...</span>
    }
    </>
  )
}

export { TabPlanEstudios }