import React from 'react'
import { Button, Modal } from 'react-bootstrap';
import banner_modal from '../../assets/images/ModalInicio/Banner-Cuenta-Bancaria_Modal.png'

const info = {
  correo: 'infopagosdepgfmluz@gmail.com'
}

const ModalCtaBancaria = ({ toggleFunction, modalState }) => {
  return (
    <Modal show={modalState} onHide={() => toggleFunction(false)} dialogClassName='custom-lg'>
      <Modal.Header closeButton>
        <Modal.Title>Asignación de Cuenta Bancaria</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <section className='info-container text-center'>
          <img src={banner_modal} alt='Apertura del Concurso de Posgrado de Medicina 2024' className='modal-banner md border' />

          <p className='heading-p'>La División de Estudios para Graduados de la Facultad de Medicina informa sobre la asignación de la nueva cuenta bancaria para los trámites de la División: </p>

          <div className='info-group'>
            <p className='info-p' 
              dangerouslySetInnerHTML={{ __html: '<b>No.</b> 0191-0304-81-2100000408' }} 
            />
            <p className='info-p' 
              dangerouslySetInnerHTML={{ __html: '<b>Banco: </b>BNC' }} 
            />
            <p className='info-p' 
              dangerouslySetInnerHTML={{ __html: '<b>Nombre: </b>Universidad del Zulia' }} 
            />
            <p className='info-p' 
              dangerouslySetInnerHTML={{ __html: '<b>RIF: </b>G-200088060' }} 
            />
          </div>

          <div className='info-group'>
            <div className='d-flex justify-content-center flex-wrap mb-4'>
              <span className='info-span'>
                Enviar el comprobante de pago al correo: 
              </span>
              <a href={`mailto:${info.correo}`} className='fw-semibold'>{info.correo}</a>
            </div>
          </div>
        </section>
      </Modal.Body>
      <Modal.Footer>
        <Button className='footer-button' variant="secondary" onClick={()=> toggleFunction(false)}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export { ModalCtaBancaria }