import React from 'react'
import gforms_img from '../../assets/images/Recursos/google-forms-logo.png'

const ArchivoSolicitudRetiro = () => {
  return (
    <div className='sub-section retiro'>
        <h3 className='subpage-title'>Solicitud de Retiro de Documentos</h3>
        <div className='form-container-placeholder'>
            <img src={gforms_img} alt="Google Forms Logo" className='img-placeholder'/>
        </div>
    </div>
  )
}

export { ArchivoSolicitudRetiro }