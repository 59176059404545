import React, { useState } from 'react'
import video_instructivo_concurso  from '../../assets/videos/Video Instructivo Concurso DEPG-FMED-LUZ 2024.mp4'
import ReactPlayer from 'react-player'
import urlOfertaCuposPdf from '../../assets/files/Concurso2024/OFERTA DE CUPOS CONCURSO 2024.pdf'
import baremoPdf from '../../assets/files/Concurso2024/BAREMO 2024.pdf'

const ConcursoInstructivos = () => {

  const ofertaCupos = {
    url: urlOfertaCuposPdf,
    fileName: 'Oferta de Cupos Concurso 2024'
  };
  const baremo = {
    url: baremoPdf,
    fileName: 'BAREMO 2024'
  };

  const [videoReadyStatus, setVideoReadyStatus] = useState(false);
  
  const toggleVideoStatus = () => {
    setVideoReadyStatus(true);
    // console.log('Video status: ' + videoReadyStatus)
  }

  return (
    <div className='page-section instructivos'>
        <section className='video-container'>
          <div className={`video-canvas ${ videoReadyStatus ? 'ready' : 'loading' }`}>
            <button 
              className={`video-placeholder btn btn-secondary disabled placeholder-wave d-${videoReadyStatus ? 'none' : 'block'}`} 
            ></button>
            <div className={`d-${ videoReadyStatus ? 'block' : 'none' }`}>
              <ReactPlayer
                className='video-player'
                url={video_instructivo_concurso}
                playing={false}
                volume={0.5}
                controls={true}
                width={'100%'}
                height={'auto'}
                onReady={toggleVideoStatus}
              />
            </div>
          </div>
          <div className='button-group d-flex flex-column'>
            <a href={baremo.url} download={baremo.fileName} className='btn-baremo btn btn-primary link-button'>
              Baremo 2024
            </a>
            <a 
              href={ofertaCupos.url} 
              download={ofertaCupos.fileName} 
              className='btn-cupos btn btn-primary link-button'
            >
              Oferta de Cupos
            </a>
            {/* <Link className='btn-cupos btn btn-primary link-button' to={urlInscripcion}>
              Registrarse
            </Link> */}
          </div>
        </section>

        <section className='info-container'>
          <p className='heading-p'>La División de Estudios para Graduados de la Facultad de Medicina de la Universidad del Zulia, da inicio al Concurso 2024.</p>
          
          <div className='info-group'>
            <h3 className='modal-subtitle continuous'>Publicación del Baremo e Instructivo 2024</h3>
            <p className='info-p'>Lunes 06 de mayo de 2024.</p>
          </div>
          <div className='info-group'>
            <h3 className='modal-subtitle'>Recepción de Credenciales</h3>
            <p className='info-p'>Desde lunes 06 de mayo hasta el 30 de septiembre de 2024. </p>
            <h4 className='highlight-title'>PRÓRROGA:</h4>
            <p className='info-p'>Hasta el jueves 03 de octubre de 2024</p>
            <h4 className='highlight-title'>Horario: </h4>
            <p className='info-p'>Lunes a viernes</p>
            <p className='info-p'>8:30 AM a 4:00 PM</p>
            <p className='info-p' dangerouslySetInnerHTML={{__html: '<b>Lugar</b>: Edificio Ciencia y Salud de la Facultad de Medicina de LUZ. '}}/>
          </div>
          <div className='info-group'>
            <h3 className='modal-subtitle'>Examen de Conocimientos</h3>
            <p className='info-p'>Sábado 12 de octubre de 2024. </p>
            <p className='info-p'>Fecha única a nivel nacional. </p>
            <p className='info-p'>Hora: 7:00 AM</p>
            <p className='info-p' dangerouslySetInnerHTML={{__html: '<b>Lugar</b>: Edificio Ciencia y Salud de la Facultad de Medicina de LUZ. '}}/>
          </div>
        </section>
    </div>
  )
}

export { ConcursoInstructivos }