import React from "react";
import {
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import dataProgramas from "../../assets/data/programas_academicos.json";
import { Button, ButtonGroup } from "react-bootstrap";
import { TabDescripcion } from "./Tabs_DOC/TabDescripcion";
import { TabObjetivos } from "./Tabs_DOC/TabObjetivos";
import { TabPerfiles } from "./Tabs_DOC/TabPerfiles";
import { TabRequisitos } from "./Tabs_DOC/TabRequisitos";
import { TabReglamento } from "./Tabs_DOC/TabReglamento";
import { TabPlanEstudios } from "./Tabs_DOC/TabPlanEstudios";

const toolbarOptions = [
  {
    name: "Descripción",
    hashtag: "#descripcion",
  },
  {
    name: "Objetivos",
    hashtag: "#objetivos",
  },
  {
    name: "Perfiles",
    hashtag: "#perfiles",
  },
  {
    name: "Requisitos",
    hashtag: "#requisitos",
  },
  {
    name: "Reglamento",
    hashtag: "#reglamento",
  },
  {
    name: "Plan de Estudios",
    hashtag: "#plan-estudios",
  },
];

function ItemDoctorado() {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const selectedPrograma = dataProgramas.programas[0].children.find(
    (programa) => programa.pathname === params.pathname
  );
  const sedePrograma = dataProgramas.programas[3].children.find(
    (sede) => sede.id === selectedPrograma.sede
  ).nombre ?? 'null';
  // const selectedPrograma = selectedPrograma.programas.find((programa) => programa.id === parseInt(searchParams.get('sede')))

  // const [selectedSede, setSede] = useState("");
  const currentHash = location.hash;

  // useEffect(() => {
  //     if(selectedPrograma !== undefined){
  //         setSede(dataProgramas.programas[3].children.find((sede) => sede.id === selectedPrograma.sede).nombre);
  //     } else {
  //         setSede("")
  //     }
  // },[selectedPrograma])

  return (
    <div className="programa-container programa-doctorado">
      {
        // (selectedPrograma.pathname === params.pathname)
        // ? <h1 className='nombre-programa nombre-doctorado'>{selectedPrograma.nombre}</h1>
        // : <h3>Página no encontrada</h3>
        selectedPrograma ? (
          <h1 className="nombre-programa nombre-doctorado">
            {selectedPrograma.nombre}
          </h1>
        ) : (
          <h3>Página no encontrada</h3>
        )
      }
      {selectedPrograma.release ? (
        <>
          <div className="header-sede">
            <div className='header-button bg-primary'>
              <span className='button-text'>Sede</span>
            </div>
            <div className='header-body'>
              <span className='sede-display'>{sedePrograma}</span>
            </div>
          </div>
          <section className="programa-section">
            <div className="toolbar-container">
              <ButtonGroup className="nav-toolbar">
                {toolbarOptions.map((option) => (
                  <Button
                    key={option.name}
                    className="toolbar-button"
                    variant="secondary"
                    active={
                      location.hash === option.hashtag
                        ? true
                        : location.hash === "" &&
                          option.hashtag === "#descripcion"
                        ? true
                        : false
                    }
                    onClick={() =>
                      navigate(
                        location.pathname + location.search + option.hashtag
                      )
                    }
                  >
                    {option.name}
                  </Button>
                ))}
              </ButtonGroup>
            </div>
          {
            selectedPrograma !== null &&
            <div className="tab-container tab-doctorados">
              {(currentHash === "#descripcion" || currentHash === "") && (
                <TabDescripcion programData={selectedPrograma} />
              )}
              {currentHash === "#objetivos" && (
                <TabObjetivos programData={selectedPrograma} />
              )}
              {currentHash === "#perfiles" && (
                <TabPerfiles programData={selectedPrograma} />
              )}
              {currentHash === "#requisitos" && (
                <TabRequisitos programData={selectedPrograma} />
              )}
              {currentHash === "#reglamento" && (
                <TabReglamento programData={selectedPrograma} />
              )}
              {currentHash === "#plan-estudios" && (
                <TabPlanEstudios programData={selectedPrograma} />
              )}
            </div>
          }
          </section>
        </>
      ) : (
        <>
          <span className="programa-description-top">
            Descripción detallada del programa.
          </span>
          <span>En construcción...</span>
        </>
      )}
    </div>
  );
}

export { ItemDoctorado };
