import React from 'react'
import { Button, Modal } from 'react-bootstrap';
import img_modal from '../../assets/images/ModalInicio/ConsejoTec-Banner.png'
import { Link } from 'react-router-dom';

const info = {
  correo: 'consejotecnicodepgfmluz@gmail.com'
}

const ModalCtecExt = ({ toggleFunction, modalState }) => {
  return (
    <Modal show={modalState} onHide={() => toggleFunction(false)} dialogClassName='custom-lg'>
      <Modal.Header closeButton>
        <Modal.Title>Consejo Técnico</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <section className='info-container text-center'>
          <img src={img_modal} alt='Consejo Técnico' className='modal-banner sm card' />

          <div className='info-group'>
            <p className='info-p' dangerouslySetInnerHTML={{__html: '<b>Fecha</b>: 09 de octubre de 2024 '}} />
            <p className='info-p' dangerouslySetInnerHTML={{__html: '<b>Hora</b>: 9:00 AM '}} />
          </div>

          <div className='info-group mb-3'>
            <h3 className='modal-subtitle continuous heading mb-3'>Recepción</h3>
            <p className='info-p' dangerouslySetInnerHTML={{__html: 'Hasta el 07 de octubre de 2024 '}} />
            <p className='info-p' dangerouslySetInnerHTML={{__html: '<b>Hora</b>: 1:00 PM '}} />

          </div>

          <Link to={'/consejo-tecnico/instructivos'} className='btn btn-primary link-button px-3 mb-3'>
            Instructivos
          </Link>
          <div className='info-group mb-2'>
            <h4 className='highlight-title fw-bold'>Correo Electrónico: </h4>
            <a href={`mailto:${info.correo}`} className='fw-semibold'>{info.correo}</a>
          </div>

        </section>
      </Modal.Body>
      <Modal.Footer>
        <Button className='footer-button' variant="secondary" onClick={()=> toggleFunction(false)}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export { ModalCtecExt }