import React from 'react'
import { Link } from 'react-router-dom'
import img_coordinadora from '../assets/images/Perfiles/Dra. Hazel Anderson.jpg'

const links = [
  {
    name: "Contenido principal",
    path: "/cursos-pec" 
  },
  {
    name: "Programas frecuentes",
    path: "/programas-frecuentes" 
  },
  {
    name: "Cursos permanentes",
    path: "/cursos-permanentes" 
  },
  {
    name: "Trámites y solicitudes",
    path: "/tramites-solicitudes" 
  }
]

const info = {
  pathname: "/cursos-pec"
}

const SidebarPEC = ({ pathname }) => {

  return (
    <aside className='sidebar-pec'>
        <div className='sidebar-header'>
          <span className='header-title'>Cursos PEC</span>
        </div>
        <div className={`sidebar-body ${pathname !== info.pathname ? 'children' : ''}`}>
          <div className='cards-container'>
            <div className={`side-card perfil ${pathname !== info.pathname ? 'd-none' : ''}`} >
              <img src={img_coordinadora} alt='Dra. Hazel Anderson - Coordinadora Cursos PEC' className='img-perfil'/>
              <h4 className='profile-name'>Dra. Hazel Anderson</h4>
              <span className='profile-position'>Coordinadora</span>
            </div>

            <div className='side-card secretaria'>
              <h3 className='card-title'>Secretaria</h3>
              <span className='text-name'>Lcda. Clarisbel González</span>
              <div className='span-email'>
                <i class="bi bi-envelope-fill mail-icon"></i>
                <span className='text-email'>pecdepgfmedluz@gmail.com</span> 
              </div>
            </div>

            <div className='side-card links'>
              <ul className='links-container arrow-list'>
              {
                links.map((link, index) => 
                <li className='list-item' key={index}>
                  <Link className='list-link' to={ link.path === info.pathname ? link.path : info.pathname + link.path }>
                    {link.name}
                  </Link>
                </li>
              )}
              </ul>
            </div>
          </div>
        </div>
    </aside>
  )
}

export { SidebarPEC }