import React from "react";
import { Accordion } from "react-bootstrap";
import archivoAbandono1 from '../../assets/files/ConsejoTecnico/InstructivosPresenciales/ABANDONO/MODELO DE CARTA ABANDONO DOCTORADO.docx'
import archivoAbandono2 from '../../assets/files/ConsejoTecnico/InstructivosPresenciales/ABANDONO/MODELO DE CARTA ABANDONO ESPECIALIDADES.docx'
import archivoAbandono3 from '../../assets/files/ConsejoTecnico/InstructivosPresenciales/ABANDONO/MODELO DE CARTA ABANDONO MAESTRIAS.docx'
import archivoCondicionado1 from '../../assets/files/ConsejoTecnico/InstructivosPresenciales/ALUMNO CONDICIONADO/MODELO DE CARTA ALUMNO CONDICIONADO ESPECIALIDAES.docx'
import archivoCondicionado2 from '../../assets/files/ConsejoTecnico/InstructivosPresenciales/ALUMNO CONDICIONADO/MODELO DE CARTA ALUMNO CONDICIONADO MAESTRIA.docx'
import insEspecial1 from '../../assets/files/ConsejoTecnico/InstructivosPresenciales/ALUMNO ESPECIAL/MODELO DE CARTA ALUMNO ESPECIAL ESPECIALIDAES.docx'
import insEspecial2 from '../../assets/files/ConsejoTecnico/InstructivosPresenciales/ALUMNO ESPECIAL/MODELO DE CARTA ALUMNO ESPECIAL MAESTRIA.docx'
import insRegular1 from '../../assets/files/ConsejoTecnico/InstructivosPresenciales/ALUMNO REGULAR/MODELO DE CARTA ALUMNO REGULAR ESPECIALIDAES.docx'
import insRegular2 from '../../assets/files/ConsejoTecnico/InstructivosPresenciales/ALUMNO REGULAR/MODELO DE CARTA ALUMNO REGULAR MAESTRIA.docx'
import insConvalidacion1 from '../../assets/files/ConsejoTecnico/InstructivosPresenciales/CONVALIDACION/INSTRUCTIVO PARA CONVALIDACION.docx'
import insConvalidacion2 from '../../assets/files/ConsejoTecnico/InstructivosPresenciales/CONVALIDACION/INSTRUCTIVO PARA CONVALIDACION.pdf'
import insConvalidacion3 from '../../assets/files/ConsejoTecnico/InstructivosPresenciales/CONVALIDACION/MODELO DE CARTA CONVALIDACION ESPECIALIDAES.docx'
import insConvalidacion4 from '../../assets/files/ConsejoTecnico/InstructivosPresenciales/CONVALIDACION/MODELO DE CARTA CONVALIDACION MAESTRIA.docx'
import insDesincorp1 from '../../assets/files/ConsejoTecnico/InstructivosPresenciales/DESINCORPORACION/INSTRUCTIVO PARA DESINCORPORACION.docx'
import insDesincorp2 from '../../assets/files/ConsejoTecnico/InstructivosPresenciales/DESINCORPORACION/INSTRUCTIVO PARA DESINCORPORACION.pdf'
import insDesincorp3 from '../../assets/files/ConsejoTecnico/InstructivosPresenciales/DESINCORPORACION/MODELO DE CARTA DESINCORPORACION ESPECIALIDAES.docx'
import insDesincorp4 from '../../assets/files/ConsejoTecnico/InstructivosPresenciales/DESINCORPORACION/MODELO DE CARTA DESINCORPORACION MAESTRIA.docx'
import insProyecto1 from '../../assets/files/ConsejoTecnico/InstructivosPresenciales/PROYECTO DE INVESTIGACION/INSTRUCTIVO PROYECTO DE INVESTIGACION.docx'
import insProyecto2 from '../../assets/files/ConsejoTecnico/InstructivosPresenciales/PROYECTO DE INVESTIGACION/INSTRUCTIVO PROYECTO DE INVESTIGACION.pdf'
import insProyecto3 from '../../assets/files/ConsejoTecnico/InstructivosPresenciales/PROYECTO DE INVESTIGACION/MODELOS DE CARTAS PROYECTO DE INVESTIGACION ESPECIALIDADES.docx'
import insProyecto4 from '../../assets/files/ConsejoTecnico/InstructivosPresenciales/PROYECTO DE INVESTIGACION/MODELOS DE CARTAS PROYECTO DE INVESTIGACION MAESTRIA.docx'
import insReconocimiento1 from '../../assets/files/ConsejoTecnico/InstructivosPresenciales/RECONOCIMIENTO DE CREDITOS/INSTRUCTIVO PARA RECONOCIMIENTO DE CREDITOS.docx'
import insReconocimiento2 from '../../assets/files/ConsejoTecnico/InstructivosPresenciales/RECONOCIMIENTO DE CREDITOS/INSTRUCTIVO PARA RECONOCIMIENTO DE CREDITOS.pdf'
import insReconocimiento3 from '../../assets/files/ConsejoTecnico/InstructivosPresenciales/RECONOCIMIENTO DE CREDITOS/MODELO DE CARTA RECONOCIMIENTO DE CREDITOS ESPECIALIDAES.docx'
import insReconocimiento4 from '../../assets/files/ConsejoTecnico/InstructivosPresenciales/RECONOCIMIENTO DE CREDITOS/MODELO DE CARTA RECONOCIMIENTO DE CREDITOS MAESTRIA.docx'
import insReincorp1 from '../../assets/files/ConsejoTecnico/InstructivosPresenciales/REINCORPORACION/INSTRUCTIVO PARA REINCORPORACION.docx'
import insReincorp2 from '../../assets/files/ConsejoTecnico/InstructivosPresenciales/REINCORPORACION/INSTRUCTIVO PARA REINCORPORACION.pdf'
import insReincorp3 from '../../assets/files/ConsejoTecnico/InstructivosPresenciales/REINCORPORACION/MODELO DE CARTA REINCORPORACION ESPECIALIDAES.docx'
import insReincorp4 from '../../assets/files/ConsejoTecnico/InstructivosPresenciales/REINCORPORACION/MODELO DE CARTA REINCORPORACION MAESTRIA.docx'
import insRenuncia1 from '../../assets/files/ConsejoTecnico/InstructivosPresenciales/RENUNCIA/MODELO DE CARTA RENUNCIA DOCTORADO.docx'
import insRenuncia2 from '../../assets/files/ConsejoTecnico/InstructivosPresenciales/RENUNCIA/MODELO DE CARTA RENUNCIA ESPECIALIDAES.docx'
import insRenuncia3 from '../../assets/files/ConsejoTecnico/InstructivosPresenciales/RENUNCIA/MODELO DE CARTA RENUNCIA MAESTRIAS.docx'
import insTesisDoc1 from '../../assets/files/ConsejoTecnico/InstructivosPresenciales/TESIS DOCTORAL/INSTRUCTIVO DE TESIS DOCTORAL.docx'
import insTesisDoc2 from '../../assets/files/ConsejoTecnico/InstructivosPresenciales/TESIS DOCTORAL/INSTRUCTIVO DE TESIS DOCTORAL.pdf'
import insTesisDoc3 from '../../assets/files/ConsejoTecnico/InstructivosPresenciales/TESIS DOCTORAL/MODELOS DE CARTAS TESIS DOCTORAL.doc'
import insTrabajoGrado1 from '../../assets/files/ConsejoTecnico/InstructivosPresenciales/TRABAJO DE GRADO/INTRODUCCION TRABAJO DE GRADO.docx'
import insTrabajoGrado2 from '../../assets/files/ConsejoTecnico/InstructivosPresenciales/TRABAJO DE GRADO/INTRODUCCION TRABAJO DE GRADO.pdf'
import insTrabajoGrado3 from '../../assets/files/ConsejoTecnico/InstructivosPresenciales/TRABAJO DE GRADO/MODELOS DE CARTAS TRABAJO DE GRADO.doc'
import insTEG1 from '../../assets/files/ConsejoTecnico/InstructivosPresenciales/TRABAJO ESPECIAL DE GRADO/INSTRUCTIVO TRABAJO ESPECIAL DE GRADO.docx'
import insTEG2 from '../../assets/files/ConsejoTecnico/InstructivosPresenciales/TRABAJO ESPECIAL DE GRADO/INSTRUCTIVO TRABAJO ESPECIAL DE GRADO.pdf'
import insTEG3 from '../../assets/files/ConsejoTecnico/InstructivosPresenciales/TRABAJO ESPECIAL DE GRADO/MODELOS DE CARTAS TRABAJO ESPECIAL DE GRADO.doc'
import insVeredictoDoc1 from '../../assets/files/ConsejoTecnico/InstructivosPresenciales/VEREDICTO TESIS DOCTORAL/INSTRUCTIVO VEREDICTO TESIS DOCTORAL.docx'
import insVeredictoDoc2 from '../../assets/files/ConsejoTecnico/InstructivosPresenciales/VEREDICTO TESIS DOCTORAL/INSTRUCTIVO VEREDICTO TESIS DOCTORAL.pdf'
import insVeredictoDoc3 from '../../assets/files/ConsejoTecnico/InstructivosPresenciales/VEREDICTO TESIS DOCTORAL/MODELO DE CARTA VEREDICTO TESIS DOCTORAL.docx'
import insVeredTrabGrado1 from '../../assets/files/ConsejoTecnico/InstructivosPresenciales/VEREDICTO TRABAJO DE GRADO/INSTRUCTIVO VEREDICTO TRABAJO DE GRADO.docx'
import insVeredTrabGrado2 from '../../assets/files/ConsejoTecnico/InstructivosPresenciales/VEREDICTO TRABAJO DE GRADO/INSTRUCTIVO VEREDICTO TRABAJO DE GRADO.pdf'
import insVeredTrabGrado3 from '../../assets/files/ConsejoTecnico/InstructivosPresenciales/VEREDICTO TRABAJO DE GRADO/MODELO DE CARTA VEREDICTO TRABAJO DE GRADO.doc'
import insVeredictoTeg1 from '../../assets/files/ConsejoTecnico/InstructivosPresenciales/VEREDICTO TRABAJO ESPECIAL DE GRADO/INSTRUCTIVO VEREDICTO TRABAJO ESPECIAL DE GRADO.docx'
import insVeredictoTeg2 from '../../assets/files/ConsejoTecnico/InstructivosPresenciales/VEREDICTO TRABAJO ESPECIAL DE GRADO/INSTRUCTIVO VEREDICTO TRABAJO ESPECIAL DE GRADO.pdf'
import insVeredictoTeg3 from '../../assets/files/ConsejoTecnico/InstructivosPresenciales/VEREDICTO TRABAJO ESPECIAL DE GRADO/MODELO CARTA DE CUMPLIMIENTO DE OBLIGACIONES ASISTENCIALES.docx'
import insVeredictoTeg4 from '../../assets/files/ConsejoTecnico/InstructivosPresenciales/VEREDICTO TRABAJO ESPECIAL DE GRADO/MODELO DE CARTA VEREDICTO TRABAJO ESPECIAL DE GRADO.doc'

const InstructivosConsejoTec = () => {  
  const info = {
    tramites: [
        "RENUNCIAS",
        "ABANDONOS",
        "DESINCORPORACIÓN",
        "REINCORPORACIÓN",
        "CAMBIO DE TUTOR ACADÉMICO",
        "CAMBIO DE TÍTULO", 
        "ALUMNOS CONDICIONADOS",
        "PROYECTO DE INVESTIGACIÓN",
        "TRABAJO DE GRADO",
        "TRABAJO ESPECIAL DE GRADO",
        "TESIS DOCTORAL",
        "VERIDICTOS (MAESTRÍAS, ESPECIALIDADES Y DOCTORADOS)",
        "APROBACIÓN DE BAREMO (POSGRADOS Y DOCTORADOS)", 
        "APROBACIÓN DE CONCURSOS (POSGRADOS Y DOCTORADOS)",
        "APROBACIÓN DE CURSOS DEL PEC"        
    ],
    instructivos: [
        {
            categoria: "Abandono",
            items: [
                {
                    nombre: "Modelo de Carta Abandonono Doctorado",
                    tipo: "word",
                    link: archivoAbandono1
                },
                {
                    nombre: "Modelo de Carta Abandonono Especialidades",
                    tipo: "word",
                    link: archivoAbandono2
                },
                {
                    nombre: "Modelo de Carta Abandonono Maestrías",
                    tipo: "word",
                    link: archivoAbandono3
                }
            ]
        },
        {
            categoria: "Alumno Condicionado",
            items: [
                {
                    nombre: "Modelo de Carta Alumno Condicionado Especialidades",
                    tipo: "word",
                    link: archivoCondicionado1
                },
                {
                    nombre: "Modelo de Carta Alumno Condicionado Maestría",
                    tipo: "word",
                    link: archivoCondicionado2
                }
            ]
        },
        {
            categoria: "Alumno Especial",
            items: [
                {
                    nombre:"Modelo de Carta Alumno Especial Especialidades",
                    tipo: "word",
                    link: insEspecial1
                },
                {
                    nombre:"Modelo de Carta Alumno Especial Maestría",
                    tipo: "word",
                    link: insEspecial2
                }
            ]
        },
        {
            categoria: "Alumno Regular",
            items: [
                {
                    nombre:"Modelo de Carta Alumno Regular Especialidades",
                    tipo: "word",
                    link: insRegular1
                },
                {
                    nombre:"Modelo de Carta Alumno Regular Maestría",
                    tipo: "word",
                    link: insRegular2
                }
            ]
        },
        {
            categoria: "Convalidación",
            items: [
                {
                    nombre: "Instructivo para Convalidación",
                    tipo: "word",
                    link: insConvalidacion1
                },
                {
                    nombre: "Instructivo para Convalidación",
                    tipo: "pdf",
                    link: insConvalidacion2
                },
                {
                    nombre: "Modelo de Carta Convalidación Especialidades",
                    tipo: "word",
                    link: insConvalidacion3
                },
                {
                    nombre: "Modelo de Carta Convalidación Maestría",
                    tipo: "word",
                    link: insConvalidacion4
                }
            ]
        },
        {
            categoria: "Desincorporación",
            items: [
                {
                    nombre: "Instructivo para Desincorporación",
                    tipo: "word",
                    link: insDesincorp1
                },
                {
                    nombre: "Instructivo para Desincorporación",
                    tipo: "pdf",
                    link: insDesincorp2
                },
                {
                    nombre: "Modelo de Carta Desincorporación Especialidades",
                    tipo: "word",
                    link: insDesincorp3
                },
                {
                    nombre: "Modelo de Carta Desincorporación Maestría",
                    tipo: "word",
                    link: insDesincorp4
                }
            ]
        },
        {
            categoria: "Proyecto de Investigación",
            items: [
                {
                    nombre: "Instructivo Proyecto de Investigación",
                    tipo: "word",
                    link: insProyecto1
                },
                {
                    nombre: "Instructivo Proyecto de Investigación",
                    tipo: "pdf",
                    link: insProyecto2
                },
                {
                    nombre: "Modelos de Cartas Proyecto de Investigación Especialidades",
                    tipo: "word",
                    link: insProyecto3
                },
                {
                    nombre: "Modelos de Cartas Proyecto de Investigación Maestría",
                    tipo: "word",
                    link: insProyecto4
                }
            ]
        },
        {
            categoria: "Reconocimiento de Créditos",
            items: [
                {
                    nombre: "Instructivo para Reconocimiento de Créditos",
                    tipo: "word",
                    link: insReconocimiento1
                },
                {
                    nombre: "Instructivo para Reconocimiento de Créditos",
                    tipo: "pdf",
                    link: insReconocimiento2
                },
                {
                    nombre: "Modelo de Carta Reconocimiento de Créditos Especialidades",
                    tipo: "word",
                    link: insReconocimiento3
                },
                {
                    nombre: "Modelo de Carta Reconocimiento de Créditos Maestría",
                    tipo: "word",
                    link: insReconocimiento4
                }
            ]
        },
        {
            categoria: "Reincorporación",
            items: [
                {
                    nombre: "Instructivo para Reincorporación",
                    tipo: "word",
                    link: insReincorp1
                },
                {
                    nombre: "Instructivo para Reincorporación",
                    tipo: "pdf",
                    link: insReincorp2
                },
                {
                    nombre: "Modelo de Carta Reincorporación Especialidades",
                    tipo: "word",
                    link: insReincorp3
                },
                {
                    nombre: "Modelo de Carta Reincorporación Maestría",
                    tipo: "word",
                    link: insReincorp4
                }
            ]
        },
        {
            categoria: "Renuncia",
            items: [
                {
                    nombre: "Modelo de Carta Renuncia Doctorado",
                    tipo: "word",
                    link: insRenuncia1
                },
                {
                    nombre: "Modelo de Carta Renuncia Especialidades",
                    tipo: "word",
                    link: insRenuncia2
                },
                {
                    nombre: "Modelo de Carta Renuncia Maestría",
                    tipo: "word",
                    link: insRenuncia3
                }
            ]
        },
        {
            categoria: "Tesis Doctoral",
            items: [
                {
                    nombre: "Instructivo de Tesis Doctoral",
                    tipo: "word",
                    link: insTesisDoc1
                },
                {
                    nombre: "Instructivo de Tesis Doctoral",
                    tipo: "pdf",
                    link: insTesisDoc2
                },
                {
                    nombre: "Modelos de Cartas Tesis Doctoral",
                    tipo: "word",
                    link: insTesisDoc3
                }
            ]
        },
        {
            categoria: "Trabajo de Grado",
            items: [
                {
                    nombre: "Introducción Trabajo de Grado",
                    tipo: "word",
                    link: insTrabajoGrado1
                },
                {
                    nombre: "Introducción Trabajo de Grado",
                    tipo: "pdf",
                    link: insTrabajoGrado2
                },
                {
                    nombre: "Modelos de Cartas Trabajo de Grado",
                    tipo: "word",
                    link: insTrabajoGrado3
                }
            ]
        },
        {
            categoria: "Trabajo Especial de Grado",
            items: [
                {
                    nombre: "Instructivo Trabajo Especial de Grado",
                    tipo: "word",
                    link: insTEG1
                },
                {
                    nombre: "Instructivo Trabajo Especial de Grado",
                    tipo: "pdf",
                    link: insTEG2
                },
                {
                    nombre: "Modelos de Cartas Trabajo Especial de Grado",
                    tipo: "word",
                    link: insTEG3
                }
            ]
        },
        {
            categoria: "Veredicto Tesis Doctoral",
            items: [
                {
                    nombre: "Instructivo Veredicto Tesis Doctoral",
                    tipo: "word",
                    link: insVeredictoDoc1
                },
                {
                    nombre: "Instructivo Veredicto Tesis Doctoral",
                    tipo: "pdf",
                    link: insVeredictoDoc2
                },
                {
                    nombre: "Modelo de Carta Tesis Doctoral",
                    tipo: "word",
                    link: insVeredictoDoc3
                }
            ]
        },
        {
            categoria: "Veredicto Trabajo de Grado",
            items: [
                {
                    nombre: "Instructivo Veredicto Trabajo de Grado",
                    tipo: "word",
                    link: insVeredTrabGrado1
                },
                {
                    nombre: "Instructivo Veredicto Trabajo de Grado",
                    tipo: "pdf",
                    link: insVeredTrabGrado2
                },
                {
                    nombre: "Modelo de Carta Veredicto Trabajo de Grado",
                    tipo: "word",
                    link: insVeredTrabGrado3
                }
            ]
        },
        {
            categoria: "Veredicto Trabajo Especial de Grado",
            items: [
                {
                    nombre: "Instructivo Veredicto Trabajo Especial de Grado",
                    tipo: "word",
                    link: insVeredictoTeg1
                },
                {
                    nombre: "Instructivo Veredicto Trabajo Especial de Grado",
                    tipo: "pdf",
                    link: insVeredictoTeg2
                },
                {
                    nombre: "Modelo de Carta de Cumplimiento de Obligaciones Asistenciales",
                    tipo: "word",
                    link: insVeredictoTeg3
                },
                {
                    nombre: "Modelo de Carta Veredicto Trabajo Especial de Grado",
                    tipo: "word",
                    link: insVeredictoTeg4
                }
            ]
        }
    ]
  }

  return (
    <>
      <h2 className="subpage-title mb-4">Instructivos Presenciales</h2>
      <Accordion alwaysOpen>
        {info.instructivos.map((categoria, index) => (
          <Accordion.Item eventKey={index} key={index}>
            <Accordion.Header className="accordion-title">
              {categoria.categoria + " "} 
            </Accordion.Header>
            <Accordion.Body>
              <ul className="document-list">
              {
                categoria.items.map((item, index) =>
                <li className="list-item" key={index}>
                  <a href={item.link} download={item.nombre} className="download-link" >
                  {
                    item.tipo === "word" && <i class="bi bi-file-word-fill word"></i>
                  }
                  {
                    item.tipo === "pdf" && <i class="bi bi-file-earmark-pdf-fill pdf"></i>
                  }
                    <span className="button-name">{item.nombre}</span>
                  </a>
                </li>
              )}
              </ul>
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </>
  );
};

export { InstructivosConsejoTec };
