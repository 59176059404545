import React from 'react'
import { Header } from '../components/Header'
import { NavigationBar } from '../components/NavigationBar'
import { Footer } from '../components/Footer'
import { Link, Outlet, useLocation } from 'react-router-dom'
import routes from '../routes/routes'

const info = {
  descripcion: "El departamento de Archivo de la División de Estudios para Graduados de la Facultad de Medicina de LUZ, es el encargado de guardar y ordenadamente bajo ciertas categorías, la documentación académica y administrativa de la División, en el reposan los documentos de los aspirantes o estudiantes de los diferentes programas de Posgrado, con el fin de facilitar su ubicación, búsqueda y consulta. \n\nPara realizar trámites de retiro de documentos ver formulario de solicitud. ",
  submenu: [
    {
      opcion: 'Contenido principal',
      link: '/archivo'
    },
    {
      opcion: 'Trámites',
      link: '/tramites'
    }
  ],
  linkFormSolicitud: 'https://docs.google.com/forms/d/e/1FAIpQLSfT7cAWkNII098JDfw3mbEJTIKyihJvl28iEp7CU0F_7jqRgA/viewform'
}

const Archivo = () => {
  const location = useLocation();

  const parentRoute = routes.find(route => route.path === 'archivo');
  const mainPath = '/' + parentRoute.path;
  // const currentPage = parentRoute.children.find(child => child.path)
  let submenuLinks = [
    {
      opcion: 'Contenido principal',
      link: mainPath
    }
  ]
  parentRoute.children.map(child => submenuLinks.push({
    opcion: child.title,
    link: `/${parentRoute.path}/${child.path}`
  }));
  const isMainPage = location.pathname === mainPath

  return (
    <>
      <Header />
      <NavigationBar />
      <main className='main main-archivo'>
         <section className='main-container dept-main'>
          <div className={`side-container ${isMainPage ? 'main-page' : 'child'}`}>
            <div className='header-box side'>
              <h1 className='page-title text-center'>Archivo</h1>
            </div>

            <div className={`perfil-card side-card ${!isMainPage && 'd-none'}`}>
              <div className='perfil-img-placeholder'></div>
              <h4 className='perfil-nombre'>Univ. José David Rodríguez Salazar</h4>
              <span className='perfil-cargo fw-semibold'>Auxiliar de Archivo</span>
            </div>

            <div className='contacto-card side-card'>
              <h4 className='card-title'>Contacto</h4>
              <span className='span-correo'>archivodepgfmluz@gmail.com</span>
            </div>

            <div className='side-card submenu-card'>
              <ul className='submenu-list std-list'>
              {
                submenuLinks.map((item, index) => 
                  <li className='list-item' key={index}>
                    <Link to={item.link} className='list-link'>{item.opcion} </Link>
                  </li>
                )
              }
              </ul>
            </div>
          </div>
          
          <div className='central-container'>
            <div className='header-box'>
            {
              location.pathname !== (mainPath) &&
              <></>
            }
            </div>
          {
            location.pathname === mainPath
            ? <>
              <p className='p-contenido descripcion ws-pw' dangerouslySetInnerHTML={{__html: info.descripcion}}/>

              <a href={info.linkFormSolicitud} target='blank' className='link-button btn btn-outline-primary'>
                Formulario de solicitud
              </a>
            </>
            : 
            <Outlet/>
          }
          </div>
         </section>
      </main>
      <Footer />
    </>
  )
}

export { Archivo }