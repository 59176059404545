import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'

const info = {
  retiro_documentos: 'Para el retiro de documentos, el solicitante debe llenar el link del formulario de solicitud, colocando correctamente los datos solicitados para su búsqueda y verificación en el sistema interno de la División. \n\nUna vez verificada la información, el departamento hará la entrega de los documentos solicitados. ',
  autorizados: [
    'Egreso',
    'Renuncia',
    'No ser seleccionado en el concurso'
  ],
  footer_retiro: 'El retiro de todo documento en la División es de carácter  personal, en caso de retirar un tercero, debe traer un poder debidamente notariado, que contenga los datos del estudiante y de la persona que retira. ',
  tramites: [
    'Consulta de documentación pendiente por actualizar o consignar. ',
    'Recepción de documentos del Concurso. ',
    'Retiro de premios o reconocimientos. ',
    'Retiro de reconocimientos a los Jefes de Residentes. '    
  ],
  linkFormSolicitud: 'https://docs.google.com/forms/d/e/1FAIpQLSfT7cAWkNII098JDfw3mbEJTIKyihJvl28iEp7CU0F_7jqRgA/viewform'
}

const ArchivoTramites = () => {
  const location = useLocation();
  const pathArray = location.pathname.split('/');
  const currentPath = pathArray[pathArray.length-1];

  return (
    <div className='page-section tramites'>
      <h2 className='subpage-title-header border-bottom'>Trámites y Solicitudes</h2>
    {
      currentPath === 'tramites' ?
      <>
        <div className='sub-section'>
          <h3 className='subpage-title'>Retiro de Documentos</h3>

          <p className='info-content ws-pw'>{info.retiro_documentos}</p>

          <h4 className='normal-subtitle fw-bold'>¿Quiénes pueden retirar sus documentos?</h4>

          <p>Aquellas personas cuyo estatus administrativo sea: </p>
          <ul className='lista-personas-retiro'>
          {
            info.autorizados.map((item, index) => 
              <li key={index} className='list-item'>{item}</li>
            )
          }
          </ul>

          <p className='p-footer'>{info.footer_retiro}</p>

          <a 
            className='link-button btn btn-primary'
            href={info.linkFormSolicitud}
            target='blank'
          >
              Formulario de solicitud
          </a>
        </div>

        <div className='sub-section'>
          <h3 className='subpage-title'>Trámites</h3>

          <p>En el departamento de Archivo, usted también podrá realizar los siguientes trámites: </p>

          <ul className='lista-tramites arrow-list'>
          {
            info.tramites.map((item, index) =>
              <li key={index} className='list-item fw-semibold'>{item}</li>
            )
          }
          </ul>
        </div>

      </>
      :
      <Outlet />
    }
    </div>
  )
}

export { ArchivoTramites }