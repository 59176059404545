import React from 'react'
import { Link, Outlet, useLocation, useParams } from 'react-router-dom'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import dataProgramas from '../../assets/data/programas_academicos.json'

const info = {
  descripcion: 'Los programas de Doctorados ofertados, por la División de Estudios para Graduados de la Facultad de Medicina de LUZ, tienen como propósito la formación de recursos humanos de alto nivel académico, técnico y científico, con un excelente nivel de preparación para la investigación y la producción de propuestas originales que constituyan un aporte significativo al acervo del conocimiento en el área de la Ciencia y constituyen el espacio para <b>formar  investigadores de alta acreditación nacional e internacional</b> en función de las demandas científicas, económicas y sociales de la región y del país. Actualmente se ofertan tres programas: ',
  programas: [
    {
      nombre: 'Doctorado de Ciencias de la Salud',
      coordinador: 'Dr. Domingo Bracho',
      link: ''
    },
    {
      nombre: 'Doctorado de Ciencias Médicas',
      coordinador: 'Dr. Anaximenes León',
      link: ''
    },
    {
      nombre: 'Doctorado en Metabolismo Humano',
      coordinador: 'Dra. Mayerlin Bracho',
      link: ''
    }
  ],
  parrafos: [
    'Estos programas desarrollan una concepción científica y humanista, abierta a todas las corrientes del pensamiento, apoyada en los principios filosóficos del currículo de la Universidad del Zulia, de excelencia académica, con compromiso social. ',
    'El proceso de selección de los aspirantes a realizar alguno de los Doctorados que ofrece la División de Estudios para Graduados de la Facultad de Medicina comprende varias etapas, que deben cumplirse en forma progresiva de acuerdo a una normativa que garantice la imparcialidad, la equidad y la rectitud del mismo. '
  ],
  secretaria: 'Anny Tessari',
  correo: 'Doctoradodepgfmedluz@gmail.com',
  pathConcurso: '/concurso/doctorados-2024'
}

function Doctorados() {
  const location = useLocation();
  const params = useParams();
  const selectedItem = dataProgramas.programas[0].children.find((programa) => programa.pathname === params.pathname)

  const doctoradosRoot = dataProgramas.programas.find((item) => item.pathname === 'doctorados')
  const doctorados = doctoradosRoot.children.filter((item) => item.active === true)
  return (
    <>
      <Breadcrumb className='programas-breadcumb border-bottom'>
        {
          selectedItem ?
            (
              <>
                <Breadcrumb.Item >
                  <Link to="/posgrado/doctorados" className='breadcumb-link'>Doctorados</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  <span>{selectedItem.nombre}</span>
                </Breadcrumb.Item>
              </>
            ) :
            (
              <Breadcrumb.Item active>Doctorados</Breadcrumb.Item>
            )
        }
      </Breadcrumb>
      {
        selectedItem ? <Outlet /> :
          <section className='section-index doctorados-index'>
            <h1 className='section-title mb-4'>Doctorados</h1>
            
            <p className='p-descripcion' dangerouslySetInnerHTML={{__html: info.descripcion}}/>

            <div className='doctorados-container'>
            {
              doctorados.map((programa, index) => 
              <Link to={`${location.pathname}/${programa.pathname}`} key={index} className='doctorado-card'>
                  <span className='doctorado-span nombre'>{programa.nombre}</span>
                  <span className='doctorado-span coord'>Coordinador: {programa.coordinador}</span>
              </Link>
            )
            }
            </div>

            {
              info.parrafos.map((parrafo, index) =>
              <p key={index} className='info-p'>{parrafo}</p>
            )
            }

            <div className='sub-container d-flex flex-wrap'>
              <div className='contacto-card side-card'>
                <div className='span-group'>
                  <span className='group-title'>Secretaria: </span>
                  <span>{info.secretaria}</span>
                </div>

                <div className='span-group'>
                  <span className='group-title'>Correo: </span>
                  <span>{info.correo}</span>
                </div>
              </div>

              <div className='button-article'>
                <h4 className='article-header'>Concurso Doctorados 2024</h4>
                <Link 
                  className='btn btn-primary w-100 link-button baremo-button' 
                  to={info.pathConcurso} 
                >
                  Más información
                </Link>
              </div>
            </div>


            <p className='mb-4 d-none'>Para visualizar un programa, utilice el menú de navegación lateral, si está accediendo desde una computadora.</p>

            <p className='d-none'>Si está visualizando desde un teléfono móvil, utilice el botón ubicado en la parte derecha de la barra superior para desplegar el menú de navegación. </p>
          </section>
      }
    </>
  )
}

export { Doctorados }