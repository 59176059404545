import React from 'react'
import { Header } from '../components/Header'
import { NavigationBar } from '../components/NavigationBar'
import { Footer } from '../components/Footer'
import { SidebarPEC } from '../components/SidebarPEC'
import { Outlet, useLocation } from 'react-router-dom'

const info = {
  pathname: "/cursos-pec",
  text: "La División de Estudios para Graduados de la Facultad de Medicina de LUZ, ofrece programas de Educación Continua que buscan preparar los profesionales de la salud en los distintos cursos avanzados que se imparte bajo este programa.\n\nCon la finalidad de  potenciar su actualización en los nuevos conocimientos, Los programas de educación continua en salud pueden abarcar una amplia gama de temas, desde actualizaciones en protocolos de tratamiento hasta avances en tecnología médica, gestión de la salud, ética profesional, entre otros. \n\nEste programa es Coordinado por la <b>Dra. Hazel Anderson</b>, profesora emeritus de la Facultad de Medicina de LUZ y Coordinadora de la Maestría Nutrición Clínica de la División de Estudios para Graduados de la Facultad de Medicina LUZ. \n\nLos cursos son certificados por la División de la Facultad de Medicina de LUZ y contemplan horas créditos que los profesionales de la salud, que pueden utilizar para  ampliar su currículo académico. \n\nParticipar en estos programas permite a los profesionales mantenerse al tanto de los últimos avances en su campo y brindar un mejor cuidado a sus pacientes. "
}

const CursosPEC = () => {
  const location = useLocation();

  return (
    <>
    <Header/>
    <NavigationBar activeLink={'Programas de Estudio'}/>
    <main  className={`main main-cursos-pec ${(location.pathname !== info.pathname) ? 'subpage' : 'standard' }`}>
        <SidebarPEC pathname={location.pathname} />
        <section className='central-container'>
          <div className='section-header'>
            <h2 className='section-title'>Programa de Educación Continua</h2>
          </div>
          <div className='section-body'>
          {
            location.pathname === info.pathname ?
            <p dangerouslySetInnerHTML={{__html: info.text}} className='section-content ws-pw'/>
            :
            <Outlet />
          }
          </div>
        </section>
    </main>
    <Footer/>
    </>
  )
}

export { CursosPEC }