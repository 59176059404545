import React from 'react'
import { Header } from '../components/Header'
import { NavigationBar } from '../components/NavigationBar'
import { Footer } from '../components/Footer'

const info = {
   descripcion: 'La Sección de Acreditación y Currículo de la División de Estudios para Graduados de la Facultad de Medicina, es la encargada de llevar a cabo la creación, revisión, reformulación y acreditación de programas de posgrado. \n\nEntre sus funciones esta tiene; propiciar el mejoramiento en la calidad de la educación superior, la idoneidad y la solidez de la institución, sirviendo como garante de cumplir con los lineamientos preestablecidos por Oficina de Planificación del Sector Universitario (OPSU), leyes y reglamentos aplicados para la creación, reformulación y acreditación de programas de posgrado. ',
   funciones: [
      "Velar y dar cumplimiento de los lineamientos preestablecidos por Oficina de Planificación del Sector Universitario (OPSU), leyes y reglamentos aplicados para la creación, reformulación y acreditación de programas de posgrado. ",
      "Garantizar que la División de Estudios para Graduados lleve a cabo sus propósitos y objetivos y que los cumplan con los más altos estándares de calidad, otorgándole a los programas el reconocimiento a la excelencia en la educación superior y permitiendo ubicarlos como referentes ante otros entes, no solo a nivel nacional sino también internacionales. ",
      "Evaluar, planificar, orientar y dirigir la creación de programas de posgrado a nivel de especialidad, maestrías y doctorados. ",
      "Autoevaluar, planificar, orientar y dirigir la reformulación y acreditación de programas de posgrado a nivel de especialidad, maestrías y doctorados. ",
      "Dar seguimiento en los procesos académicos en busca del mejoramiento continuo y que evidencie la excelencia de los programas de posgrado; basado en un proceso previo de evaluación en el que participa toda la comunidad académica (estudiantes activos, egresados, y profesores). "     
   ],
   correo: 'acreditaciondepgfmluz@gmail.com'
}

const AcreditacionCurriculo = () => {
   return (
   <>
      <Header />
      <NavigationBar activeLink={'Directorio'} />
      <main className='main main-acreditacion-curriculo'>
         <section className='main-container dept-main'>
          <div className='side-container'>
            <div className='header-box side'>
              <h1 className='page-title text-center'>Acreditación y Currículo</h1>
            </div>
            <div className='perfil-card side-card'>
              <div className='perfil-img-placeholder'></div>
              <h4 className='perfil-nombre'>Dra. María Corina Gómez</h4>
              <span className='perfil-cargo fw-semibold'>Coordinadora</span>
            </div>
            <div className='contacto-card side-card'>
              <h3 className='card-title'>Contacto</h3>
              <span className='span-correo'>{info.correo}</span>
            </div>
          </div>
          
          <div className='central-container'>
            <div className='header-box'></div>

            <p className='p-contenido descripcion' dangerouslySetInnerHTML={{__html: info.descripcion}}/>

            <h3 className='subtitle'>Funciones del Departamento</h3>
            <ul className='lista-funciones'>
            {
               info.funciones.map((elemento, index) =>
               <li className='list-item' key={index}>{elemento}</li>
            )}
            </ul>
          </div>
         </section>
      </main>
      <Footer />
    </>
  )
}

export { AcreditacionCurriculo }