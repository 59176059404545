import React from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import routes from "./routes/routes";
import ScrollToTop from "./functions/ScrollToTop";

function App() {
  
  return (
    <>
      <Router>
        <Routes>
          {
            routes.map((route) => (
              <Route key={route.path} path={route.path} element={route.component}>
                { route.children && (route.children.map(children => (
                  <>
                  {
                    route.variableChildren ?
                    <Route path=':pathname' element={children.component} ></Route>
                    :
                    <Route key={children.path} path={children.path} element={children.component}>
                      {
                        children.childrenCompontent && (
                          <>
                            <Route path=':pathname' element={children.childrenCompontent} ></Route>
                          </>
                        )
                      }
                    </Route>
                  }
                  </>
                )))
                }
              </Route>
            )
          )}
        </Routes>
        <ScrollToTop />
      </Router>
    </>
    
  );
}

export default App;