import React from 'react'

const info = {
    cursos: [
        {
            nombre: 'Prueba de Suficiencia de Inglés',
            coordinador: 'Coordinadora: Lcda. Virginia Novoa'
        },
        {
            nombre: 'Prueba de Salud Mental: Entrevista Psiquiátrica',
            coordinador: 'Dr. Cesar Marín Marcano'
        }
    ],
    nota: 'La oferta de los cursos que se realizan en el PEC, son evaluados por la Coordinación para ser aprobados por el Consejo Técnico de la División y  luego promocionados por el departamento de Relaciones Públicas, a través de la página web de posgrado, así como también en las plataformas digitales y de comunicación de la División.'
}

const PecCursosPermanentes = () => {
  return (
    <div className='page-section cursos-permanentes'>
        <h3 className='subpage-title'>Cursos permanentes</h3>

        <div className='cursos-container'>
        {
            info.cursos.map((curso, index) => 
            <div key={index} className='curso-item'>
                <span className='curso-span nombre'>{curso.nombre}</span>
                <span className='curso-span coord'>{curso.coordinador}</span>
            </div>
        )}
        </div>

        <p className='cursos-nota justify-text'>{info.nota}</p>
    </div>
  )
}

export { PecCursosPermanentes }