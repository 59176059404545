import React from 'react'
import archivo_solicitud from '../../assets/files/PLANILLA DE SOLICITUD DE CURSO.doc'

const info = {
    descripcion: 'Dentro de los trámites y solicitudes que se realizan  del Programa de Educación Continua del PEC, destacan: ',
    tramites: [
        "Constancia de la Prueba de Suficiencia de Inglés. ",
        "Certificados de los Cursos Aprobados por el PEC. ",
        "Entrevistas Psiquiátricas. ",
        "Constancias academice a Docentes y Facilitadores de los Cursos. ",
        "Formato de Solicitud de Curso."        
    ]
}

const PecTramites = () => {
  return (
    <div className='page-section tramites'>
        <h3 className='subpage-title'>Trámites y solicitudes</h3>
        
        <p className='descripcion justify-text'>{info.descripcion}</p>

        <ol className='lista-tramites'>
        {
            info.tramites.map((tramite, index) =>
            <li key={index} className='list-item'>{tramite}</li>
        )}
        </ol>

        <a className='btn btn-primary download-button' href={archivo_solicitud} download={'Planilla de Solicitud de Curso'}>
            <i class="bi bi-file-earmark-word-fill me-2"></i>
            <span>Descargar formato de solicitud de curso</span>
        </a>
    </div>
  )
}

export { PecTramites }