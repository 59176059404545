import React from 'react'

function TabDescripcion ({ programData }) {

  return (
    <>
      {
        programData.release ?
        <div className='tab-descripcion'>
          <p className='descripcion descripcion-principal mb-4 ws-pw'>
            {programData.descripcion.principal}
          </p>

        {
          programData.descripcion.detallada && 
          <p className='tab-text-last ws-pw'>
            {programData.descripcion.detallada}
          </p>
        }
        </div>
        :
        <span>En construcción...</span>
      }
    </>
  )
}

export { TabDescripcion }