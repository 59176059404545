import React, { useState } from 'react'
import { Header } from '../components/Header'
import { NavigationBar } from '../components/NavigationBar'
import { Footer } from '../components/Footer'
import { SidebarProgramas } from '../components/SidebarProgramas'
import { Outlet, useLocation } from 'react-router-dom'
import { Offcanvas } from 'react-bootstrap'

function Programas() {
  const location = useLocation();

  const [showSidebar, setShow] = useState(false);

  const toggleData = (data) => {
    setShow(data);
  }

  const toggleSidebar = (action) => {
    setShow(action);
  }

  const handleClose = () => {
    setShow(false);
  }

  return (
    <>
      <Header/>
      <NavigationBar activeLink={'Programas de Estudio'} toggleButton={'Programas'} toggleFunction={toggleData}/>
      <div className='page-container container-programas p-0 d-flex'>
        <SidebarProgramas/>

        <Offcanvas show={showSidebar} onHide={handleClose} >
          <SidebarProgramas toggleName={'Offcanvas'} toggleOffcanvas={toggleSidebar} />
        </Offcanvas>
        <main className='main main-programas'>
        {
          !(location.pathname === "/posgrado")
          ?  <Outlet/>
          :  
          <section className='section-index programas-index'>
            <h1 className='section-title border-bottom pb-3'>Posgrado</h1>

            <p className='mb-5'>Para visualizar un programa, utilice el menú de navegación lateral, si está accediendo desde una computadora.</p>

            <p>Si está visualizando desde un teléfono móvil, utilice el botón ubicado en la parte derecha de la barra superior para desplegar el menú de navegación. </p>
          </section>
        }
        </main>
      </div>
      <Footer/>
    </>
  )
}

export { Programas }