import React from 'react'

function TabObjetivos({ programData }) {

  return (
    <>
    {
      programData.release ?
      <div className='tab-objetivos'>
        <div className='tab-card card-objetivos generales'>
          <h3 className='sub-titulo subtitulo-objetivos'>{programData.objetivos.unicos ? 'Objetivos' : 'Objetivos Generales'}</h3>

          {
            programData.objetivos.general_descripcion && <p>{programData.objetivos.general_descripcion}</p>
          }

          <ul className='tab-list lista-objetivos generales'>
          {
            programData.objetivos.unicos ?
            programData.objetivos.unicos.map((objetivo, index) => 
              <li key={index} className='elemento-lista'>{objetivo}</li>
            ) 
            :
            programData.objetivos.generales.map((objetivo, index) => 
              <li key={index} className='elemento-lista'>{objetivo}</li>
            )
          }
          </ul>
        </div>

      {
        programData.objetivos.especificos && 
        <div className='tab-card card-objetivos especificos'>
          <h3 className='sub-titulo subtitulo-objetivos'>Objetivos Específicos</h3>

          {
            programData.objetivos.especificos_descripcion && <p>{programData.objetivos.especificos_descripcion}</p>
          }
          <ul className='tab-list lista-objetivos especificos'>
          {
            programData.objetivos.especificos.map((objetivo, index) => 
              <li key={index} className='elemento-lista'>{objetivo}</li>
            )
          }
          </ul>
          {
            programData.objetivos.especificos_footer && <p>{programData.objetivos.especificos_footer}</p>
          }
        </div>
      }

      </div>
      :
      <span>En construcción...</span>
    }
    </>
  )
}

export { TabObjetivos }