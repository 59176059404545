import React from 'react'

function TabRequisitos ({ programData }) {
  return (
    <>
    {
      programData.release ?
      <div className='tab-requisitos'>
        <div className='tab-card'>
        {
          programData.requisitos.ingreso &&
          <>
            <div className='tab-heading'>
              <h3 className='sub-titulo ingreso'>Requisitos de Ingreso</h3>

              {
                programData.requisitos.ingresoDescripcion && 
                <span className='span-descripcion'>{programData.requisitos.ingresoDescripcion}</span>
              }
            </div>

            <div className='card-content card-requisitos ingreso'>
              {
                (programData.requisitos.layoutIngreso && programData.requisitos.layoutIngreso === 'ol') ?
                <ol className='tab-list lista-requisitos'>
                {
                  !programData.requisitos.sublistas ?
                  programData.requisitos.ingreso.map((element, index) => 
                    <li key={index} className='elemento-lista'>{element}</li>
                  )
                  :
                  programData.requisitos.ingreso.map((element, index) => 
                    <>
                      <li key={index} className='elemento-lista'>{element.item}</li>
                      {
                        element.sublista &&
                        <ul className='sublista nested'>
                        {
                          element.sublista.map((item, index) => 
                            <li className='elemento-lista' key={index}>{item}</li>
                          )
                        } 
                        </ul>
                      }
                    </>
                  )
                }
                </ol>
                :
                <ul className='tab-list lista-requisitos'>
                {
                  !programData.requisitos.sublistas ?
                  programData.requisitos.ingreso.map((element, index) => 
                    <li key={index} className='elemento-lista'>{element}</li>
                  )
                  :
                  programData.requisitos.ingreso.map((element, index) => 
                    <>
                      <li key={index} className='elemento-lista'>{element.item}</li>
                      {
                        element.sublista &&
                        <ul className='sublista nested'>
                        {
                          element.sublista.map((item, index) => 
                            <li className='elemento-lista' key={index}>{item}</li>
                          )
                        } 
                        </ul>
                      }
                    </>
                  )
                }
                </ul>
              }
              {
                programData.requisitos.footer_ingreso && 
                <p className='footer-text' dangerouslySetInnerHTML={{__html: programData.requisitos.footer_ingreso}} />
              }
              {
                programData.requisitos.especial &&
                <div className='card-requisito-especial'>
                  <h3 className='sub-titulo'>Requisito Especial</h3>
                  <ul className='tab-list'>
                  {
                    programData.requisitos.especial.items.map((item, index) =>
                      <li key={index} className='elemento-lista'>{item}</li>
                    )
                  }
                  </ul>
                </div>
              }
            </div>
          </>
        }
      
          <h3 className='sub-titulo egreso'>Requisitos de Egreso</h3>

          <div className='card-content card-requisitos egreso'>
            <ul className='tab-list lista-requisitos'>
            {
              programData.requisitos.egreso.map((element, index) => 
                <li key={index} className='elemento-lista'>
                {
                  !element.subelementos ?
                  <>
                    <span className='element-title fw-bold'>{element.nombre}:</span>
                    <p className='element-description'>{element.descripcion}</p>
                  </>
                  :
                  <>
                    <span className='element-title fw-bold'>{element.nombre}</span>
                    {
                      element.subelementos.map((item, index) =>
                      <div className='nested-group' key={index}>
                        <span className='element-title fw-semibold'>{item.titulo}</span>
                        <span className='element-title'>{item.descripcion}</span>
                      </div>
                    )}
                  </>
                }
                </li>
              )
            }
            </ul>
          </div>
        </div>
      </div>
      :
      <span>En construcción...</span>
    }
    </>
  )
}

export { TabRequisitos }