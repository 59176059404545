import React from 'react'
import { Button, Modal } from 'react-bootstrap';

const ModalConsultaEstatus = ({ toggleFunction, modalState }) => {
  return (
    <Modal show={modalState} onHide={() => toggleFunction(false)} dialogClassName='custom-lg'>
      <Modal.Header closeButton>
        <Modal.Title>Consulta tu estatus administrativo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <section className='info-container text-center'>

          <div className='info-group'>
            <span className='info-span'>Consulta tu estatus administrativo de forma presencial en nuestra sede o mediante el correo electrónico: </span>
            <a href="mailto:infopagosdepgfmluz@gmail.com" className='std-link fw-semibold' target='blank'>infopagosdepgfmluz@gmail.com</a>
          </div>

          <div className='info-group text-start'>
            <ul className='std-list'>
              <li className='list-item'>
                Los pagos de cada trimestre se deben cumplir los primeros cinco (05) días del inicio del trimestre.
              </li>
              <li className='list-item'>
                Para realizar cualquier proceso académico o administrativo es importante estar solvente con la División.
              </li>
            </ul>
          </div>
        </section>
      </Modal.Body>
      <Modal.Footer>
        <Button className='footer-button' variant="secondary" onClick={()=> toggleFunction(false)}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export { ModalConsultaEstatus }