import React from 'react'
import { Header } from '../components/Header'
import { NavigationBar } from '../components/NavigationBar'
import { Footer } from '../components/Footer'
// import img_perfil from '../assets/images/Perfiles/Dr. Sergio Osorio Morales - Decano.jpg'
import img_perfil from '../assets/images/Perfiles/Dr. Sergio Osorio Morales - Decano (2).jpg'

const info = {
    parrafos: [
        'La Facultad de Medicina de la Universidad del Zulia es una organización academica-cientifica en el cumplimiento de sus funciones de docencia, investigación y extensión, innova en la formación de profesionales de las ciencias de la salud, competentes y provistos de valores necesarios para la búsqueda del bienestar social y calidad de vida de todos los ciudadanos. Genera y difunde conocimientos científicos y tecnológicos orientados a la solución de problemas en el área de la salud. Se vincula con el entorno mediante un intercambio equilibrado que deja un valor agregado tanto a la institución como a la comunidad.',
        'La Facultad de Medicina está conformada por las cuatro Escuelas, Medicina, Bioanalisis, Enfermería y Nutrición y Dietética, así como también Institutos y Divisiones. ',
        'Actualmente el Dr. Sergio Osorio Morales es el Decano de la Facultad de Medicina desde el año 2008, es Médico-Cirujano egresado de la Facultad de Medicina de LUZ en el año 1990, con especialización en Cirugía General y Cirugía Forense- Servicio Nacional de Medicina y Ciencias Forenses (SENAMECF), Doctor en Ciencias Médicas egresado de la Universidad del Zulia (LUZ) en año 1998. Doctor en Ciencias de la Educación, egresado de la Universidad Dr. Rafael Belloso Chacín (URBE), en año 2008. Doctor en Ciencias Gerenciales Universidad egresado de la Universidad Dr. Rafael Belloso Chacín (URBE), en el año 2016. Doctor en Ciencias de la Salud egresado de la Universidad del Zulia (LUZ), en el año 2021. Post-Doctorado Gerencia en las Organizaciones, egresado de la Universidad Dr. Rafael Belloso Chacín (URBE), en el año 2010. Profesor Titular de la Facultad de Medicina LUZ. Individuo de Número Sillón XXXIV de la ANM desde noviembre de 2023. Coordinador del Programa de Posgrado de Cirugía General con sede Hospital General del Sur. ',
        'El Decano de la Facultad de Medicina de LUZ, es la persona encargada de dirigir, planificar y evaluar el cumplimiento de los objetivos planteados por  la institución. '
    ]
}

const Facultad = () => {
  return (
    <>
    <Header />
    <NavigationBar activeLink={'Directorio'} />
    <main className='main main-facultad'>
        <h1 className='page-title text-center w-100'>Facultad de Medicina</h1>
        <section className='main-container dept-main'>
            <div className='side-container'>
                <article className='perfil-card side-card'>
                    <img src={img_perfil} className='img-perfil' alt="Dr. Sergio Osorio Morales - Decano Facultad de Medicina LUZ" />
                    <h4 className='perfil-nombre'>Dr. Sergio Osorio Morales</h4>
                    <span className='perfil-cargo fw-semibold'>Decano</span>
                </article>
            </div>

            <div className='central-container'>
            {
                info.parrafos.map((item, index) => 
                    <p className='info-p' key={index}>{item}</p>
                )
            }
            </div>
        </section>
    </main>
    <Footer />
    </>
  )
}

export { Facultad }