import React from 'react'
import routes from '../../routes/routes'
import { Outlet, useLocation } from 'react-router-dom';

const ConcursosTramites = () => {
    const location = useLocation();

    const parentRoute = routes.find(route => route.path === 'concurso');
    const childRoute = parentRoute.children.find(child => child.path === 'tramites');
    let buttons = [];
    childRoute.children.forEach(child => {
        child.active && buttons.push({
            name: child.title,
            disabled: child.disabled,
            path: ""
            // path: "/" + parentRoute.path + "/" + childRoute.path + "/" + child.path
        });
    });

    const localPath = `/${parentRoute.path}/${childRoute.path}`
    return (
        <div className='page-section tramites'>
        {
            location.pathname === localPath
            ? <>
                <h3 className='subpage-title'>Trámites</h3>

                <div className='buttons-container'>
                {
                    buttons.map((button, index) => 
                    <a 
                        key={index}
                        href={'/'}
                        target='blank'
                        className={`btn-tramite btn btn-primary ${button.disabled ? 'disabled' : ''}`}
                    >
                        {button.name}
                    </a>
                )}
                </div>
            </>
            :
            <Outlet/>
        }
        </div>
    )
}

export { ConcursosTramites }