import React from 'react'

function Sedes() {
  return (
    <>
        <>
            <h1 className='nombre-programa mt-2 mb-4'>{'Unidades Docentes (Sedes)'}</h1>
            <span>En construcción...</span>
        </>
    </>
  )
}

export { Sedes }