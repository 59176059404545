import React from 'react'

function TabDescripcion ({ programData }) {

  return (
    <>
      {
        programData.release ?
        <div className='tab-descripcion'>
        {
          !programData.descripcion.custom ?
          <>
            <p className='descripcion descripcion-principal mb-4 ws-pw'>
              {programData.descripcion.principal}
            </p>

            {
              programData.descripcion.detallada && 
              <p className='tab-text-last ws-pw'>
                {programData.descripcion.detallada}
              </p>
            }
          </>
          :
          <>
          {
            programData.descripcion.items_descripcion.map((item, index) => 
            <div className={`item-descripcion custom-mg-${ item.custom_margin ?? 'std'}`} key={index}>
            {
              item.tipo === "parrafo" ?
              <p className={`ws-pw p-${item.heading ? 'heading' : 'std'}`}>{item.contenido}</p>
              :
              item.tipo_lista === "ol" 
              ? <ol className={`lista-descripcion ${item.nested ? 'nested-list' : 'std'}`}>
              {
                item.elementos.map((elemento, index) =>
                  <li className='elemento-lista' key={index}>{elemento}</li>
                )
              }
              </ol> 
              : <ul className={`lista-descripcion ${item.nested ? 'nested-list' : 'std'} ${item.style_type} ${item.continuous ? 'continuous' : ''}`}>
              {
                item.elementos.map((elemento, index) =>
                  <li className='elemento-lista' key={index}>{elemento}</li>
                )
              }
              </ul> 
            }
            </div>
            )
          }
          </>
        }
        </div>
        :
        <span>En construcción...</span>
      }
    </>
  )
}

export { TabDescripcion }