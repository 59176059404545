import React from 'react'
import { Link, Outlet, useParams } from 'react-router-dom'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import dataProgramas from '../../assets/data/programas_academicos.json'

function Maestrias() {
  const params = useParams();
  const selectedItem = dataProgramas.programas[1].children.find((programa) => programa.pathname === params.pathname)

  return (
    <>
      <Breadcrumb className='programas-breadcumb border-bottom'>
        {
          selectedItem ? 
          (
            <>
            <Breadcrumb.Item >
              <Link to="/posgrado/maestrias" className='breadcumb-link'>Maestrías</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{selectedItem.nombre}</Breadcrumb.Item>
            </>
          ) : 
          (
              <Breadcrumb.Item active>Maestrías</Breadcrumb.Item>
          )
        }
      </Breadcrumb>
      {
        selectedItem ? <Outlet/> : 
        <section className='section-index maestrias-index'>
          <h1 className='section-title mb-4'>Maestrías</h1>

          <p className='mb-4'>Para visualizar un programa, utilice el menú de navegación lateral, si está accediendo desde una computadora.</p>

          <p>Si está visualizando desde un teléfono móvil, utilice el botón ubicado en la parte derecha de la barra superior para desplegar el menú de navegación. </p>
        </section>
      }
    </>
  )
}

export { Maestrias }