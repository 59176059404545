import React from 'react'
import { Button, Modal } from 'react-bootstrap';
import img_modal from '../../assets/images/ModalInicio/Reconsideraciones2024_Modal.png'

const ModalReconsideraciones = ({ toggleFunction, modalState }) => {
  const formUrl = 'https://forms.gle/chaWiiJ7GCns3tbn6'

  return (
    <Modal show={modalState} onHide={() => toggleFunction(false)} dialogClassName='custom-lg'>
      <Modal.Header closeButton>
        <Modal.Title>Reconsideraciones 2024</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <section className='info-container text-center'>
          <img src={img_modal} alt='Recepción Acto de Grado' className='modal-banner sm card mb-4' />

          <div className='info-group mb-3'>
            <a href={formUrl} className='link-button btn btn-primary rounded-pill' target='blank'>Ir al formulario</a>
          </div>
        </section>
      </Modal.Body>
      <Modal.Footer>
        <Button className='footer-button' variant="secondary" onClick={()=> toggleFunction(false)}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export { ModalReconsideraciones }