import React from 'react'
import { Header } from '../components/Header'
import { NavigationBar } from '../components/NavigationBar'
import { Footer } from '../components/Footer'

const info = {
  parrafos: [
    'El Departamento de Relaciones Públicas es el encargado de generar comunicaciones estratégicas enfocadas en construir relaciones ventajosas entre las organizaciones y su público. ',
    'Este departamento se encarga de construir y gestionar la imagen de la institución de cara al público, desde los empleados, usuarios, los medios de comunicación y la sociedad en general. ',
    'Es el encargado de difundir las comunicaciones e informaciones de la División, así como también de supervisar y dar respuestas a las inquietudes, dudas y preguntas que se generan en las plataformas digitales. '
  ],
  correo: 'postgradodemedicinagregory@gmail.com'
}

const RelacionesPublicas = () => {
  // document.title = 'Relaciones Públicas | División de Estudios para Graduados | Facultad de Medicina LUZ'

  return (
    <>
    <Header />
    <NavigationBar activeLink={'Directorio'}/>
    <main className='main main-rrpp'>
      <div className='main-container dept-main'>
        <section className='side-container'>
          <div className='header-box side'>
            <h1 className='page-title'>Relaciones Públicas</h1>
          </div>

          <div className='perfil-card side-card'>
            <div className='perfil-img-placeholder'></div>
            <h4 className='perfil-nombre'>TSU. Gregory Reyes</h4>
            <span className='perfil-cargo'>Comunicaciones y RRPP</span>
          </div>

          <div className='contacto-card side-card px-2'>
            <h3 className='card-title'>Contacto</h3>
            <span className='span-correo'>{info.correo}</span>
          </div>
        </section>

        <section className='central-container'>
          <div className='header-box'></div>
        {
          info.parrafos.map((item, index) =>
            <p className='info-p' key={index}>{item}</p>
          )
        }
        </section>
      </div>
    </main>
    <Footer />
    </>
  )
}

export { RelacionesPublicas }