import { Home } from '../pages/Home'
import { Programas } from '../pages/Programas'
import { Doctorados } from '../components/Programas/Doctorados';
import { ItemDoctorado } from '../components/Programas/ItemDoctorado';
import { Maestrias } from '../components/Programas/Maestrias';
import { ItemMaestria } from '../components/Programas/ItemMaestria';
import { Especialidades } from '../components/Programas/Especialidades';
import { ItemEspecialidad } from '../components/Programas/ItemEspecialidad';
import { Sedes } from '../components/Programas/Sedes';
import { Contacto } from '../pages/Contacto';
import { Direccion } from '../pages/Direccion';
import { ConsejoTecnico } from '../pages/ConsejoTecnico';
import { TramitesDireccion } from '../pages/subpages/TramitesDireccion';
import { TramitesConsejoTec } from '../pages/subpages/TramitesConsejoTec';
import { InstructivosConsejoTec } from '../pages/subpages/InstructivosConsejoTec';
import { SecretariaDocente } from '../pages/SecretariaDocente';
import { CursosPEC } from '../pages/CursosPEC';
import { PecProgramasFrecuentes } from '../pages/subpages/PEC_ProgramasFrecuentes';
import { PecCursosPermanentes } from '../pages/subpages/PEC_CursosPermanentes';
import { PecTramites } from '../pages/subpages/PEC_Tramites';
import { Concursos } from '../pages/Concursos';
import { ConcursosNormativa } from '../pages/subpages/ConcursosNormativa';
import { ConcursosListados } from '../pages/subpages/ConcursosListados';
import { ConcursosTramites } from '../pages/subpages/ConcursosTramites';
import { ControlEstudios } from '../pages/ControlEstudios';
import { AcreditacionCurriculo } from '../pages/AcreditacionCurriculo';
import { Archivo } from '../pages/Archivo';
import { ArchivoTramites } from '../pages/subpages/ArchivoTramites';
import { ArchivoSolicitudRetiro } from '../pages/subpages/ArchivoSolicitudRetiro';
import { Administracion } from '../pages/Administracion';
import { AdministracionTramites } from '../pages/subpages/AdministracionTramites';
import { Facultad } from '../pages/Facultad';
import { RelacionesPublicas } from '../pages/RRPP';
import { Informatica } from '../pages/Informatica';
import { ConcursosOfertaCupos } from '../pages/subpages/Concursos_OfertaCupos';
import { Comites, ItemComite } from '../pages/Comites';
import { ConcursoInstructivos } from '../pages/subpages/ConcursoInstructivos';
import { ConcursoDoctorados } from '../pages/subpages/ConcursoDoctorados';
import { ConcursoPrueba } from '../pages/subpages/ConcursoPrueba';
import { CheckUbicacion } from '../pages/subpages/CheckUbicacion';
import { ConcursoInscripciones } from '../pages/subpages/ConcursoInscripciones';

const routes = [
    {
        title: 'Posgrado',
        path: 'posgrado',
        component: <Programas/>,
        children: [
            {
                title: 'Doctorados',
                path: 'doctorados',
                component: <Doctorados/>,
                index: 2,
                childrenCompontent: <ItemDoctorado />,
            },
            {
                title: 'Maestrías',
                path: 'maestrias',
                component: <Maestrias />,
                index: 1,
                childrenCompontent: <ItemMaestria />
            },
            {
                title: 'Especialidades',
                path: 'especialidades',
                component: <Especialidades />,
                index: 0,
                childrenCompontent: <ItemEspecialidad />
            },
            {
                title: 'Unidades Docentes (Sedes)',
                path: 'unidades-docentes',
                component: <Sedes />,
                index: 3
            },
        ]
    },
    {
        title: 'Contacto',
        path: 'contacto',
        component: <Contacto/>
    },
    {
        title: 'Home',
        path: '/',
        component: <Home/>
    },
    {
        title: 'Dirección',
        path: 'direccion',
        component: <Direccion />,
        children: [
            {
                title: "Trámites",
                path: "tramites",
                component: <TramitesDireccion />
            }
        ]
    },
    {
        title: 'Consejo Técnico',
        path: 'consejo-tecnico',
        component: <ConsejoTecnico />,
        children: [
            {
                title: "Trámites",
                path: "tramites",
                component: <TramitesConsejoTec />
            },
            {
                title: "Instructivos Presenciales",
                path: "instructivos",
                component: <InstructivosConsejoTec />
            },
        ]
    },
    {
        title: 'Secretaría Docente',
        path: 'secretaria-docente',
        component: <SecretariaDocente />
    },
    {
        title: 'Cursos PEC',
        path: 'cursos-pec',
        component: <CursosPEC />,
        children: [
            {
                title: 'Programas Frecuentes',
                path: 'programas-frecuentes',
                component: <PecProgramasFrecuentes />
            },
            {
                title: 'Cursos Permanentes',
                path: 'cursos-permanentes',
                component: <PecCursosPermanentes />
            },
            {
                title: 'Trámites y Solicitudes',
                path: 'tramites-solicitudes',
                component: <PecTramites />
            }
        ]
    },
    {
        title: 'Concurso 2024',
        path: 'concurso',
        component: <Concursos />,
        children: [
            {
                title: 'Instructivo',
                path: 'instructivo',
                active: true,
                component: <ConcursoInstructivos />
            },
            {
                title: 'Normativa',
                path: 'normativa',
                active: true,
                component: <ConcursosNormativa />
            },
            {
                title: 'Listados',
                path: 'listados',
                active: false,
                component: <ConcursosListados />
            },
            {
                title: 'Trámites',
                path: 'tramites',
                active: false,
                component: <ConcursosTramites />,
                children: [
                    {
                        title: 'Registro al Concurso',
                        path: 'registro',
                        disabled: false,
                        active: true,
                        component: <></>
                    },
                    {
                        title: 'Reconsideraciones',
                        path: 'reconsideraciones',
                        disabled: true,
                        active: false,
                        component: <></>
                    },
                    {
                        title: 'Inscripción',
                        path: 'inscripcion',
                        disabled: true,
                        active: false,
                        component: <></>
                    }
                ]
            },
            {
                title: 'Oferta de Cupos',
                path: 'oferta-de-cupos',
                active: true,
                component: <ConcursosOfertaCupos />
            },
            {
                title: 'Concurso Doctorados',
                path: 'doctorados-2024',
                active: true,
                component: <ConcursoDoctorados />
            },
            {
                title: 'Prueba de Conocimientos',
                path: 'prueba-conocimientos',
                active: false,
                component: <ConcursoPrueba />
            },
            {
                title: 'Listado de Ganadores',
                path: 'listado-ganadores',
                active: true,
                component: <ConcursoInscripciones />
            }
        ]
    },
    {
        title: 'Control de Estudios',
        path: 'control-estudios',
        component: <ControlEstudios />
    },
    {
        title: 'Acreditación y Currículo',
        path: 'acreditacion-curriculo',
        component: <AcreditacionCurriculo />
    },
    {
        title: 'Archivo',
        path: 'archivo',
        component: <Archivo />,
        children: [
            {
                title: 'Trámites y Solicitudes',
                path: 'tramites',
                component: <ArchivoTramites />,
                childrenCompontent: <ArchivoSolicitudRetiro />
            }
        ]
    },
    {
        title: 'Administración',
        path: 'administracion',
        component: <Administracion />,
        children: [
            {
                title: 'Trámites y Solicitudes',
                path: 'tramites',
                component: <AdministracionTramites />
            }
        ]
    },
    {
        title: 'Facultad de Medicina',
        path: 'facultad-de-medicina',
        component: <Facultad />
    },
    {
        title: 'Relaciones Públicas',
        path: 'relaciones-publicas',
        component: <RelacionesPublicas />
    },
    {
        title: 'Departamento de Informática',
        path: 'informatica',
        component: <Informatica />
    },
    {
        title: 'Comités Académicos',
        path: 'comites-academicos',
        component: <Comites />,
        variableChildren: true,
        children: [
            { 
                title: 'Item Comité',
                component: <ItemComite />
            }
        ]
    },
    {
        title: 'Check Ubicaciones',
        path: 'ubicaciones-concurso',
        component: <CheckUbicacion />
    }
]

export default routes;