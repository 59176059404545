import React from 'react'
import doc_ciencias_salud from '../../../assets/images/planes_estudio/Doctorados/Doctorado_Ciencias_Salud.png'
import doc_ciencias_medicas from '../../../assets/images/planes_estudio/Doctorados/Doctorado_Ciencias_Medicas.png'
import doc_metabolismo_humano from '../../../assets/images/planes_estudio/Doctorados/Doctorado_Metabolismo_Humano.png'

function TabPlanEstudios ({ programData }) {
  const planes = [
    {
      programa: "doctorado-ciencias-salud",
      imagen: doc_ciencias_salud
    },
    {
      programa: "doctorado-ciencias-medicas",
      imagen: doc_ciencias_medicas
    },
    {
      programa: "doctorado-metabolismo-humano",
      imagen: doc_metabolismo_humano
    }
  ]

  const programaActual = planes.find(plan =>
    plan.programa === programData.plan_estudios.imagen_pde
  );

  return (
    <>
    {
      programData.release ?
      <div className='tab-plan-estudios'>
        <h3 className='sub-titulo'>Plan de Estudios</h3>

        <div className='d-flex flex-column align-items-center'>
          {
            (programData.plan_estudios.imagen_pde !== "--pendiente--") ?
            <figure className='plan-container'>
                <img 
                  className={`plan-estudios-img ${ programData.plan_estudios.img_layout ?? 'standard' }` } 
                  alt='Plan de Estudios' 
                  src={programaActual.imagen}
                />
            </figure>
            :
            <div class="spinner-border text-primary mb-3" style={{'height': "30px", "width": "30px"}} role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          }
          <div className={`plan-footer text-center fw-semibold d-flex flex-wrap justify-content-center ${programData.plan_estudios?.footer===false && 'd-none'}`}>
            <span className='footer-text'>HT: Horas teóricas. </span>
            <span className='footer-text'>HP: Horas prácticas. </span>
            <span className='footer-text'>HPP: Horas de Práctica Profesional. </span>
            <br/>
            <span className='footer-text'>HTol: Horas totales semanales. </span>
            {
              programData.plan_estudios.uc && 
              <span className='footer-text'>UC: Unidades créditos</span>
            }
          </div>
        </div>

      {
        (programData.plan_estudios.layout === 1 && !(programData.plan_estudios.no_electivas)) && 
        <>
          {
            programData.plan_estudios.notas_header && programData.plan_estudios.notas_header.map((element, index) => 
              <p key={index} className='list-parag'>{element}</p>
            )
          }
          <span className='text-footer fw-bold'>{programData.plan_estudios.footer_title}</span>
          {
            programData.plan_estudios.footer &&
            <span className='text-footer'>{programData.plan_estudios.footer}</span>
          }
          <ul className='tab-list'>
          {
            programData.plan_estudios.electivas.map((element, index) => 
              <li key={index} className='element-list'>{element}</li>
            )
          }
          </ul>
          {
            programData.plan_estudios.nota && <>
              <span className='fw-bold'>Nota: </span><span>{programData.plan_estudios.nota} </span>
            </>
          }
        </>
      }
      {
        (programData.plan_estudios.layout === 2 && !(programData.plan_estudios.no_electivas)) && 
        <>
          {
            programData.plan_estudios.footer_title &&
            <div className='mb-4'>
              <span className='text-footer fw-semibold'>{programData.plan_estudios.footer_title}</span>
            {
              programData.plan_estudios.footer && 
              <span className='text-footer'>{programData.plan_estudios.footer}</span>
            }
            </div>
          }
          {
            programData.plan_estudios.electivas.map((electiva, index) => 
              <div key={index} className='tab-footer-group'>
                <span className='fw-bold'>{electiva.titulo}</span>
                <span>{electiva.descripcion}</span>

                <ul className='tab-list mb-0'>
                {
                  electiva.materias.map((materia, index) =>
                    <li key={index} className='list-item'>{materia}</li>
                  )
                }
                </ul>

                <span className='fw-semibold'>{electiva.footer}</span>
              </div>
            )
          }
        </>
      }
      </div>
      :
      <span>En construcción...</span>
    }
    </>
  )
}

export { TabPlanEstudios }