import React from 'react'
import { Accordion } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import programasAcademicos from "../assets/data/programas_academicos.json"

const sortedProgramas = programasAcademicos.programas.toSorted((a,b) => a.sidebarIndex - b.sidebarIndex);

function SidebarProgramas({ toggleName, toggleOffcanvas }) {
  const location = useLocation();

  return (
    <>
        <aside className='sidebar-container'>
          <div className='sidebar-header d-flex align-items-center'>
            <span className='sidebar-title fw-semibold'>Posgrado</span>
            {
              toggleName && ( <i className='close-button bi bi-x-lg' onClick={()=> toggleOffcanvas(false)}></i> )
            }
          </div>
          <div className='sidebar-inner d-flex flex-column'>
            {
              sortedProgramas.map((programa, index) => (programa.sidebarVisible === true) && (
                <Accordion key={index} className='sidebar-accordion' 
                  defaultActiveKey={(location.pathname === `/posgrado/${programa.pathname}`) ? programa.sidebarIndex : -1}>

                  <Accordion.Item eventKey={programa.sidebarIndex}>
                    <Accordion.Header>
                      <span className='accordion-title fw-semibold'>{programa.display_name}</span>
                    </Accordion.Header>
                    <Accordion.Body className='d-flex flex-column'>
                      <ul className='sidebar-list'>
                      {
                        programa.children.map((item, index) => (
                          <>
                            {
                              toggleName 
                              ? (
                                  <Link
                                    key={index}
                                    to={`/posgrado/${programa.pathname}/${item.pathname}`}
                                    className='accordion-item-link'
                                    onClick={() => toggleOffcanvas(false)}>
                                    {
                                      <li className='accordion-list-item'>{item.nombre}</li>
                                    }
                                    {
                                      item.badge && (
                                        <span className={`badge rounded-pill bg-${item.badge.variant}`}>{item.badge.name}</span>
                                      )
                                    }
                                  </Link>
                                ) : (
                                  item.active &&
                                  <Link
                                    key={index}
                                    to={`/posgrado/${programa.pathname}/${item.pathname}`}
                                    className='accordion-item-link'>
                                    {
                                      <li className='accordion-list-item'>{item.nombre}</li>
                                    }
                                    {
                                      item.badge && (
                                        <span className={`badge rounded-pill bg-${item.badge.variant}`}>{item.badge.name} </span>
                                      )
                                    }
                                  </Link>
                            )}
                          </>
                        ))
                      }
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              ))
            }
          </div>
        </aside>
    </>
  )
}

export { SidebarProgramas }