import React from 'react'

function TabRequisitos ({ programData }) {
  return (
    <>
    {
      programData.release ?
      <div className='tab-requisitos'>
        <div className='tab-card'>
          <div className='tab-heading'>
            <h3 className='sub-titulo ingreso'>Requisitos de Ingreso</h3>

            {
              programData.requisitos.ingresoDescripcion && 
              <span className='span-descripcion'>{programData.requisitos.ingresoDescripcion}</span>
            }
          </div>

          <div className='card-content card-requisitos ingreso'>
            <ul className='tab-list lista-requisitos'>
            {
              programData.requisitos.ingreso.map((element, index) => 
                <li key={index} className='elemento-lista'>{element}</li>
              )
            }
            </ul>

            {
              programData.requisitos.footer_ingreso && 
              <p className='footer-text' dangerouslySetInnerHTML={{__html: programData.requisitos.footer_ingreso}} />
            }

            {
              programData.requisitos.especial &&
              <div className='card-requisito-especial'>
                <h3 className='sub-titulo'>Requisito Especial</h3>
                <ul className='tab-list'>
                {
                  programData.requisitos.especial.items.map((item, index) =>
                    <li key={index} className='elemento-lista'>{item}</li>
                  )
                }
                </ul>
              </div>
            }

          </div>

          {
            programData.requisitos.seleccion && 
            <div className='card-content card-requisitos'>
              <h3 className='sub-titulo ingreso mt-0'>Criterios de Selección</h3>

              <p className='span-descripcion ws-pw' dangerouslySetInnerHTML={{__html: programData.requisitos.seleccion.descripcion ?? ''}}/>

              {
                programData.requisitos.seleccion.requisitos && 
                <ul className='tab-list lista-criterios'>
                {
                  programData.requisitos.seleccion.requisitos.map((item, index) => 
                    <li key={index} className='elemento-lista'>
                      {item.descripcion}
                    {
                      item.sublista && 
                      <ul className='sublista-seleccion'>
                      {
                        item.sublista.map((element, index) => 
                          <li key={index} className='elemento-lista'>{element}</li>
                        )
                      }
                      </ul>
                    }
                    </li>                
                  )
                }
                </ul>
              }
              
            </div>
          }

          {
            programData.requisitos.documentos_ob &&
            <div className='card-content card-requisitos documentos'>
              <h3 className='sub-titulo ingreso mt-0'>Documentos Obligatorios</h3>

              <p className='span-descripcion ws-pw' dangerouslySetInnerHTML={{__html: programData.requisitos.documentos_ob.descripcion ?? ''}}/>

              <ul className='tab-list lista-criterios nested-list'>
              {
                programData.requisitos.documentos_ob.items.map((item, index) =>
                  <li key={index} className='elemento-lista'>
                    <p className='p-lista' dangerouslySetInnerHTML={{__html: item}}/>
                  </li>
                )
              }
              </ul>

              {
                programData.requisitos.documentos_ob.subparrafos &&
                programData.requisitos.documentos_ob.subparrafos.map((item, index) => 
                  <p key={index} className='info-p ws-pw' dangerouslySetInnerHTML={{__html: item}}/>
                )
              }
            </div>
          }

          {
            programData.requisitos.criterios && 
            <div className='card-content card-requisitos criterios'>
              <h3 className='sub-titulo ingreso mt-0'>Criterios de Selección</h3>

              <div className='card-content card-requisitos'>
                <ul className='tab-list lista-criterios nested-list'>
                {
                  programData.requisitos.criterios.items.map((element, index) => 
                    <li key={index} className='elemento-lista'>{element}</li>
                  )
                }
                </ul>
                {
                  programData.requisitos.criterios.nota &&
                  <p dangerouslySetInnerHTML={{__html: programData.requisitos.criterios.nota}} className='p-nota'/>
                }
              </div>

              {
                programData.requisitos.criterios.proceso &&
                <>
                  <p className='heading-p ws-pw'>{programData.requisitos.criterios.proceso.descripcion}</p>

                  <ul className='tab-list lista-seleccion'>
                  {
                    programData.requisitos.criterios.proceso.fases.map((fase, index) =>
                      <li className='elemento-lista'>
                        <p className='fase-desc ws-pw' dangerouslySetInnerHTML={{__html: fase.desc}} key={index} />

                        {
                          fase.lista &&
                          <>
                            <p className='lista-desc'>{fase.lista.descripcion ?? ''}</p>
                            <ul className={`lista-fase type-${fase.lista.tipo} nested-list`}>
                            {
                              !fase.lista.sublistas ?
                              fase.lista.items.map((item, index) => 
                                <li className='elemento-lista' key={index}>{item}</li>
                              )
                              :
                              fase.lista.items.map((item, index) => 
                                <li className='elemento-lista' key={index}>
                                  <p className='item-desc'>{item.desc}</p>
                                  
                                  {
                                    item.sublista &&
                                    <ul className={`sublista type-${item.tipo_sublista} nested-list`}>
                                    {
                                      item.sublista.map((subitem, index) =>
                                        <li className='elemento-lista' key={index}>{subitem}</li>
                                      )
                                    }
                                    </ul>
                                  }

                                  {
                                    item.footer &&
                                    <p className={`sublista-footer text-${item.footer.align} mb-0`}>{item.footer.texto}</p>
                                  }
                                </li>
                              )
                            }
                            </ul>
                          </>
                        }
                      </li>
                    )
                  }  
                  </ul>

                  {
                    programData.requisitos.criterios.proceso.parrafos &&
                    programData.requisitos.criterios.proceso.parrafos.map((item, index) =>
                      <p className='info-p' key={index}>{item}</p>
                    ) 
                  }
                </>
              }
            </div>
          }


          <div className='card-content card-requisitos egreso'>
            <h3 className='sub-titulo egreso'>Requisitos de Egreso</h3>

            <ul className='tab-list lista-requisitos'>
            {
              programData.requisitos.egreso.items.map((element, index) => 
                <li key={index} className='elemento-lista'>
                {
                  !element.subelementos ?
                  <>
                    <span className='element-title fw-bold'>{element.nombre !== "" ? `${element.nombre}:` : ""}</span>
                    <p className='element-description ws-pw'>{element.descripcion}</p>

                    {
                      element.subparrafos &&
                      element.subparrafos.map((parr, index) =>
                        <p className='sub-p' key={index}>{parr}</p>
                      )
                    }

                    {
                      element.lista &&
                      <ul className={`lista-requisito type-${element.lista.tipo}`}>
                      {
                        element.lista.elementos.map((item, index) => 
                          <li className='elemento-lista' key={index}>
                            <p className='ws-pw'>{item.texto}</p>
                            {
                              item.sublista &&
                              <ul className={`sublista type-${item.sublista.tipo}`}>
                              {
                                item.sublista.items.map((subitem, index) => 
                                  <li className='elemento-lista' key={index}>{subitem}</li>
                                )
                              }
                              </ul>
                            }
                          </li>
                        )
                      }
                      </ul>
                    }
                  </>
                  :
                  <>
                    <span className='element-title fw-bold'>{element.nombre}</span>
                    {
                      element.subelementos.map((item, index) =>
                      <div className='nested-group' key={index}>
                        <span className='element-title fw-semibold'>{item.titulo}</span>
                        <span className='element-title'>{item.descripcion}</span>
                      </div>
                    )}
                  </>
                }
                </li>
              )
            }
            </ul>
          </div>
        </div>
      </div>
      :
      <span>En construcción...</span>
    }
    </>
  )
}

export { TabRequisitos }