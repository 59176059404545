import React from 'react'
import { Link } from 'react-router-dom'
import "../assets/styles/index.scss";
import LogoLUZ from "../assets/images/Logos/LogoLUZ.gif";
import LogoFMED from "../assets/images/Logos/logo-fmed-crop.png";
import LogoDEPG from "../assets/images/Logos/DEPG-icon.png";
// import LogoDEPG from "../assets/images/Logos/Nuevos/Logo-DEPG-Rounded.png";
// import LogoDEPG from "../assets/images/Logos/Nuevos/Logo-DEPG-Rounded-SM.png";

const info = {
    titulo: "División de Estudios para Graduados",
    subTitulo: "Universidad del Zulia | Facultad de Medicina",
    subTituloTop: "Universidad del Zulia",
    subTituloBottom: "Facultad de Medicina",
    link_luz: "http://www.luz.edu.ve/",
    link_fmed: "https://fmed-testing.netlify.app/"
}

function Header() {
  return (
    <>
        <header className='header-container container-fluid'>
            <div id='header-landscape' className='header header-landscape align-items-center'>
            <div className='logo-container d-flex'>
                <a href={info.link_luz} target='blank'>
                    <img 
                        src={LogoLUZ}
                        alt='Universidad del Zulia'
                        className='logo logo-luz'
                    />
                </a>
                <img 
                    src={LogoFMED}
                    alt='Facultad de Medicina LUZ'
                    className='logo logo-fmed'
                />
                <Link to='/'>
                    <img 
                        src={LogoDEPG}
                        alt='División de Estudios para Graduados de la Facultad de Medicina'
                        className='logo logo-depg'
                    />
                </Link>
            </div>
            <div className='group-text d-flex flex-column'>
                <p className='header-subtitle fw-normal'>{info.subTitulo}</p>
                <p className='header-title fw-semibold'>{info.titulo}</p>
            </div>
            </div>

            <div id='header-mobile' className='header header-mobile align-items-center'>
                <div className='left-section d-flex align-items-center'>
                    <div className='main-logo-container d-flex justify-content-center'>
                        <a href={info.link_luz}>
                            <img
                                src={LogoLUZ}
                                alt='División de Estudios para Graduados de la Facultad de Medicina'
                                className='main-logo logo-depg'
                            />
                        </a>
                    </div>

                    <div className='group-text group-text-md'>
                        <p className='header-subtitle fw-normal'>{info.subTitulo}</p>
                        <p className='header-title fw-semibold'>{info.titulo}</p>
                    </div>

                    <div className='group-text group-text-sm'>
                        <p className='header-subtitle fw-normal'>{info.subTituloTop}</p>
                        <p className='header-subtitle fw-normal'>{info.subTituloBottom}</p>
                        <p className='header-title fw-semibold'>{info.titulo}</p>
                    </div>
                </div>
            
                <div className='logo-container d-flex flex-column'>
                    <img 
                        src={LogoFMED}
                        alt='Facultad de Medicina - LUZ'
                        className='logo logo-top'
                    />
                    <Link to='/'>
                        <img 
                            src={LogoDEPG}
                            alt='División de Estudios para Graduados - Facultad de Medicina - LUZ'
                            className='logo logo-bottom'
                        />
                    </Link>
                </div>
            </div>
        </header>
    </>
  )
}

export { Header }