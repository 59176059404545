import React from 'react'
import { Accordion } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const concursos = [
    {
        nombre: "Concurso 2024",
        highlight: true,
        enlaces: [
            {
                tipo: "provisional",
                nombre: "Listado provisional",
                donwload_link: "",
                visible: false

            },
            {
                tipo: "definitivo",
                nombre: "Listado definitivo",
                donwload_link: "",
                visible: false
            },
            {
                tipo: "baremo",
                nombre: "Baremo 2024",
                donwload_link: "",
                visible: true
            }
        ]
    }
]

const ConcursosListados = () => {
    return (
      <div className="page-section listados">
        <h2 className="subpage-title">Listados</h2>

        <div className="dropdown-container">
          {concursos.map((concurso, index) => (
            <Accordion
              className={`accordion-concurso ${
                concurso.highlight ? "highlight" : "std"
              }`}
              defaultActiveKey={concurso.highlight ? "0" : false}
              key={index}
            >
              <Accordion.Item eventKey="0">
                <Accordion.Header className="accordion-title">
                  {concurso.nombre}
                </Accordion.Header>
                <Accordion.Body>
                  <ul className="listado-links">
                    {concurso.enlaces.map((enlace, index) => (
                      <li className="list-item" key={index}>
                        <Link
                          to="#"
                          aria-disabled="true"
                          className={`listado-link disabled d-flex align-items-center ${
                            enlace.visible ? "visible" : "d-none"
                          }`}
                        >
                          <i class="bi bi-file-earmark-pdf-fill item-icon"></i>
                          <span className="item-name">{enlace.nombre}</span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          ))}
        </div>
      </div>
    );
}

export { ConcursosListados }