import React from 'react'

const tramites = [
  "Atención al usuario para tramites en general y orientación de procesos. ",
  "Recepción de solicitudes por parte de Comités Académicos, profesores, empleados y estudiantes activos y egresados. ",
  "Recepción de documentos y programas para verificación y validación. "   
]

const TramitesDireccion = () => {
  return (
    <>
      <h2 className='subpage-title'>Trámites</h2>

      <ul className='content-list'>
      {
        tramites.map(item => 
          <li>{item}</li>  
        )
      }
      </ul>
    </>
  )
}

export { TramitesDireccion }
