import React from 'react'
import { Button, Modal } from 'react-bootstrap';

const ModalCde = ({ toggleFunction, modalState }) => {
  return (
    <Modal show={modalState} onHide={() => toggleFunction(false)} dialogClassName='custom-lg'>
      <Modal.Header closeButton>
        <Modal.Title>Recepción Acto de Grado</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <section className='info-container text-center'>
          {/* <img src={img_modal} alt='Recepción Acto de Grado' className='modal-banner sm card' /> */}

          <div className='info-group mb-3'>
            <h3 className='modal-subtitle continuous heading mb-3'>Recepción</h3>
            <p className='info-p'>Control de Estudios</p>
            <p className='info-p'>DEPGFMLUZ</p>
            <p className='info-p'>Hasta 31 de octubre de 2024</p>
          </div>

          <div className='info-group mb-2'>
            <h4 className='highlight-title fw-bold'>Fecha Acto de Grado Solemne</h4>
            <p className='p-info'>05 de diciembre de 2024</p>
          </div>

          <div className='info-group'>
            <h4 className='highlight-title fw-bold'>Fecha Taquilla</h4>
            <p className='info-p'>06 de diciembre de 2024</p>
            <p className='info-p'>Edificio Ciencia y Salud 2do piso</p>
          </div>
        </section>
      </Modal.Body>
      <Modal.Footer>
        <Button className='footer-button' variant="secondary" onClick={()=> toggleFunction(false)}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export { ModalCde }