import React from "react";
import { Header } from "../components/Header";
import { NavigationBar } from "../components/NavigationBar";
import { Footer } from "../components/Footer";
import { Outlet, Link, useLocation } from "react-router-dom";
import img_secretaria_docente from "../assets/images/Perfiles/Secretaria Docente - Dra. Jorymar Leal.jpg";

const info = {
  principal: "La Secretaría Docente de la División de Estudios para Graduados de la Facultad de Medicina, es el ente encargado de administrar los procesos académicos y actúa de acuerdo con los preceptos de planificación, organización, coordinación y eficiencia, en estricto cumplimiento de los reglamentos, normas y procedimientos dictados por las instancias superiores y por la propia universidad en el ejercicio de sus respectivas competencias. \n\nTiene como misión la coordinación y desarrollo de la función docencia de posgrado, en la ejecución de planes, programas y proyectos del cuarto y quinto nivel. ",
  funciones: [
      "Las funciones de la Secretaría Docente, son la definición de objetivos para el nivel de posgrado, asesorar a los Comités Académicos y Miembros en cuestiones que se le requieran en el ámbito de su competencia. ",
      "Dirigir y supervisar las actividades asignadas a la Secretaría Docente a su cargo y diagnosticar los aspectos sustantivos del área a su cargo. ", 
      "Diseña plan estratégico y operativo de su gestión, considerando la misión y funciones específicas de la Secretaría. ",
      "Articula las políticas de posgrado con las políticas académicas de formación y actualización de docentes, investigadores y graduados y desarrolla planes de cooperación con las demás unidades académicas de la Universidad y con instituciones de educación superior nacionales y extranjeras, para la administración de proyectos de posgrado. "       
  ],
  slug: "/secretaria-docente",
  correo: "secretariadocentedepg.fmedluz@gmail.com",
  submenu: [
    {
      opcion: "Trámites",
      link: ""
    }
  ]
}

const SecretariaDocente = () => {
    const location = useLocation();

  return (
    <>
      <Header />
      <NavigationBar activeLink={"Directorio"} />
      <main className="main main-consejo">
        {location.pathname === info.slug ? (
          <section className="main-container dept-main">
            <div className="side-container">
              <div className="header-box side">
                <h1 className="page-title text-center">Secretaría Docente</h1>
              </div>

              <div className="perfil-card side-card">
                <img
                  className="img-perfil"
                  alt="Secretaría Docente"
                  src={img_secretaria_docente}
                />
                <h4 className="perfil-nombre">Dra. Jorymar Leal</h4>
                <span className="perfil-cargo fw-semibold">Secretaria Docente</span>
              </div>

              <div className="contacto-card side-card">
                <h4 className="card-title">Contacto</h4>

                <span className="span-correo">{info.correo}</span>
              </div>

              {/* <div className="side-card submenu-card">
                <ul className="submenu-list std-list">
                  {info.submenu.map(
                    (item, index) =>
                      item.link !== location.pathname && (
                        <li className="list-item" key={index}>
                          <Link
                            to={location.pathname + item.link}
                            className="list-link"
                          >
                            {item.opcion}
                          </Link>
                        </li>
                      )
                  )}
                </ul>
              </div> */}
            </div>

            <div className="central-container">
              <div className="header-box"></div>
              <p
                dangerouslySetInnerHTML={{ __html: info.principal }}
                className="p-contenido ws-pw mb-5"
              />

              <h3 className="page-subtitle">Funciones</h3>

              <ul className="mt-3">
              {
                info.funciones.map((funcion, index) =>
                    <li className="list-item" key={index}>{funcion}</li>
                )
              }
              </ul>
            </div>
          </section>
        ) : (
          <section className="main-container dept-main">
            <div className="side-container">
              <div className="header-box side">
                <h1 className="page-title text-center">Consejo Técnico</h1>
              </div>

              <div className="side-card submenu-card">
                <ul className="submenu-list std-list">
                  {info.submenu.map((item, index) =>
                    item.link === info.slug ? (
                      <li className="list-item" key={index}>
                        <Link to={item.link} className="list-link org">
                          {item.opcion}
                        </Link>
                      </li>
                    ) : (
                      <li className="list-item" key={index}>
                        <Link to={info.slug + item.link} className="list-link">
                          {item.opcion}{" "}
                        </Link>
                      </li>
                    )
                  )}
                </ul>
              </div>

              <div className="contacto-card side-card">
                <h4 className="card-title">Contacto</h4>
                <span className="span-correo">{info.correo}</span>
              </div>
            </div>

            <div className="central-container">
              <div className="header-box"></div>
              <Outlet />
            </div>
          </section>
        )}
      </main>
      <Footer />
    </>
  );
};

export { SecretariaDocente };
