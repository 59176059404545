import React from 'react'
import { Button, Table } from 'react-bootstrap'
import { ScrollToTopByClick } from '../../functions/ScrollToTop'

const info = {
  tramites: [
    'Pagos trimestrales, en las distintas Especialidades, Doctorados o Maestrías',
    'Solvencia Administrativa',
    'Prueba de Inglés',
    'Metodología de la Investigación', 
    'Examen de Salud Mental',
    'Proyecto De Investigación', 
    'Constancia De Estudios',
    'Constancia Final de Notas',
    'Constancia de Culminación',
    'Inscripción al Concurso',
    'Introducción de Tesis',
    'Elaboración de Expediente',
    'Constancia de Curso',
    'Extravío de Recibo',
    'Inscripción al Posgrado',
    'Presupuesto',
    'Derecho a Convalidar',
    'Pensum de Estudios Certificado',
    'Derecho a Reco. de Crédito',
    'Unidades Crédito y Convalidación',
    'Plan de Estudios',
    'Carta de Aceptación',
    'Multa de Trimestre',
    'Constancias Depg C/U',
    'Acreditación',
    'Perfil Ingreso y Egreso',
    'Carga Horaria',
    'Modalidad de Estudios',
    'Certificado de Escenario Prácticos',
    'Cine-Uneso',
    'Certificado en Pergamino Pequeño',
    'Certificado en Pergamino Grande',
    'Certificado en Cartulina',
    'Tutor Académico',
    'Inscripción al Doctorado',
    'Inscripción al Con. Doctorado',
    'Acta de Grado',
    'Doctorado No Escolarizado',
    'Congreso Científico',
    'Inscripción Con. Maestría',
    'Inscripción a la Maestría'
  ]
}

const AdministracionTramites = () => {
  const tramites = info.tramites.sort((a, b) => a.localeCompare(b));

  return (
    <div className='page-section tramites'>
      <h3 className='subpage-title-heading'>Trámites y Solicitudes</h3>

      <Table striped bordered hover className='table-tramites'>
        <thead className='table-header'>
          <tr className='tab-row'>
            <th className='tab-heading'>Pago de Aranceles</th>
          </tr>
        </thead>
        <tbody className='table-body'>
        {
          tramites.map((tramite, index) =>
          <tr className='tab-row' key={index}>
            <td className='tab-cell'>{tramite}</td>
          </tr>
        )}
        </tbody>

      </Table>
      <Button className='btn btn-primary rounded d-none' onClick={ScrollToTopByClick}>Subir</Button>
    </div>
  )
}

export { AdministracionTramites }