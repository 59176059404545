import React from 'react'
import oferta_cupos_pdf from '../../assets/files/Concurso2024/OFERTA DE CUPOS CONCURSO 2024.pdf'

const ConcursosOfertaCupos = () => {
  return (
    <div className='page-section oferta-cupos'>
        <h2 className='subpage-title'>Oferta de Cupos</h2>

        <a href={oferta_cupos_pdf} className='btn btn-primary link-button' download={'Oferta de Cupos Concurso 2024'}>
            <i class="bi bi-file-earmark-pdf-fill item-icon"></i>
            <span className="item-name ms-2">Ver Oferta de Cupos</span>
        </a>
    </div>
  )
}

export { ConcursosOfertaCupos }