import React, { useEffect, useState } from 'react'
import { Header } from '../components/Header'
import { NavigationBar } from '../components/NavigationBar'
import { Footer } from '../components/Footer'
import { Accordion, Offcanvas } from 'react-bootstrap'
import { Link, Outlet, useLocation } from 'react-router-dom'
import routes from '../routes/routes'
import info_comites from '../assets/data/comites_academicos.json'

const info = {
  descripcion: "Los Comités Académicos están conformados por Profesores titulares o invitados de la Facultad de Medicina de LUZ, encargados de coordinar actividades académicas dentro de los programas académicos de Posgrado. \n\nEl comité Académico es el Encargado de supervisar y regular el Cumplimiento de las horas académicas en la unidad docente. \n\nSus funciones pueden incluir la planificación y supervisión de programas académicos, la revisión de planes de estudio, la evaluación del desempeño académico y la promoción de iniciativas para mejorar la calidad educativa. \n\nNo obstante suelen ser responsables de proponer y desarrollar políticas relacionadas con la enseñanza y el aprendizaje. "
}

const Comites = () => {  
  const location = useLocation();
  const parentRoute = routes.find(route => route.path === 'comites-academicos');
  const mainPath = '/' + parentRoute.path;
  const isMainPage = location.pathname === mainPath;

  const [showSidebar, setShow] = useState(false);
  const handleShowSidebar = () => setShow(true)
  const handleCloseSidebar = () => setShow(false)

  return (
    <>
    <Header />
    <NavigationBar activeLink={'Directorio'} toggleButton={'Comités'} toggleFunction={handleShowSidebar} />
    <main className={`main main-comites ${ isMainPage ? 'index' : 'child' }`}>
      {/* <h1 className='page-title text-center w-100'>Comités Académicos</h1> */}
      <SidebarComites layout={ isMainPage ? 'index' : 'child'} />
      <Offcanvas show={showSidebar} onHide={handleCloseSidebar} >
        <SidebarComites toggleOffcanvas={handleCloseSidebar} />
      </Offcanvas>
      <div className={`central-container `}>
        <div className='header-box'>
          <Link className='invisible-link' to={ "/" + parentRoute.path}>
            <h3 className='section-title'>Comités Académicos</h3>
          </Link>
        </div>
      {
        isMainPage ? 
        <>
          <div className='section-container'>
            <p className='info-p ws-pw justify-text' dangerouslySetInnerHTML={{__html: info.descripcion}}/>
          </div>
        </>
        :
        <>
        <Outlet />
        </>
      }
      </div>
    </main>
    <Footer />
    </>
  )
}

const SidebarComites = ({ layout, toggleOffcanvas }) => {
  const parentRoute = routes.find(route => route.path === 'comites-academicos');
  const mainPath = '/' + parentRoute.path;

  const unidadesDocentes = info_comites.filter(item => item.tipo === "sede");
  const doctorados = info_comites.filter(item => item.tipo === "doctorado");

  return(
    <>
      <aside className={`sidebar-container sidebar-comites ${layout} ${ toggleOffcanvas ? 'off-canvas' : 'std'}`}>
        <div className='sidebar-header'>
          <Link className='invisible-link' to={mainPath}>
            <span className='header-title'>Comités Académicos</span>
          </Link>
          {
            toggleOffcanvas &&
            <i className='close-button bi bi-x-lg' onClick={() => toggleOffcanvas(false)}></i>
          }
        </div>
        <div className='sidebar-body'>
          <Accordion className='sidebar-accordion'>
            <Accordion.Item className='accordion-item' eventKey='-1'>
              <Accordion.Header>
                <span className='accordion-title link-button'>Unidades Docentes</span>
              </Accordion.Header>
              <Accordion.Body>
                <ul className='sidebar-list arrow-list'>
                {
                  unidadesDocentes.map((item, index) =>
                    <Link 
                      className='accordion-item-link' 
                      to={ item.path }
                      key={index}
                      onClick={() => { toggleOffcanvas && toggleOffcanvas(false)}}
                    >
                      <li className='accordion-list-item'>{item.nombre}</li>
                    </Link>
                  )
                }
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <Accordion className='sidebar-accordion'>
            <Accordion.Item className='accordion-item' eventKey='-1'>
              <Accordion.Header>
                <span className='accordion-title link-button'>Doctorados</span>
              </Accordion.Header>
              <Accordion.Body>
                <ul className='sidebar-list arrow-list'>
                {
                  doctorados.map((item, index) =>
                    <Link 
                      className='accordion-item-link' 
                      to={item.path} 
                      key={index}
                      onClick={() => { toggleOffcanvas && toggleOffcanvas(false)}}
                    >
                      <li className='accordion-list-item'>
                      { item.nombre }
                      </li>
                    </Link>
                  )
                }
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </aside>
    </>
  )
}

const ItemComite = () => {
  const location = useLocation();

  const [accordion, setAccordion] = useState([]);
  const handleAccordion = (id) => {
    const isOpen = accordion.includes(id);

    if(isOpen){
      const newAccordion = accordion.filter(item => item !== id);
      setAccordion(newAccordion);
    } else {
      setAccordion([...accordion, id])
    }
  }

  const splitLocation = location.pathname.split('/');
  const currentPath = splitLocation.at(-1);

  const comiteParent = info_comites.find(item => item.path === currentPath ) ?? null
  const currentIndex = info_comites.findIndex((item) => item.path === currentPath).toString();
  // const indexArray = comiteParent.tipo !== "doctorado" && comiteParent.comites.map((item, index) => (
  //   currentIndex + index.toString()
  // ));
  // console.log(indexArray);

  useEffect(() =>{
    // console.log("Location EFFECT");
    setAccordion([])
  },[location])

  return (
    comiteParent &&
    <div className='section-container'>
      <div className='section-header'>
        <h3 className='section-title'>{comiteParent.nombre}</h3>
      </div>
      <div className='section-body'>
        <div className='accordion-container'>
        {
          comiteParent.tipo === "sede" ?
            comiteParent.comites.map((comite, index) => 
              <Accordion 
                className={`accordion-comite ${comiteParent.tipo}`} 
                key={index} 
                activeKey={accordion}
                onSelect={()=> {
                  const newIndex = currentIndex + index.toString();
                  handleAccordion(newIndex)
                }}
                alwaysOpen
              >
                <Accordion.Item className='accordion-item' eventKey={currentIndex + index.toString()} key={index}>
                  <Accordion.Header>
                    <span className='accordion-title link-button'>{comite.programa}</span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul className='accordion-list unstyled-list'>
                      {
                        comite.miembros.map((item, index) =>
                          <li className='accordion-list-item' key={index}>
                            {item.nombre + " - " + item.rol}
                          </li>
                        )
                      }
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            ) 
          :
          <Accordion className='accordion-comite doctorado' defaultActiveKey={[1,2,3]} alwaysOpen>
            <Accordion.Item className='accordion-item' eventKey={comiteParent.index}>
              <Accordion.Header>
                <span className='accordion-title link-button'>Miembros del Comité</span>
              </Accordion.Header>
              <Accordion.Body>
                <ul className='accordion-list unstyled-list'>
                  {
                    comiteParent.miembros.map((item, index) =>
                      <li className='accordion-list-item' key={index}>
                        {item.nombre + " - " + item.rol}
                      </li>
                    )
                  }
                </ul>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        }
        </div>
      </div>
    </div>
  )
}

export { Comites, ItemComite }