import React from 'react'
import LogoDEPG from "../assets/images/Logos/DEPG-icon.png";

const info = {
    titulo: "División de Estudios para Graduados",
    subTituloBottom: "Universidad del Zulia",
    subTituloTop: "Facultad de Medicina"
}

// const footerLinks = [
//     {
//         page: "Directorio",
//         link: "/"
//     },
//     {
//         page: "Academia",
//         link: "/"
//     },
//     {
//         page: "Administración",
//         link: "/"
//     },
//     {
//         page: "Noticias",
//         link: "/"
//     },
//     {
//         page: "Contacto",
//         link: "/"
//     },
// ]

function Footer () {
  return (
    <>
        <footer className='footer'>
            <div className='top-section d-flex justify-content-between'>
                <div className='left-container d-flex align-items-center'>
                    <a href='/' target='blank'>
                        <img 
                            src={LogoDEPG} 
                            alt='Logotipo División de Estudios para Graduados de la Facultad de Medicina - LUZ'
                            className='footer-logo' 
                        />
                    </a>
                    <div className='footer-text-group d-flex flex-column'>
                        <span className='footer-title span fw-semibold'>{info.titulo}</span>
                        <span className='footer-subtitle span fw-normal'>{info.subTituloTop}</span>
                        <span className='footer-subtitle span fw-normal'>{info.subTituloBottom}</span>
                    </div>
                </div>

                <div className='right-container links-group d-flex'>
                    {/* <div className='links-group d-flex flex-column'>
                    {
                        footerLinks.map(element => (
                            <a key={element.page} href={element.link} className='footer-page-link'>
                                <span className='footer-link'>{element.page}</span>
                            </a>
                        ))
                    }
                    </div> */}
                    <div className='footer-icon-group vertical-icon-group'>
                        <a href='https://www.instagram.com/depgfmluz/' target='blank' className='contact-link'>
                            <i className='bi bi-instagram contact-icon'></i>
                        </a>
                        <a href='https://t.me/depgfmluz' target='blank' className='contact-link'>
                            <i className='bi bi-telegram contact-icon'></i>
                        </a>
                    </div>
                </div>
            </div>
            <div className='bottom-section d-flex justify-content-between'>
                <span className='copyright-text'>© 2024. Todos los derechos reservados.</span>
                <div className='footer-icon-group'>
                    <a href='https://www.instagram.com/depgfmluz/' target='blank' className='contact-link'>
                        <i className='bi bi-instagram contact-icon'></i>
                    </a> 
                    <a href='https://t.me/depgfmluz' target='blank' className='contact-link'>
                        <i className='bi bi-telegram contact-icon'></i>
                    </a>
                </div>
            </div>
        </footer>
    </>
  )
}

export { Footer }