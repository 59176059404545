import React from 'react'
import { Header } from '../components/Header'
import { NavigationBar } from '../components/NavigationBar'
import { Footer } from '../components/Footer'
import img_perfil from '../assets/images/Perfiles/Ingrid Torres - Control de Estudios.jpg'

const info = {
    descripcion: 'El Departamento de Control de Estudios de la División de Estudios para Graduados, de la Facultad de Medicina, tiene como misión, velar ante los organismos competentes nacionales e internacionales, por la legalidad, validez y exactitud de los documentos probatorios de los estudios cursados, por los estudiantes activos y egresados de nuestra institución. ',
    funciones: [
        "Velar y dar cumplimiento de las normas y procedimientos vigentes en materia de posgrado. ",
        "Evaluar, revisar y elaboración de expedientes académicos o procedimientos. ",
        "Dar celeridad a los procesos administrativos e informar a las coordinaciones de los programas de posgrado. ",
        "Revisar, transcribir, organizar, controlar y emitir los documentos probatorios de los programas de posgrado de estudiantes activos y egresados, asegurando su legalidad y validez ante los organismos competentes. ",
        "Orientar a los estudiantes activos y egresados, en relación al registro y control de estudio, a los fines de validar y otorgar a los interesados: Constancia de Notas, Constancia de Estudios, Constancias de Culminación, Constancias de Egresos, Constancias DEPG, Planes de Estudio, Pensum de Estudios. ",
        "Elaborar y consignar ante la Secretaria de la Universidad del Zulia, los expedientes de estudiantes con opción a grado académico que han cumplido con los requisitos de egreso exigidos en el programa de posgrado, así como velar por el cumplimiento de procesos y lineamientos académicos. ", 
        "Mantenerse informados sobre los cambios realizados a nivel de normas, reglamentos, procedimientos y requisitos exigidos en materia de posgrado. "        
    ],
    procesos_academicos: [
        "Entrega y recepción de Planillas de notas a los Coordinadores o Miembros del Comité Académico. ",
        "Status académico de estudiantes activos y egresados de posgrado. ",
        {
            descripcion: "Entrega de documentos: ",
            sublista: true,
            elementos: [
                "Documentos para egresados: constancia de notas, plan de estudio, pensum de estudio, constancias DEPG."
            ] 
        },
        "Notas finales. ",
        "Constancias de cursante sin nota. ",
        "Constancias de culminación",
        "Revisión y elaboración de expedientes de grado académico. "         
    ],
    correo: 'controldeestudiosdepgfmluz@gmail.com'
}

const ControlEstudios = () => {
  return (
    <>
        <Header/>
        <NavigationBar/>
        <main className='main main-control-estudios'>
            <section className='main-container dept-main'>
                <div className='side-container'>
                    <div className='header-box side'>
                        <h1 className='page-title text-center'>Control de Estudios</h1>
                    </div>

                    <div className='perfil-card side-card'>
                        <img className='img-perfil' src={img_perfil} alt='Ingrid Torres - Jefa de Control de Estudios' />
                        <h4 className='perfil-nombre'>Ingrid Torres</h4>
                        <span className='perfil-cargo fw-semibold'>Jefa de Control de Estudios</span>
                    </div>

                    <div className='contacto-card side-card'>
                        <h3 className='card-title'>Contacto</h3>
                        <span className='span-correo'>{info.correo}</span>
                    </div>
                </div>

                <div className='central-container'>
                    <div className='header-box'></div>
                    <p className='p-contenido descripcion ws-pw'>{info.descripcion}</p>

                    <h3 className='subtitle' id='funciones-departamento'>Funciones del Departamento</h3>
                    <ul className='lista-funciones justify-text'>
                    {
                        info.funciones.map((elemento, index) => 
                        <li className='list-item' key={index}>{elemento}</li>
                    )}    
                    </ul>

                    <h3 className='subtitle' id='procesos-academicos'>Procesos Académicos</h3>
                    <ul className='lista-procesos justify-text'>
                    {
                        info.procesos_academicos.map((item, index) =>
                        !(item.sublista) 
                        ? <li className='list-item' key={index}>{item}</li>
                        : <div key={index}>
                            <li className='list-item' >{item.descripcion}</li>
                            <ul className='sublista' >
                                {
                                    item.elementos.map((elemento, index) => 
                                    <li className='list-item' key={index}>{elemento}</li>
                                )}
                            </ul>
                        </div>
                    )}
                    </ul>
                </div>
            </section>
        </main>
        <Footer/>
    </>
  )
}

export { ControlEstudios }