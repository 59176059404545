import React, { useState } from 'react'
import video from '../../assets/videos/Prueba de Conocimientos Concurso 2024.mp4'
import ReactPlayer from 'react-player'
import ubicacionesPdf from '../../assets/files/Concurso2024/Concurso 2024 Distribucion.pdf'

const ConcursoPrueba = () => {

  const [videoReadyStatus, setVideoReadyStatus] = useState(false);
  
  const toggleVideoStatus = () => {
    setVideoReadyStatus(true);
    // console.log('Video status: ' + videoReadyStatus)
  }

  return (
    <div className='page-section instructivos prueba-con'>
        <h2 className='subpage-title'>Prueba de Conocimientos</h2>

        <section className='video-container'>
          <div className={`video-canvas ${ videoReadyStatus ? 'ready' : 'loading' }`}>
            <button 
              className={`video-placeholder btn btn-secondary disabled placeholder-wave d-${videoReadyStatus ? 'none' : 'block'}`} 
            ></button>
            <div className={`d-${ videoReadyStatus ? 'block' : 'none' }`}>
              <ReactPlayer
                className='video-player'
                url={video}
                playing={true}
                volume={0.6}
                controls={true}
                width={'100%'}
                height={'auto'}
                onReady={toggleVideoStatus}
              />
            </div>
          </div>
          <div className='button-group d-flex flex-column'>
            <a href={ubicacionesPdf} download={'Concurso 2024 Distribucion'} className='btn-baremo btn btn-primary link-button'>
              Ubicación de Aulas
            </a>
          </div>
        </section>

        <section className='info-container' id='index'>
          {/* <p className='heading-p'></p> */}
          
          <h3 className='section-title'>Examen de Conocimientos 2024</h3>
          
          <div className='info-group'>
            {/* <h3 className='modal-subtitle spaced'>Cronograma</h3> */}
            <p dangerouslySetInnerHTML={{__html: '<b>Fecha: </b> Sábado 12 de octubre de 2024'}} />
            <p dangerouslySetInnerHTML={{__html: '<b>Hora: </b> 7:00 AM'}}/>
            <p dangerouslySetInnerHTML={{__html: '<b>Lugar:</b> Edificio Ciencia y Salud. Facultad de Medicina. '}}></p>
            <div className='span-group mb-0'>
              <span className='span-lead'>Hora recomendada de llegada: </span>
              <span>6:00 AM</span>
            </div>
          </div>
          <div className='info-group list-column'>
            <h4 className='group-title'>Asistir con: </h4>
            <p>✔  Bata médica blanca</p>
            <p>✔  Lápiz grafito, sacapuntas y borrador</p>
          </div>
          <div className='info-group list-column'>
            <h4 className='group-title'>Importante: </h4>

            <p>- No se permitirá la entrada con carteras, bolsos o celulares. </p>
            <p>- No se permitirán acompañantes. </p>
          </div>
        </section>
    </div>
  )
}

export { ConcursoPrueba }