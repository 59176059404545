import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavbarToggle from 'react-bootstrap/NavbarToggle'

const navDropdownContents = [
    {
        element: "directorio",
        displayName: "Directorio",
        dropdown: true,
        options: [
            {
                name: "Facultad de Medicina",
                link: "/facultad-de-medicina"
            },
            {
                name: "Consejo Técnico",
                link: "/consejo-tecnico"
            },
            {
                name: "Dirección",
                link: "/direccion"
            },
            {
                name: "Comités Académicos",
                link: "/comites-academicos"
            },
            {
                name: "Secretaría Docente",
                link: "/secretaria-docente"
            },
            {
                name: "Acreditación y Currículo",
                link: "/acreditacion-curriculo"
            },
            {
                name: 'Departamentos',
                link: '#',
                subMenuActive: true,
                reverse: true,
                subOptions: [
                    {
                        name: "Administración",
                        link: "/administracion"
                    },
                    {
                        name: "Informática",
                        link: "/informatica"
                    },
                    {
                        name: "Relaciones Públicas",
                        link: "/relaciones-publicas"
                    },
                    {
                        name: "Control de Estudios",
                        link: "/control-estudios"
                    },
                    {
                        name: "Archivo",
                        link: "/archivo"
                    },
                ]
            }
        ] 
    },
    {
        element: "programas-estudio",
        displayName: "Programas de Estudio",
        dropdown: true,
        options: [
            {
                name: "Posgrado",
                link: "/posgrado",
                subMenuActive: true,
                subOptions: [
                    { 
                        name: "Especialidades",
                        link: "/posgrado/especialidades"
                    },
                    {
                        name: "Maestrías",
                        link: "/posgrado/maestrias"
                    },
                    {
                        name: "Doctorados",
                        link: "/posgrado/doctorados"
                    },
                    {
                        name: "Unidades Docentes",
                        link: "/posgrado/unidades-docentes"
                    }
                ]
            },
            {
                name: "Programa de Educación Continua (PEC)",
                link: "/cursos-pec"
            }
        ]
    },
    {
        element: "tramites-solicitudes",
        displayName: "Trámites y Solicitudes",
        dropdown: true,
        options: [
            {
                name: "Consejo Técnico",
                link: "/consejo-tecnico/tramites"
            },
            {
                name: "Dirección",
                link: "/direccion/tramites"
            },
            {
                name: "Administración",
                link: "/administracion/tramites"
            },
            {
                name: "Archivo",
                link: "/archivo/tramites"
            }
        ]
    },
    {
        element: "concurso/instructivo",
        displayName: "Concurso 2024",
        dropdown: false
    },
    {
        element: "contacto",
        displayName: "Contacto",
        dropdown: false
    }
]

function NavigationBar ({ activeLink, toggleButton, toggleFunction }) {
    const [showDrop, setShowDrop] = useState({
        element: 'none',
        active: false
    });

    const [showSubmenu, setShowSubmenu] = useState({
        element: 'none',
        active: false
    });
    
    const setActiveDropdown = (component) => setShowDrop({
        element: component,
        active: true
    });

    const setInactiveDropdown = (component) => setShowDrop({
        element: component,
        active: false
    });

    const switchDropdownStatus = (component) => {
        const newStatus = !showDrop.active;

        if(showDrop.element === component){
            setShowDrop({
                element: component,
                active: newStatus
            })
        }
    } 

    const setActiveSubmenu = (component) => setShowSubmenu({
        element: component,
        active: true
    });
    
    const setInactiveSubmenu = () => setShowSubmenu({
        element: "none",
        active: false
    });

    const switchSubmenuStatus = (component) => {
        const newStatus = !showSubmenu.active;

        if(showSubmenu.element === component){
            setShowSubmenu({
                element: component,
                active: newStatus
            })
        }
    }
    
    return (
        <>
            <Navbar expand="lg" className="navbar" >
                <Container className='nav-container'>
                    <div className='nav-toggler-container'>
                        <NavbarToggle aria-controls="basic-navbar-nav"></NavbarToggle>
                        <span className='nav-toggler-span'>Menú</span>
                        {
                            toggleButton && (
                                <button type='button' className='toggleButton btn btn-light' onClick={()=> toggleFunction(true)}>{toggleButton}</button>
                            )
                        }
                    </div>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="nav-items me-auto">
                            <Link to='/' className={`nav-link ${ (activeLink === 'Inicio') && `navlink-active` }`}>
                                Inicio
                            </Link>
                            {
                                navDropdownContents.map((element, index) => (
                                    element.dropdown ? 
                                        <div key={index} className='nav-dropdown' 
                                            onMouseLeave={() => { setInactiveDropdown(element.element); setInactiveSubmenu() }}>
                                            <Nav.Link href="#"
                                                className={`nav-link ${(activeLink === element.displayName) && `navlink-active`}`}
                                                onMouseEnter={() => setActiveDropdown(element.element)}
                                                onClick={() => switchDropdownStatus(element.element)}
                                            >
                                                <span className='nav-link-text'>{element.displayName}</span>
                                                <i class="bi bi-caret-down-fill navlink-dropdown-icon"></i>
                                            </Nav.Link>
                                            <div className={`dropdown-sub-menu flotante d-flex flex-column
                                                ${(showDrop.element === element.element && showDrop.active)
                                                    ? 'dropdown-active'
                                                    : 'dropdown-inactive'}`
                                                }
                                            >
                                                {
                                                    element.options.map((option) => ( 
                                                        option.subMenuActive ?
                                                            <div key={option.name} className={`submenu-container d-flex flex-column`} 
                                                                onMouseLeave={setInactiveSubmenu}>

                                                                <Link to='#' className='dropdown-link d-flex align-items-center justify-content-between'
                                                                    onMouseEnter={() => setActiveSubmenu(option.name)}
                                                                    onClick={() => switchSubmenuStatus(option.name)}>
                                                                    <span>{option.name}</span>
                                                                    <i className='bi bi-caret-down-fill navlink-dropdown-icon'></i>
                                                                </Link>

                                                                <div className={`submenu-options ${option.reverse && 'reverse'} d-flex flex-column ${(showSubmenu.element===option.name && showSubmenu.active === true) ? 'submenu-active' : 'd-none'}`}>
                                                                {
                                                                    option.subMenuActive && option.subOptions.map((item) => (
                                                                        <Link key={item.name} className='dropdown-link' to={item.link}>
                                                                            {item.name}
                                                                        </Link>
                                                                    ))
                                                                }
                                                                </div>
                                                            </div>
                                                        :
                                                            <Link key={option.name} to={option.link} className='dropdown-link'>
                                                                {option.name}
                                                            </Link>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                        :
                                        <Link to={'/' + element.element} className={`nav-link ${(activeLink === element.displayName) && `navlink-active`}`} key={index}>
                                            {element.displayName}
                                        </Link>
                                ))
                            }

                            

                            <div className='nav-contact-group d-flex justify-content-between'>
                                <div className='nav-icons-group'>
                                    <a href='https://www.instagram.com/depgfmluz/' target='blank' className='nav_contact-link'>
                                        <i className='bi bi-instagram nav_contact-icon'></i>
                                    </a>

                                    <a href='https://t.me/depgfmluz' target='blank' className='nav_contact-link'>
                                        <i className='bi bi-telegram nav_contact-icon'></i>
                                    </a>
                                </div>
                                <a href='mailto:depgfmluz@gmail.com' target='blank' className='nav-email-link d-flex align-items-center'>
                                    <span className='nav-email'>depgfmluz@gmail.com</span>
                                </a>
                            </div>
                        </Nav>
                        
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    )
}

export { NavigationBar }