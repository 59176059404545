import React, { useState } from "react";
import { Header } from "../../components/Header";
import { Alert, Button, Form, Spinner } from "react-bootstrap";

const CheckUbicacion = () => {
  const [cedula, setCedula] = useState("");
  const [loading, setLoading] = useState(false);
  const [participante, setParticipante] = useState({
    cedula: undefined,
    nombre: undefined,
    especialidad: undefined,
    hospital: undefined,
    ruta: undefined,
    aula: undefined,
    piso: undefined,
    consultas: undefined,
    found: false
  });

  const apiUrl = 'https://api.sheetbest.com/sheets/f27413cf-b4bd-4e31-bdd5-d1d30d68e4a1'

  const [alert, setAlert] = useState(
    {
      style: "warning",
      message: "Mensaje estándar de alerta",
      show: false,
      blurClose: false,
    }
  )

  const resetAlert = () => {
    setAlert({
      show: false, 
      style: "primary",
      message: "",
      blurClose: false
    });
  }

  const buscarParticipante = async () => {
    const intCedula = parseInt(cedula);
    // console.log(intCedula)
    
    if(cedula === "" || cedula === undefined){
      setAlert({ 
        style: "warning",
        message: "Ingrese una cédula con formato válido. Sólo números, sin puntos. ",
        blurClose: true,
        show: true 
      });
      setTimeout(() => {
        resetAlert();
      }, 10000)
    } else {
      setLoading(true);
      try {
        const resp = await fetch(apiUrl + `/query?Cedula=__eq(${intCedula})`);

        const contenido = await resp.json();

        if(resp.status === 200){
          // console.log(contenido);
          setLoading(false);

          if(contenido.length > 0){
            // setAlert({
            //   style: 'success',
            //   message: 'Participante encontrado',
            //   show: true,
            //   blurClose: true
            // });
            // setTimeout(() => {
            //   resetAlert();
            // }, 7000);
            resetAlert();

            // Actualizar la información del participante para mostrarla en cuadro informativo
            setParticipante({
              nombre: contenido[0].Nombres,
              cedula: cedula,
              especialidad: contenido[0].Especialidad,
              hospital: contenido[0].Hospital,
              piso: contenido[0].Piso,
              ruta: contenido[0].Ruta,
              aula: contenido[0].Aula,
              consultas: contenido[0].ConsultasUb,
              found: true
            });

            // Actualizar la cantidad de consultas realizadas mediante método PATCH, incrementando en 1
            try {
              await fetch(apiUrl + `/Cedula/${intCedula}`, {
                method: "PATCH",
                mode: "cors",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  ConsultasUb: parseInt(contenido[0].ConsultasUb) + 1,
                })
              });
              // console.log(await update.json())
            } catch (error) {
              console.log("Error al actualizar registro de consulta exitosa: " + error);
            }
          } else {
            setAlert({
              style: 'danger',
              message: 'Participante no encontrado. Verifique sus credenciales. ',
              show: true,
              blurClose: true
            });
            setTimeout(() => {
              resetAlert();
            }, 7000);
          }
        } else {
          setLoading(false);

          setAlert({
            style: 'danger',
            message: 'Error de consulta',
            show: true,
            blurClose: true
          });
          setTimeout(() => {
            resetAlert();
          }, 7000)
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
    // setTimeout(() => {
    //   resetAlert();
    // }, 7000)
  }

  const validarCedula = (event) => {
    const { value } = event.target;
    const regex =  /^[0-9]*$/;
    if(regex.test(value) && value !== ""){
      setCedula(value);
    } else {
      setCedula("");
    }
  }

  const handleKeyCedula = (event) => {
    if(event.key === 'Enter'){
      buscarParticipante();
    }
  }

  return (
    <>
      <Header />
      <div className="header-box">
        <h1 className="box-title">Concurso 2024</h1>
      </div>
      <main className="main main-ubicaciones">
        <h2 className="page-title text-center mb-4">Consulta de Ubicaciones de Aulas</h2>

        <Alert
          variant={alert.style}
          onClose={() => resetAlert()}
          show={alert.show}
          dismissible
          className='alert-asistencia'>
          {alert.message}
        </Alert>

        <section className="query-section card">
          <Form.Group className="form-container" controlId="form-cedula">
            <Form.Label className="form-label">Cédula del aspirante:</Form.Label>
            <Form.Control
              className='input-cedula mb-4'
              type="number"
              placeholder="Ingrese la cédula sin los puntos"
              value={cedula}
              onKeyDown={handleKeyCedula}
              min={0}
              max={999999999}
              onChange={validarCedula}
              disabled={participante.found}
            />
            <div className='d-flex justify-content-center align-items-center'>
              <Button 
                className={`boton-buscar btn btn-primary rounded-pill ${ participante.found ? 'd-none' : 'std' } ${loading ? 'd-none' : 'active'}`} 
                disabled={(cedula === '' || cedula === undefined) && true} 
                onClick={() => buscarParticipante()}
              >
                Buscar
              </Button>
              <Spinner 
                variant="primary" 
                className={`spinner ${ loading ? 'active' : 'd-none' }`} 
                role="status" 
                animation="border"
              />
            </div>

          </Form.Group>

        {
          participante.found &&
          <section className="info-container">
            <div className='info-card card d-flex flex-column'>
              <div className='header-row d-flex justify-content-between'>
                <div className='left-col label-group'>
                  <span className='data-title'>Aspirante:</span>
                  <span className='user-data'>{participante.nombre}</span>
                </div>
                {/* <Badge className='badge-rol' bg='primary'>Universitario</Badge> */}
              </div>

              <div className='label-group'>
                <span className='data-title'>Especialidad:</span>
                <span className='user-data'>{participante.especialidad} </span>
              </div>

              <div className='label-group'>
                <span className='data-title'>Unidad Docente:</span>
                <span className='user-data'>{participante.hospital} </span>
              </div>
            </div>

            <div className="ubicacion-card card">
              <h3 className="card-title text-center">Ubicación</h3>

              <div className="span-group">
                <span className="span-lead">Ruta</span>
                <div className={`color-box ${ participante.ruta.toLowerCase()}`}>{participante.ruta}</div>
              </div>
              <div className="span-group">
                <span className="span-lead">Salón</span>
                <div className="color-box card">{participante.aula}</div>
              </div>
            </div>
          </section>
        }
        </section>
      </main>
    </>
  );
};

export { CheckUbicacion };
