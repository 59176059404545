import React from 'react'
import { Header } from '../components/Header'
import { NavigationBar } from '../components/NavigationBar'
import { Footer } from '../components/Footer'
import { Link, useLocation, Outlet } from 'react-router-dom'
// import img_director_perfil from '../assets/images/Perfiles/Dr. Freddy Pachano - Director.jpg'
import img_director_perfil from '../assets/images/Perfiles/Director - Dr. Freddy Pachano.jpg'
import img_secretaria_academica from '../assets/images/Perfiles/Secretaria Académica - Dra. Maribel Castellano.jpg'

const info = {
    principal: "El Consejo Técnico de la División de Estudios para Graduados de la Facultad de Medicina de LUZ, tiene como misión asegurar la eficacia y eficiencia de las actividades académicas desempeñadas por la institución, para garantizar la transparencia de los trámites realizados por los cursantes y la excelencia académica de sus egresados. \n\nEl Consejo Técnico es presidido por el Director de la División, el <b>Dr. Freddy Pachano Arenas</b> y miembros de los Comités Académicos de los diferentes programa de Posgrado, es un ente comprometido con la planificación, decisión, consulta, desarrollo e innovación de los servicios que ofrece la División, actuando con liderazgo y eficiencia para promover el perfeccionamiento continuo y la probidad de las actividades a fin de responder cabalmente a los requerimientos y exigencias de cada uno de los programas académicos impartidos.\n\nLa secretaria académica es la encargada de evaluar la agenda y velar por el cumplimiento de los documentos académicos que se expondrán en la sesión, la misma esta cargo de la <b>Dra. Maribel Castellano</b>, profesora ordinaria de la Facultad de Medicina de LUZ y miembro del Comité Académico de la Maestría Diagnostico Bacteriológico de la División de Estudios para Graduados de la Facultad de Medicina de LUZ.",
    secretarias: [
        "Lcda. Yumagri Sulbarán",
        "Daniela Ávila"
    ],
    correo: "consejotecnicodepgfmluz@gmail.com",
    slug: "/consejo-tecnico",
    submenu: [
      {
        opcion: "Contenido principal",
        link: "/consejo-tecnico"
      },
      {
        opcion: "Trámites",
        link: "/tramites"
      },
      {
        opcion: "Instructivos presenciales",
        link: "/instructivos"
      }
    ]
}

function ConsejoTecnico() {
  const location = useLocation();

  return (
    <>
      <Header />
      <NavigationBar activeLink={"Directorio"} />
      <main className="main main-consejo">
        {location.pathname === "/consejo-tecnico" ? (
          <section className="main-container dept-main">
            <div className="side-container">
              <div className="header-box side">
                <h1 className="page-title text-center">Consejo Técnico</h1>
              </div>

              <div className="perfil-card side-card">
                <img
                  className="img-director"
                  alt="Director Dr. Freddy Pachano Arenas"
                  src={img_director_perfil}
                />
                <h4 className="perfil-nombre">
                  Dr. Freddy Enrique Pachano Arenas
                </h4>
                <span className="perfil-cargo fw-semibold">Director</span>
              </div>
              <div className="perfil-card side-card">
                <img
                  className="img-perfil"
                  src={img_secretaria_academica}
                  alt="Secretaria Académica - Dra. Maribel Castellano"
                />
                <h4 className="perfil-nombre">Dra. Maribel Castellano</h4>
                <span className="perfil-cargo fw-semibold">
                  Secretaria Académica
                </span>
              </div>

              <div className="contacto-card side-card">
                <h4 className="card-title">Contacto</h4>

                <ul className="contacto-lista unstyled-list">
                  <li className="list-item word-break">{info.correo}</li>
                </ul>
              </div>

              <div className="secretarias-card side-card">
                <h4 className="card-title">Secretarias</h4>
                <ul className="std-list">
                  {info.secretarias.map((item, index) => (
                    <li className="list-item" key={index}>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>

              <div className="side-card submenu-card">
                <ul className="submenu-list std-list">
                  {info.submenu.map(
                    (item, index) =>
                      item.link !== location.pathname && (
                        <li className="list-item" key={index}>
                          <Link
                            to={location.pathname + item.link}
                            className="list-link"
                          >
                            {item.opcion}{" "}
                          </Link>
                        </li>
                      )
                  )}
                </ul>
              </div>
            </div>

            <div className="central-container">
              <div className="header-box"></div>
              <p
                dangerouslySetInnerHTML={{ __html: info.principal }}
                className="p-contenido ws-pw"
              />
            </div>
          </section>
        ) : (
          <section className="main-container dept-main">
            <div className="side-container">
              <div className="header-box side">
                <h1 className="page-title text-center">Consejo Técnico</h1>
              </div>

              <div className="side-card submenu-card">
                <ul className="submenu-list std-list">
                  {info.submenu.map((item, index) =>
                    item.link === info.slug ? (
                      <li className="list-item" key={index}>
                        <Link to={item.link} className="list-link org">
                          {item.opcion}
                        </Link>
                      </li>
                    ) : (
                      <li className="list-item" key={index}>
                        <Link to={info.slug + item.link} className="list-link">
                          {item.opcion}{" "}
                        </Link>
                      </li>
                    )
                  )}
                </ul>
              </div>

              <div className="contacto-card side-card">
                <h4 className="card-title">Contacto</h4>

                <ul className="contacto-lista unstyled-list">
                  <li className="list-item">{info.correo}</li>
                </ul>
              </div>
            </div>

            <div className="central-container">
              <div className="header-box"></div>
              <Outlet />
            </div>
          </section>
        )}
      </main>
      <Footer />
    </>
  );
}

export { ConsejoTecnico }