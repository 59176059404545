import React from 'react'
import { Header } from '../components/Header'
import { NavigationBar } from '../components/NavigationBar'
import { Footer } from '../components/Footer'
import { Table } from 'react-bootstrap'

const directorio = [
  {
    correo: "depgfmluz@gmail.com",
    procesos: "Para solicitar información general de la DEPG."
  },
  {
    correo: "direcciondepgfmluz@ditic.luz.edu.ve",
    procesos: "Comunicaciones para la Dirección de la DEPG, que no deban ser tramitadas por otros correos."
  },
  {
    correo: "infopagosdepgfmluz@gmail.com",
    procesos: "Para solicitar información de sus pagos y estatus administrativos en la DEPG."
  },
  {
    correo: "consejotecnicodepgfmluz@gmail.com",
    procesos: "Para solicitar información y orientación sobre los trámites del Consejo Técnico de la DEPG."
  },
  {
    correo: "controldeestudiosdepgfmluz@gmail.com",
    procesos: "Para solicitar información sobre los trámites de Control de Estudios. Ejemplo: Notas - Grado."
  },
  {
    correo: "pecdepgfmluz@gmail.com",
    procesos: "Para solicitar información sobre los cursos avanzados de la DEPG."
  },
  {
    correo: "acreditaciondepgfmluz@gmail.com",
    procesos: "Para solicitar información sobre Acreditación y Reformulación de Programas de la DEPG."
  },
  {
    correo: "doctoradodepgfmluz@gmail.com",
    procesos: "Para solicitar información sobre los Doctorados de la DEPG."
  },
  {
    correo: "concursodepgfmluz@gmail.com",
    procesos: "Para solicitar información sobre el Concurso de la DEPG."
  },
  {
    correo: "saludmentaldepgfmluz@gmail.com",
    procesos: "Para solicitar información sobre el examen de Salud Mental"
  },
  {
    correo: "archivodepgfmluz@gmail.com",
    procesos: "Para realizar trámites de retiro de documentos. "
  },
  {
    correo: "informaticadepgfmluz@gmail.com",
    procesos: "Para información de datos y actualizaciones. "
  },
  {
    correo: "postgradodemedicinagregory@gmail.com",
    procesos: "Para tramitar todo lo referente a las relaciones publicas de la División. "
  },
  {
    correo: "secretariadocentedepg.fmedluz@gmail.com",
    procesos: ""
  }
]

function Contacto() {
  return (
    <>
        <Header />
        <NavigationBar activeLink={'Contacto'} />
        <main className='main main-contacto'>
            <h1 className='main-title'>Directorio Institucional</h1>
            
            <section className='table-container'>

              <Table striped hover size="lg" className='table-directorio'>
                <thead>
                  <tr className='table-header-row'>
                    <th className='table-header-cell left'>Correo Electrónico</th>
                    <th className='table-header-cell right'>Procesos</th>
                  </tr>
                </thead>
                <tbody>
                {
                  directorio.map((elemento) => (
                    <tr key={elemento.correo} className='table-row'>
                      <td className='table-cell email fw-semibold'>{elemento.correo}</td>
                      <td className='table-cell description'>{elemento.procesos}</td>
                    </tr>
                  ))
                }
                </tbody>
              </Table>
            </section>
        </main>
        <Footer />
    </>
  )
}

export { Contacto }