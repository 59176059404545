import React from 'react'

function TabReglamento ({ programData }) {
  return (
    <>
    {
      programData.release ?
      <div className='tab-reglamento'>
        <h3 className='sub-titulo'>Régimen de permanencia en el programa</h3>

        <p>{programData.reglamento.introduccion}</p>
      {
        programData.reglamento.articulos.map((articulo, index) => 
          (articulo.tipo === "lista") ? 
            <div key={index} className='tab-card card-articulo lista'>
              <div className='text-container'>
                <span className='text-lead fw-bold'>{articulo.nombre}: </span>
                <p>{articulo.descripcion}</p>
              </div>

              <ul className='tab-list articulo-lista'>
              {
                articulo.subarticulos.map((element, index) => 
                <li key={index} className='elemento-lista'>{element}</li>
              )} 
              </ul>
            </div>
          :
          (articulo.tipo === "parrafo" && 
            <div key={index} className='tab-card card-articulo'>
              <span className='text-lead fw-bold'>{articulo.nombre}: </span>
              <p>{articulo.descripcion}</p>
            </div>
          )
        )
      }
        <p className='tab-text'>{programData.reglamento.subparrafo}</p>

        <div className='tab-card card-deberes'>
          <p className=''>{programData.reglamento.intro_deberes ?? ''}</p>

          <ul className='tab-list'>
          { 
            programData.reglamento.deberes.map((element, index) => 
              <li key={index} className='elemento-lista'>{element}</li>
            )
          }
          </ul>
        </div>

      {
        programData.reglamento.parrafos.map((element, index) => 
          <p key={index} className='tab-text'>{element}</p>
        )
      }  

      </div>
      :
      <span>En construcción...</span>
    }
    </>
  )
}

export { TabReglamento }