import React from 'react'
import diagnostico_bacteriologico from "../../../assets/images/planes_estudio/Maestrias/Diagnostico_Bacterologico.png"
import metabolismo_humano from "../../../assets/images/planes_estudio/Maestrias/Metabolismo_Humano.png"
import enfermedades_cardiovasculares from "../../../assets/images/planes_estudio/Maestrias/Enfermedades_Cardiovasculares.png"
import genetica_humana from "../../../assets/images/planes_estudio/Maestrias/Genetica_Humana.png"
import genetica_medica from "../../../assets/images/planes_estudio/Maestrias/Genetica_Medica.png"
import inmunologia from "../../../assets/images/planes_estudio/Maestrias/Inmunologia.png"
import investigacion_biomedicina from "../../../assets/images/planes_estudio/Maestrias/Investigacion_en_Biomedicina.png"
import nutricion_comunitaria from "../../../assets/images/planes_estudio/Maestrias/Nutricion_Comunitaria.png"
import salud_ocupacional from "../../../assets/images/planes_estudio/Maestrias/Salud_Ocupacional.png"

function TabPlanEstudios ({ programData }) {
  const planes = [
    {
      programa: "diagnostico-bacteriologico",
      imagen: diagnostico_bacteriologico
    },
    {
      programa: "metabolismo-humano",
      imagen: metabolismo_humano
    },
    {
      programa: "enfermedades-cardiovasculares",
      imagen: enfermedades_cardiovasculares
    },
    {
      programa: "genetica-humana",
      imagen: genetica_humana
    },
    {
      programa: "genetica-medica",
      imagen: genetica_medica
    },
    {
      programa: "inmunologia",
      imagen: inmunologia
    },
    {
      programa: "investigacion-biomedicina",
      imagen: investigacion_biomedicina
    },
    {
      programa: "nutricion-comunitaria",
      imagen: nutricion_comunitaria
    },
    {
      programa: "salud-ocupacional",
      imagen: salud_ocupacional
    }
  ]

  const programaActual = planes.find(plan =>
    plan.programa === programData.plan_estudios.imagen_pde
  );

  return (
    <>
    {
      programData.release ?
      <div className='tab-plan-estudios'>
        <h3 className='sub-titulo'>Plan de Estudios</h3>

        <div className='d-flex flex-column align-items-center'>
          {
            (programData.plan_estudios.imagen_pde !== "--pendiente--") ?
            <figure className='plan-container'>
                <img 
                  className={`plan-estudios-img ${ programData.plan_estudios.img_layout ?? 'standard' }` } 
                  alt='Plan de Estudios' 
                  src={programaActual.imagen}
                />
            </figure>
            :
            <div class="spinner-border text-primary mb-3" style={{'height': "30px", "width": "30px"}} role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          }
          <div className={`plan-footer text-center fw-semibold d-flex flex-wrap justify-content-center ${programData.plan_estudios?.footer===false && 'd-none'}`}>
            <span className='footer-text'>HT: Horas teóricas. </span>
            <span className='footer-text'>HP: Horas prácticas. </span>
            <span className='footer-text'>HPP: Horas de Práctica Profesional. </span>
            <br/>
            <span className='footer-text'>HTol: Horas totales semanales. </span>
            {
              programData.plan_estudios.uc && 
              <span className='footer-text'>UC: Unidades créditos</span>
            }
          </div>
        </div>

      {
        (programData.plan_estudios.layout === 1 && !(programData.plan_estudios.no_electivas)) && 
        <>
          {
            programData.plan_estudios.notas_header && programData.plan_estudios.notas_header.map((element, index) => 
              <p key={index} className='list-parag'>{element}</p>
            )
          }
          <span className='text-footer fw-bold'>{programData.plan_estudios.footer_title}</span>
          {
            programData.plan_estudios.footer &&
            <span className='text-footer'>{programData.plan_estudios.footer}</span>
          }
          <ul className='tab-list'>
          {
            programData.plan_estudios.electivas.map((element, index) => 
              <li key={index} className='element-list'>{element}</li>
            )
          }
          </ul>
          {
            programData.plan_estudios.nota && <>
              <span className='fw-bold'>Nota: </span><span>{programData.plan_estudios.nota} </span>
            </>
          }
        </>
      }
      {
        (programData.plan_estudios.layout === 2 && !(programData.plan_estudios.no_electivas)) && 
        <>
          {
            programData.plan_estudios.footer_title &&
            <div className='mb-4'>
              <span className='text-footer fw-semibold'>{programData.plan_estudios.footer_title}</span>
            {
              programData.plan_estudios.footer && 
              <span className='text-footer'>{programData.plan_estudios.footer}</span>
            }
            </div>
          }
          {
            programData.plan_estudios.electivas.map((electiva, index) => 
              <div key={index} className='tab-footer-group'>
                <span className='fw-bold'>{electiva.titulo}</span>
                <span>{electiva.descripcion}</span>

              {
                (programData.plan_estudios.tipo_lista === "ol") ?
                <ol className='ordered-list mb-0 ms-3'>
                {
                  electiva.materias.map((materia, index) =>
                    <li key={index} className='list-item'>{materia}</li>
                  )
                }
                </ol>
                :
                <ul className='tab-list mb-0'>
                {
                  electiva.materias.map((materia, index) =>
                    <li key={index} className='list-item'>{materia}</li>
                  )
                }
                </ul>
              }

                <span className='fw-semibold'>{electiva.footer}</span>
              </div>
            )
          }
        </>
      }
      </div>
      :
      <span>En construcción...</span>
    }
    </>
  )
}

export { TabPlanEstudios }