import React from 'react'
import { Header } from '../components/Header'
import { NavigationBar } from '../components/NavigationBar'
import { Footer } from '../components/Footer'

const info = {
  descripcion: 'El Departamento de informática de la División de Estudios para Graduados de la Facultad de Medicina de LUZ, es el encargado de diseñar aplicaciones siguiendo estándares tecnológicos y metodologías técnicamente a los usuarios que pertenecen a la institución. \n\nElabora aplicaciones siguiendo estándares tecnológicos y metodologías que impulsen nuestro diseño curricular. Respalda la información siguiendo procedimientos establecidos en el sistema de la Universidad del Zulia, proporciona apoyo logístico y técnico tanto al personal docente y administrativo como en  las presentaciones y eventos especiales de la División. ',
  correo: 'informaticadepgfmluz@gmail.com'
}

const Informatica = () => {
  return (
    <>
    <Header />
    <NavigationBar activeLink={'Directorio'} />
    <main className='main main-informatica'>
      <div className='main-container dept-main'>
        <section className={`side-container `}>
          <div className='header-box side'>
            <h1 className='page-title text-center'>Informática</h1>
          </div>
          <div className={`perfil-card side-card`}>
            <div className='perfil-img-placeholder'></div>
            <h4 className='perfil-nombre'>Univ. Juan Briceño</h4>
            <span className='perfil-cargo fw-semibold'>Jefe del Departamento de Informática</span>
          </div>
          <div className='contacto-card side-card'>
            <h4 className='card-title'>Contacto</h4>
            <span className='span-correo'>{info.correo}</span>
          </div>
        </section>

        <section className='central-container'>
          <div className='header-box'></div>
          <p className='info-p ws-pw' dangerouslySetInnerHTML={{__html: info.descripcion}}/>
        </section>
      </div>
    </main>
    <Footer />
    </>
  )
}

export { Informatica }