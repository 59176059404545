import React from 'react'
import { Button, Modal } from 'react-bootstrap';
// import img_modal from '../../assets/images/ModalInicio/BannerProgramasPEC-Modal.png'

const links = {
  formCursoSaludPublica: 'https://forms.gle/D7XUHB4bCk6FBr448',
  formRadiologia: 'https://forms.gle/QLeSLN8FToXD2y4c6'
}

const ModalPec = ({ toggleFunction, modalState }) => {
  return (
    <Modal show={modalState} onHide={() => toggleFunction(false)} dialogClassName='custom-lg'>
      <Modal.Header closeButton>
        <Modal.Title>Cronograma de Actividades</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <section className='info-container text-center'>
          {/* <img src={img_modal} alt='Apertura del Concurso de Posgrado de Medicina 2024' className='modal-banner' /> */}

          <h3 className='heading-title mb-4'>Programa de Educación Continua (PEC)</h3>

          <div className='cards-group'>
            <div className='info-group card'>
              <h3 className='modal-subtitle continuous heading'>Curso Medio de Salud Pública</h3>
              <p className='info-p' dangerouslySetInnerHTML={{__html: '<b>Fecha de inicio</b>: 21 de octubre de 2024'}} />
              <p className='info-p' dangerouslySetInnerHTML={{__html: '<b>Hora</b>: 8:30 AM '}} />
              <p className='info-p' dangerouslySetInnerHTML={{__html: '<b>Lugar</b>: Edificio Ciencia y Salud 2do. Piso. Salón de Usos Múltiples '}} />
              <p className='info-p spaced-md' dangerouslySetInnerHTML={{__html: '<b>Coordinadora</b>: Dra. Noris Acosta'}} />

              <a href={links.formCursoSaludPublica} target='blank' className='btn btn-primary link-button rounded-pill center'>
                Formulario de Registro
              </a>
            </div>

            <div className='info-group card'>
              <h3 className='modal-subtitle continuous heading'>Curso de Radiología Torácica (Reprogramado)</h3>
              <p className='info-p mb-3'>"Aprende a interpretar la Radiología Torácica en diversas patologías Toraco-pulmonares"</p>

              <h4 className='highlight-title fw-bold'>Preinscripciones Abiertas</h4>
              <p className='info-p mb-4'>Desde 24 de Septiembre de 2024 hasta el 15 de Octubre de 2024</p>

              <h4 className='highlight-title fw-bold'>Curso dirigido a</h4>
              <p className='info-p mb-4'>Especialistas, Estudiantes del último benio</p>

              <div className='d-flex justify-content-center mb-4'>
                <span className='fw-bold'>PRESENCIAL         </span>
                <span className='fw-bold'>8 HORAS         </span>
                <span className='fw-bold mb-1'>U.C.</span>
              </div>

              <p className='info-p' dangerouslySetInnerHTML={{__html: '<b>Inicio</b>: 18 de octubre de 2024'}} />
              <p className='info-p mb-4' dangerouslySetInnerHTML={{__html: '<b>Hora</b>: 3:00 PM - 5:00 PM'}} />
              <p className='info-p' dangerouslySetInnerHTML={{__html: '<b>Final</b>: 19 de octubre de 2024'}} />
              <p className='info-p mb-4' dangerouslySetInnerHTML={{__html: '<b>Hora</b>: 8:00 AM - 1:00 PM'}} />
              <p className='info-p spaced-md' dangerouslySetInnerHTML={{__html: '<b>Coordinador</b>: Dr. Juan Lauretta'}} />

              <a href={links.formRadiologia} target='blank' className='btn btn-primary link-button rounded-pill center'>
                Formulario de Registro
              </a>
            </div>
          </div>
        </section>
      </Modal.Body>
      <Modal.Footer>
        <Button className='footer-button' variant="secondary" onClick={()=> toggleFunction(false)}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export { ModalPec }