import React from 'react'
import { Header } from '../components/Header'
import { NavigationBar } from '../components/NavigationBar'
import { Footer } from '../components/Footer'
import { Link, Outlet, useLocation } from 'react-router-dom'
import routes from '../routes/routes'
import img_perfil from '../assets/images/Perfiles/TSU Sofía Altuve - Administracion.jpg'

const info = {
  items_descripcion: [
    {
      tipo: "parrafo",
      contenido: "El departamento de administración juega un importante papel dentro de la División de Estudios para Graduados de la Facultad de Medicina de LUZ, pues ejerce una serie de funciones de carácter administrativo y académico, que involucran buena parte de los procesos tanto internos como externos de la institución. "
    },
    {
      tipo: "parrafo",
      contenido: "Es el encargado de recibir las facturas de los proveedores y emitir las facturas a los usuarios. Además, es el eje central de la División del que dependen los demás departamentos para su correcta gestión, Otras tareas del departamento de administración incluyen: "
    },
    {
      tipo_lista: "ul",
      nested: true,
      continuous: true,
      elementos: [
        "Reclutar, contratar y capacitar al personal. ",
        "Gestionar las relaciones laborales y la resolución de conflictos. ",
        "Establecer políticas de compensación y beneficios. ",
        "Contratación, selección y gestión del personal de la empresa. ",
        "Elaboración de políticas y procedimientos relacionados con el personal. ",
        "Gestión de nóminas, contratos laborales y beneficios sociales. "        
      ]
    },
    {
      tipo: "parrafo",
      contenido: "El departamento de administración también se encarga de establecer los objetivos de la empresa para un periodo especifico este planifica y distribuye los ingresos y egresos de la División. "
    },
    {
      tipo: "parrafo",
      contenido: "Dentro de la Administración se encuentran los departamentos de: "
    }
  ],
  asistentes: [
    'Univ. Josmary Muñoz',
    'Univ. Vanessa Vivas',
    'Lcda. Hillary Hoira',
    'Univ. Kelly Briceño'
  ],
  correo: 'infopagosdepgfmedluz@gmail.com',
  contacto: '0412.778.5916',
  perfil: {
    nombre: 'TSU. Sofía Altuve',
    cargo: 'Administrador Jefe'
  }
}

const Administracion = () => {
  const location = useLocation();

  const parentRoute = routes.find(route => route.path === 'administracion');
  const mainPath = '/' + parentRoute.path;
  let submenuLinks = [
    {
      opcion: 'Contenido principal',
      link: mainPath
    }
  ]
  parentRoute.children.map(child => submenuLinks.push({
    opcion: child.title,
    link: `/${parentRoute.path}/${child.path}`
  }));
  const isMainPage = location.pathname === mainPath

  return (
    <>
      <Header />
      <NavigationBar />
      <main className='main main-administracion'>
        <section className='main-container dept-main'>
          <div className={`side-container ${isMainPage ? 'main-page' : 'child'}`}>
            <div className='header-box side'>
              <h1 className='page-title text-center'>Administración</h1>
            </div>

            <div className={`perfil-card side-card ${!isMainPage && 'd-none'}`}>
              <img src={img_perfil} alt={info.perfil.nombre} className='img-perfil' />
              <h4 className='perfil-nombre'>{info.perfil.nombre}</h4>
              <span className='perfil-cargo fw-semibold'>{info.perfil.cargo}</span>
            </div>

            <div className='asistentes-card side-card'>
              <h4 className='card-title'>Asistentes Administrativos</h4>
              <ul className='lista-asistentes'>
              {
                info.asistentes.map((item, index) =>
                  <li className='list-item' key={index}>{item}</li>
                )
              }
              </ul>
            </div>

            <div className='contacto-card side-card'>
              <h4 className='card-title'>Contacto</h4>
              <span className='span-correo'>{info.correo}</span>
            </div>

            <div className='side-card submenu-card'>
              <ul className='submenu-list std-list'>
              {
                submenuLinks.map((item, index) => 
                  <li className='list-item' key={index}>
                    <Link to={item.link} className='list-link'>{item.opcion} </Link>
                  </li>
                )
              }
              </ul>
            </div>
          </div>
          
          <div className='central-container'>
            <div className='header-box'>
            {
              location.pathname !== (mainPath) &&
              <></>
            }
            </div>
          {
            location.pathname === mainPath
            ? <>
              <p className='p-contenido descripcion ws-pw' dangerouslySetInnerHTML={{__html: info.descripcion}}/>
              {
                info.items_descripcion.map((item, index) => 
                <div className='item-descripcion' key={index}>
                {
                  item.tipo === "parrafo" ?
                  <p className={`ws-pw p-${item.heading ? 'heading' : 'std'}`}>{item.contenido}</p>
                  :
                  item.tipo_lista === "ol" 
                  ? <ol className={`lista-descripcion ${item.nested ? 'nested-list' : 'std'}`}>
                  {
                    item.elementos.map((elemento, index) =>
                      <li className='elemento-lista' key={index}>{elemento}</li>
                    )
                  }
                  </ol> 
                  : <ul className={`lista-descripcion ${item.nested ? 'nested-list' : 'std'} ${item.style_type} ${item.continuous ? 'continuous' : ''}`}>
                  {
                    item.elementos.map((elemento, index) =>
                      <li className='elemento-lista' key={index}>{elemento}</li>
                    )
                  }
                  </ul> 
                }
                </div>
                )
              }
              <div className='depts-container'>
                <article className='dept-card left card'>
                  <div className='card-header'>
                    <h4 className='card-title'>Caja</h4>
                  </div>
                  <div className='card-body'>
                    <p className='p-info last-p'>Es el departamento encargado de informar sobre los aranceles que oferta la División, y emite la facturación correspondiente.</p>

                    <p className='p-encargado'>El departamento de caja está encargado por: </p>
                    <p className='nombre-encargado'>
                      » Univ. Kelly Briceño
                    </p>
                  </div>
                </article>

                <article className='dept-card left card'>
                  <div className='card-header'>
                    <h4 className='card-title'>Almacén y Proveeduría</h4>
                  </div>
                  <div className='card-body'>
                    <p className='p-info'>Se encarga de garantizar la distribución de los materiales que reposan en la División, así como el adecuado manejo y custodia de los mismos. </p>

                    <p className='p-info last-p'>Verifica la exactitud del registro de los bienes, bajo un inventario frecuente. Realiza Formatos de compra, de reparaciones y donaciones. </p>

                    <p className='p-encargado'>El departamento de almacén está encargado por: </p>
                    <p className='nombre-encargado'>
                      » Univ. José Antonio Altuve Rincón
                    </p>
                  </div>
                </article>
              </div>
              
            </>
            : 
            <Outlet/>
          }
          </div>
        </section>
      </main>
      <Footer/>
    </>
  )
}

export { Administracion }