import React from 'react'

function TabPerfiles({ programData }) {
  const tabLayout = programData.perfiles.layout; // Número entero indicador del tipo de Layout
  
  return (
    <>
    {
      programData.release ?
      <>
        {
          tabLayout === 1 && 
          <div className='tab-perfiles'>
          {
            programData.perfiles.estudiante && 
            <div className='tab-card card-perfiles estudiante'>
              <h3 className='sub-titulo perfil-estudiante'>Perfil del estudiante a ingresar</h3>

              <p>{ programData.perfiles.descripcion_estudiante ?? '' }</p>

              <ul className='tab-list lista-perfiles'>
              {
                programData.perfiles.estudiante.map((elemento, index) =>
                  <li key={index} className='elemento-lista ws-pw'>{elemento}</li>
                )
              }  
              </ul>
            </div>
          }

            <div className='tab-card card-perfiles egresado'>
              <h3 className='sub-titulo perfil-egresado'>Perfil del estudiante a egresar</h3>

              {
                programData.perfiles.descripcion_egresado && 
                <p>{programData.perfiles.descripcion_egresado}</p>
              }

              <ul className='tab-list lista-perfiles'>
              {
                programData.perfiles.sublista_eg ?
                programData.perfiles.egresado.map((elemento, index) =>
                  elemento.sublista ?
                  <ul className='nested-list'>
                  {
                    elemento.sublista.map((subitem, index) => 
                    <li key={index}>{subitem}</li>   
                  )}
                  </ul>
                  :
                  <li key={index} className='elemento-lista ws-pw'>
                    {elemento.item}
                  </li>
                )
                :
                programData.perfiles.egresado.map((elemento, index) =>
                  <li key={index} className='elemento-lista ws-pw'>
                    {elemento}
                  </li>
                )
              }  
              </ul>

              {
                programData.perfiles.egresado_footer && 
                <p>{programData.perfiles.egresado_footer}</p>
              }
            </div>

            <h3 className='sub-titulo grado'>Grado a otorgar</h3>
            <span className='grado-span'>» {programData.grado}</span>
          </div>
        }

        {
          tabLayout === 2 && 
          <div className='tab-perfiles'>
            {
              programData.perfiles.estudiante &&
              <div className='tab-card card-perfiles estudiante'>
                <h3 className='sub-titulo perfil-estudiante'>Perfil del estudiante a ingresar</h3>

                <ul className='tab-list lista-perfiles'>
                {
                  programData.perfiles.estudiante.map((elemento, index) =>
                    <li key={index} className='elemento-lista'>{elemento}</li>
                  )
                }  
                </ul>
              </div>
            }

            <div className='tab-card card-perfiles egresado'>
              <h3 className='sub-titulo perfil-egresado'>Perfil del estudiante a egresar</h3>

              <p className='mb-4 ws-pw'>{programData.perfiles.egresado.titulo}</p>

              {
                programData.perfiles.egresado.competencias.map((competencia, index) =>
                  <div key={index} className='sub-container-perfiles nested-list-1'>
                    <h4 className='titulo-md'>{competencia.titulo}</h4>

                    <ul className='tab-list lista-competencias'>
                    {
                      competencia.elementos.map((elemento, index) => 
                        <li key={index} className='elemento-lista ws-pw'>{elemento}</li>
                      )
                    }
                    </ul>
                  </div>
                )
              }  
            </div>

            <h3 className='sub-titulo grado'>Grado a otorgar</h3>
            <span className='grado-span'>» {programData.grado}</span>
          </div>
        }

        {
          tabLayout === 3 &&
          <div className='tab-perfiles'>
            {
              programData.perfiles.estudiante &&
              <div className='tab-card card-perfiles estudiante'>
                <h3 className='sub-titulo perfil-estudiante'>Perfil del estudiante a ingresar</h3>

                <ul className='tab-list lista-perfiles'>
                {
                  programData.perfiles.estudiante.map((elemento, index) =>
                    <li key={index} className='elemento-lista'>{elemento}</li>
                  )
                }  
                </ul>
              </div>
            }

            <div className='tab-card card-perfiles egresado'>
              <h3 className='sub-titulo perfil-egresado'>Perfil del estudiante a egresar</h3>

              <p className='mb-4 ws-pw'>{programData.perfiles.egresado.descripcion}</p>

              {
                programData.perfiles.egresado.competencias.map((competencia, index) =>
                  <div key={index} className='sub-container-perfiles'>
                    <h4 className='titulo-md'>{competencia.titulo}</h4>
                    <p className='mb-2 fw-semibold'>{competencia.subtitulo}</p>
                  {
                    competencia.descripcion && <p className='comp-descripcion'>{competencia.descripcion}</p>
                  }

                    <ul className='tab-list lista-competencias'>
                    {
                      competencia.elementos.map((elemento, index) => 
                        <li key={index} className='elemento-lista'>{elemento}</li>
                      )
                    }
                    </ul>
                  </div>
                )
              }  
              <p className='s'>{programData.perfiles.egresado.footer ?? ""}</p>  

            </div>

            <h3 className='sub-titulo grado'>Grado a otorgar</h3>
            <span className='grado-span'>» {programData.grado}</span>
          </div>
        }

        {
          tabLayout === 4 &&
          <div className='tab-perfiles'>
            {
              programData.perfiles.estudiante !== false &&
              <div className='tab-card card-perfiles estudiante'>
                <h3 className='sub-titulo perfil-estudiante'>Perfil del estudiante a ingresar</h3>

              {
                programData.perfiles.estudiante_descripcion &&
                <p>{programData.perfiles.estudiante_descripcion}</p>
              }

                <ul className='tab-list lista-perfiles'>
                {
                  programData.perfiles.estudiante.map((elemento, index) =>
                    <li key={index} className='elemento-lista'>{elemento}</li>
                  )
                }
                </ul>

              {
                programData.perfiles.estudiante_footer &&
                <p>{programData.perfiles.estudiante_footer}</p>
              }
              </div>
            }

            <div className='tab-card card-perfiles egresado'>
              <h3 className='sub-titulo perfil-egresado'>Perfil del estudiante a egresar</h3>

              <p className='descripcion-egresado ws-pw'>{programData.perfiles.egresado.descripcion}</p>
              {
                programData.perfiles.egresado.listas.map((lista, index) =>
                    lista.hasChildren ? 
                    <div key={index} className='sub-container-perfiles'>
                      <h3 className='titulo-md'>{lista.titulo}</h3>
                      {
                        lista.descripcion && 
                        <p className='mb-3'>{lista.descripcion}</p>
                      }
                    {
                      lista.sublistas.map((sublista, ind) =>
                        <div className='nested-list'>
                          <p className='titulo-sublista mb-2'>{sublista.titulo}</p>
                        {
                          sublista.tipo === "ul" ?
                          <ul className={`tab-list lista-perfil ${sublista.nested ?? 'std'}`} key={ind}>
                          {
                            sublista.items.map((item, index) => 
                              <li key={index} className='elemento-lista'>{item}</li>
                            )
                          }
                          </ul>
                          :
                          <ol className={`tab-list lista-perfil ${sublista.nested ?? 'std'}`} key={ind}>
                          {
                            sublista.items.map((item, index) => 
                              <li key={index} className='elemento-lista'>{item}</li>
                            )
                          }
                          </ol>
                        }
                        </div>
                      )
                    }
                    </div> 
                    :
                    <div key={index} className='sub-container-perfiles'>
                      <h3 className='titulo-md mb-2'>{lista.titulo}</h3>
                      <p className='mb-3'>{lista.descripcion}</p>
                    {
                      lista.tipo === "ul" ?
                      <ul className='tab-list lista-perfil'>
                      {
                        lista.items.map((item, index) => 
                          <li key={index} className='elemento-lista'>{item}</li>
                        )
                      }
                      </ul>
                      :
                      <ol className='tab-list lista-perfil'>
                      {
                        lista.items.map((item, index) => 
                          <li key={index} className='elemento-lista'>{item}</li>
                        )
                      }
                      </ol>
                    }
                    </div>
                )
              }

              <p className='s'>{programData.perfiles.egresado.footer ?? ""}</p>  
            </div>

            <h3 className='sub-titulo grado'>Grado a otorgar</h3>
            <span className='grado-span'>» {programData.grado}</span>
          </div>
        }

        {
          tabLayout === 5 &&
          <div className='tab-perfiles'>
            
            {
              programData.perfiles.items_estudiante &&
              <div className='tab-card card-perfiles estudiante'>
                <h3 className='sub-titulo perfil-estudiante'>Perfil del estudiante a ingresar</h3>
              {
                programData.perfiles.items_estudiante.map((item, index) => 
                <div className={`sub-container-perfiles mb-${item.custom_margin ?? 'std'} nested-list-${ item.nestLevel ?? 'std'}`} key={index}>
                {
                  item.tipo === "parrafo" ?
                  <p className={`ws-pw p-${item.heading ? 'heading' : 'std'}`}>{item.contenido}</p>
                  :
                  item.tipo_lista === "ol" 
                  ? 
                  <>
                    {
                      item.titulo && <h3 className='titulo-md'>{item.titulo}</h3>
                    }
                    <ol className={`lista-perfil ${item.nested ? 'nested' : 'std'}`}>
                    {
                      item.elementos.map((elemento, index) =>
                        <li className='elemento-lista' key={index}>{elemento}</li>
                      )
                    }
                    </ol> 
                  </>
                  : 
                  <>
                    {
                      item.titulo && <h3 className='titulo-md'>{item.titulo}</h3>
                    }
                    <ul className={`lista-perfil ${item.nested ? 'nested' : 'std'} ${item.style_type} ${item.continuous ? 'continuous' : ''}`}>
                    {
                      item.elementos.map((elemento, index) =>
                        <li className='elemento-lista' key={index}>{elemento}</li>
                      )
                    }
                    </ul> 
                  </>
                }
                </div>
                )
              }
              </div>  
            }

            {
              programData.perfiles.items_egresado &&
              <div className='tab-card card-perfiles egresado'>
                <h3 className='sub-titulo perfil-egresado'>Perfil del estudiante a egresar</h3>

                {
                  programData.perfiles.descripcion_egresado &&
                  <p className='ms-3'>{ programData.perfiles.descripcion_egresado }</p>
                }
                {
                programData.perfiles.items_egresado.map((item, index) => 
                <div className={`mb-${item.custom_margin ?? 'std'}`} key={index}>
                {
                  item.tipo === "parrafo" ?
                  <p className={`ws-pw p-${item.heading ? 'heading' : 'std'}`}>{item.contenido}</p>
                  :
                  item.tipo_lista === "ol" 
                  ? <ol className={`lista-perfil ${item.nested ? 'nested' : 'std'}`}>
                  {
                    item.elementos.map((elemento, index) =>
                      <li className='elemento-lista' key={index}>{elemento}</li>
                    )
                  }
                  </ol> 
                  : <ul className={`lista-perfil ${item.nested ? 'nested' : 'std'} ${item.style_type} ${item.continuous ? 'continuous' : ''}`}>
                  {
                    item.elementos.map((elemento, index) =>
                      <li className={`elemento-lista `} key={index}>{elemento}</li>
                    )
                  }
                  </ul> 
                }
                </div>
                )
                }
              </div>
            }


            <h3 className='sub-titulo grado'>Grado a otorgar</h3>
            <span className='grado-span'>» {programData.grado}</span>
          </div>
        }
      </>
      :
      <span>En construcción...</span>
    }
    </>
  )
}

export { TabPerfiles }